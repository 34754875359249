import React from 'react'

const Login = () => {

  return (
    <div>
      <section class="login-bac">
        <div class="container" >
          <div class="row">
            <div class="col-sm-6">


            </div>
            <div class="col-sm-6">
              <div class="setLogin">
                <div class="set-login">
                  <h3 >Login</h3>
                  <form>
                    <div class="form-group mb-4">
                      <label for="exampleInputEmail1">Email address</label>
                      <input type="email" class="form-control p-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                      <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                    </div>
                    <div class="form-group mb-1">
                      <label for="exampleInputPassword1">Password</label>
                      <input type="password" class="form-control p-3" id="exampleInputPassword1" placeholder="Password" />
                    </div>
                    <div class="form-check mb-5">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      <label class="form-check-label" for="exampleCheck1">Check me out</label>
                    </div>
                    <a type="submit" class="btn btn-primary w-100 p-3" href="book-detail.html">Login</a>
                    {/* style="  font-family: 'Poppins', sans-serif;background-color: black;border: none;" */}
                  </form>
                  <div>

                    <ul class="formLogin mt-4 d-flex justify-content-center">
                      <li>
                        <a href="#"><i class="fa fa-facebook-square me-2"></i></a>
                      </li>
                      {/* <!-- <li>
                              <a href="#"><i class="fa fa-twitter-square me-2"></i></a>
                            </li> --> */}
                      <li>
                        <a href="#"><i class="fa fa-google me-2"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="fa fa-instagram me-2"></i></a>
                      </li>
                      {/* <!-- <li>
                              <a href="#"><i class="icon-linkedin-2 me-2"></i></a>
                            </li> --> */}
                    </ul>
                  </div>
                </div>
              </div>


            </div>
          </div>







        </div>

      </section>




    </div>
  )
}

export default Login