import React, { useEffect } from 'react'
import { useState } from 'react';
import config from '../config/Config';


import AddNew from '../Component/AddAdress';



export default function Addresses() {

    const [userId, setUserId] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [addresses, setAddresses] = useState([]);
  
    // const [showModal, setShowModal] = useState(false);

   

    useEffect(() => {
        const storedUsername = localStorage.getItem('MyToken');
        const parsedUsername = JSON.parse(storedUsername);
    
        if (parsedUsername) {
          console.log(parsedUsername);
        //   setData(parsedUsername)
          // setemail(parsedUsername.email)
          setUserId(parsedUsername.userId)
        //   setUserType(parsedUsername.user_type)
            
    
        } else {
          console.log('No username found in local storage');
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });


    }, [] )

    useEffect(() => {
        // This useEffect depends on userId and calls AllAddress when userId changes and is valid
        if (userId) {
          AllAddress();
        }
      }, [userId]);

    const AllAddress = () => {
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
    
        fetch(config.api_base_url + `/api/v1/user/address/list/${userId}`, requestOptions)
          .then((response) => {
            if (response.code != 200) {
              //   alert("Please Fill Valid Details")
    
    
            }
    
            return response.json();
          })
    
          .then(async (responseData) => {
            console.log("RESULTS all All  Address===>>>>>>", responseData)
            setAddresses(responseData.data)
    
          })
          .catch(error => console.log('error', error));
      }

    const handleCardClick = () => {
        // setSelectedItem(item);
        setShowModal(true);
      };
    return (
        <section className='Add-Main'>
            <div className='container'>

                <h3>Your Addresses</h3>
                <div className='row'>
                    <div className='col-sm-6 col-md-4 col-xl-4'>
                        <div className='card newCard'>
                            <div className='picPlus d-flex justify-content-center'>
                                <div onClick={() => handleCardClick()}>
                                    <img src={require("../assets/images/add.png")} width={50}/>
                                    <h5 >Add Address</h5>

                                    <AddNew
                                        userId={userId}
                                        show={showModal}
                                        onHide={() => setShowModal(false)}
                                        // item={selectedItem}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        addresses.map((address,index) => {
                           return(
                            <>
                                <div className='col-sm-6 col-md-4 col-xl-4' key={index}>
                                    <div className='card newCard'>
                                        <div>
                                            <h6>{address.name}</h6>
                                            <p className='py-2'>  {address.address},   {address.city},   {address.state} , {address.country} , {address.zipCode}</p>

                                        </div>
                                    </div>
                                </div>
                            </>
                           )
                        })
                    }
                    
                </div>
            </div>
        </section>
    )
}

