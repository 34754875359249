import React, { useState ,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthorSearch from './AuthorSearch';
import 'react-quill/dist/quill.snow.css'; // import styles
import ReactQuill from 'react-quill'; // import ReactQuill
import config from '../config/Config';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const AddBook = () => {
  const [formData, setFormData] = useState({
    title: '',
    userId: '',
    shortDescription: '',
    description: '',
    mainImage: '',
    additionalImages: [''],
    sku: '',
    barcode: '',
    isbn: '',
    authorId: '',
    publisherId: '',
    author_name: '',
    publisher_name: '',
    categoryId: '',
    subCategoryId: '',
    bookLanguages: ['English'],
    pageCount: 0,
    bookCoverType: ['hardcover'],
    eBook: '0',
    eBookFile: [''],
    audioBookFiles: [''],
    audioBook: '0',
    quantity: 0,
    listingPrice: 0.0,
    price: 0.0,
    shippingBy: '1',
    shippingChargeStatus: '1',
    shippingChargeAmount: 0.0,
    publishStatus: '1',
    bookListingStatus: '0',
    booksBeaVerified: '1',
    bookLaunchDate: '',
    metaFields: '',
    metaTitle: '',
    originCountry: '',
    dimensions: '',
    edition: '',
    createdAt: new Date().toISOString().slice(0, 10), // Current date in YYYY-MM-DD format
    updatedAt: '',
    deletedAt: ''
  });
  const [shippingChargeStatusDisabled, setshippingChargeStatusDisabled] = useState(false)
  const [shippingChargeAmountDisabled, setshippingChargeAmountDisabled] = useState(false)
  const [formChanged, setFormChanged] = useState(false);
  const [categories,setCategories] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const  [userId,setUserId] = useState('')
  // Fetch  Categories
  const getCategories = ( async () => {
    try {
      const response = await fetch(config.api_base_url+"/api/v1/category/getList");
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      console.log(jsonData)
      setCategories(jsonData['data']);
    } catch (error) {
        setError(error);
    } finally {
        setIsLoading(false);
    }
  })

  useEffect(() => {
    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);

    if (parsedUsername) {
      console.log(parsedUsername);
      // setemail(parsedUsername.email)
      setUserId(parsedUsername.userId)
      setFormData({ ...formData, userId: parsedUsername.userId });
      
    } else {
      console.log('No username found in local storage');
    }
    getCategories()
  }, []);

  
  /** For  input  fields  */
  const handleChange = (e) => {
    const { name, value, index } = e.target;
    setFormChanged(true);
    if (name === 'bookLanguages') {
      const updatedLanguages = [...formData.bookLanguages];
      updatedLanguages[index] = value;
      setFormData({ ...formData, bookLanguages: updatedLanguages });
    } else if (name === 'shippingChargeStatus') {
      if (value === '0') {
        setFormData({
          ...formData,
          [name]: value,
          shippingChargeAmount: 0, // Set shipping charge amount to 40

        });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === 'shippingChargeAmount') {
      if (formData.shippingChargeStatus === '0' && parseFloat(value) !== 0) {
        alert('Shipping charge amount must be 0 for free shipping.');
        setFormData({
          ...formData,
          [name]: value,
          shippingChargeAmount: 0, // Set shipping charge amount to 40

        });
      } else if (formData.shippingChargeStatus === '1' && parseFloat(value) <= 0) {
        // alert('Shipping charge amount must be greater than 0 for applicable shipping.');
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === 'shippingBy') {
      if (value === '0') { // If shipping by BooksBea
        setFormData({
          ...formData,
          [name]: value,
          shippingChargeStatus: '1', // Set shipping charge status to "Applicable"
          shippingChargeAmount: 40, // Set shipping charge amount to 40

        });
        setshippingChargeStatusDisabled(true) // Disable the shipping charge status field
        setshippingChargeAmountDisabled(true)// Disable the shipping charge amount field
      } else {
        setFormData({
          ...formData,
          [name]: value,
          shippingChargeStatus: '1', // Set shipping charge status to "Applicable" by default when shipping by other means

        });
        setshippingChargeStatusDisabled(false)// Disable the shipping charge status field
        setshippingChargeAmountDisabled(false)
      }
    } else if (name === 'audioBook') {
      const isAudioBookSelected = value === '1';
      setFormData({
        ...formData,
        audioBook: value,
        audioBookFilesDisabled: !isAudioBookSelected, // Enable/disable audiobookFiles based on audioBook selection
      });
    } else if (name === 'eBook') {
      const isEbookSelected = value === '1';
      setFormData({
        ...formData,
        eBook: value,
        eBookFilesDisabled: !isEbookSelected, // Enable/disable eBookFiles based on eBook selection
      });
    } else if (name === 'title') {
      setFormData({
        ...formData,
        title: value,
        metaTitle: value.trim().replace(/\s+/g, '-'), // Enable/disable eBookFiles based on eBook selection
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }


  };



  const handleSelected = (selectedData) => {
    console.log(selectedData)
    selectedData.text = selectedData.value === '-1' || selectedData.value === -1 ? "" : selectedData.text
    if (selectedData.type === 'publisher') {
      setFormData({
        ...formData,
        publisherId: selectedData.value,
        publisher_name: selectedData.text
      }); // Update the authorId in the parent's state

    } else {
      setFormData({
        ...formData,
        authorId: selectedData.value,
        author_name: selectedData.text
      });
    }
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  }


  /** handle  Images */
  const handleImagesChange = async (e) => {
    const { name, files } = e.target;
    const formDataCopy = { ...formData };
    const additionalImages = [];

    // Append each file to additionalImages array
    for (let i = 0; i < files.length; i++) {
      additionalImages.push(files[i]);
    }

    formDataCopy[name] = additionalImages;
    await setFormData(formDataCopy);
  };
  const handleImageChange = async (e) => {
    const { name, files } = e.target;
    const formDataCopy = { ...formData };
  
    // Append the first file to mainImage
    formDataCopy[name] = files[0];
    await setFormData(formDataCopy);
  };

  const handleAddLanguage = () => {
    setFormData({ ...formData, bookLanguages: [...formData.bookLanguages, ''] });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)

    const formDataToSend = new FormData();

    // Append image files to formDataToSend
    formDataToSend.append('mainImage', formData.mainImage);
    formData.additionalImages.forEach((image, index) => {
      formDataToSend.append(`additionalImages`, image);
    });
    formData.audioBookFiles.forEach((image, index) => {
      formDataToSend.append(`audioBookFiles`, image);
    });
    formData.eBookFile.forEach((image, index) => {
      formDataToSend.append(`eBookFile`, image);
    });

    // Append other form fields to formDataToSend
    Object.entries(formData).forEach(([key, value]) => {
      if (key !== 'mainImage' && key !== 'additionalImages' && key !== 'audioBookFiles' && key !== 'eBookFile') {
        formDataToSend.append(key, value);
      }
    });
    console.log(formDataToSend);
    // Send formDataToSend to the API endpoint
    try {
      const response = await fetch(config.api_base_url + "/api/v1/books/addnew", {
        method: 'POST',
        body: formDataToSend,
      });
      const responseData = await response.json();
      if (response.status === 201) {
        // Handle successful creation
        
        toast.success(responseData.message,{ position: toast.POSITION.BOTTOM_RIGHT});
        setFormData({
          title: '',
          userId: '',
          shortDescription: '',
          description: '',
          mainImage: '',
          additionalImages: [''],
          sku: '',
          barcode: '',
          isbn: '',
          authorId: '',
          publisherId: '',
          author_name: '',
          publisher_name: '',
          categoryId: '',
          subCategoryId: '',
          bookLanguages: ['English'],
          pageCount: 0,
          bookCoverType: ['hardcover'],
          eBook: '0',
          eBookFile: [''],
          audioBookFiles: [''],
          audioBook: '0',
          quantity: 0,
          listingPrice: 0.0,
          price: 0.0,
          shippingBy: '1',
          shippingChargeStatus: '1',
          shippingChargeAmount: 0.0,
          publishStatus: '1',
          bookListingStatus: '0',
          booksBeaVerified: '1',
          bookLaunchDate: '',
          metaFields: '',
          metaTitle: '',
          originCountry: '',
          dimensions: '',
          edition: '',
          createdAt: new Date().toISOString().slice(0, 10), // Current date in YYYY-MM-DD format
          updatedAt: '',
          deletedAt: ''
        });
        console.log('Book created successfully:', responseData);
        
      } else if (response.status === 404) {
        // Handle not found error
        // console.error('Error 404: Endpoint not found');
        toast.error(responseData.message,{ position: toast.POSITION.BOTTOM_RIGHT})
      } else if (response.status === 500) {
        // Handle server error
        // console.error('Error 500: Internal server error');
        toast.error("Error 500: Internal server error",{ position: toast.POSITION.BOTTOM_RIGHT})
      } else if (response.status === 409) {
        // Handle conflict error
        console.error('Error 409: Conflict');
        toast.error(responseData.message,{ position: toast.POSITION.BOTTOM_RIGHT})
      } else if (response.status === 403) {
        // Handle forbidden error
        console.error('Error 403: Forbidden');
        toast.error(responseData.message,{ position: toast.POSITION.BOTTOM_RIGHT})
      } else {
        // Handle other status codes
        console.error('Error:', responseData.data);
      }
      // Clear the form fields
    
      setIsLoading(false)
      // Handle successful response
      // console.log('Form data submitted successfully');
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  return (
    <>
      <section className='mt-5 py-5'>
        <div className="container mt-5 shadow p-5">
          <div className='mainHeader_'>
            <h3> Add New Book</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <h6 className='mt-4'>Basic Details</h6>
            <hr />
            <div className='form-row'>
              {/* Title */}
              <div className='col-md-12 form-group'>
                <label>Title</label>
                <input type="text" className="form-control" name="title" value={formData.title} onChange={handleChange}  />
              </div>
            </div>
            <div className='row'>
              {/* SKU */}
              <div className="col-md-4 form-group">
                <label>SKU</label>
                <input type="text" className="form-control" name="sku" value={formData.sku} onChange={handleChange} />
              </div>
              {/* Barcode */}
              <div className="col-md-4 form-group">
                <label>Barcode</label>
                <input type="text" className="form-control" name="barcode" value={formData.barcode} onChange={handleChange} />
              </div>
              {/* ISBN */}
              <div className=" col-md-4 form-group">
                <label>ISBN</label>
                <input type="text" className="form-control" name="isbn" value={formData.isbn} onChange={handleChange}  />
              </div>
            </div>
            <div className='row'>
              {/* Author ID */}
              <div className="col-md-4 form-group">
                <label>Select Author </label>
                <AuthorSearch props={{ field: 'firstName', url: 'api/v1/list/author/search?value=a', selectedID: formData.authorId }} onSelect={handleSelected} />

                {/* <input type="text" className="form-control" name="authorId" value={formData.authorId} onChange={handleChange} required /> */}
              </div>

              {/* Author Name */}
              <div className="col-md-2 form-group">
                <label>Author Name</label>
                <input type="text" className="form-control" name="author_name" value={formData.author_name} onChange={handleChange} disabled={formData.authorId <= "-1" ? false : true} />
              </div>
              {/* Publisher ID */}
              <div className="col-md-4 form-group">
                <label>Select Publisher </label>
                <AuthorSearch props={{ field: 'publicationName', url: 'api/v1/list/publisher/search', selectedID: formData.publisherId }} onSelect={handleSelected} />

                {/* <input type="text" className="form-control" name="publisherId" value={formData.publisherId} onChange={handleChange} required /> */}
              </div>
              {/* Publisher Name */}
              <div className="col-md-2 form-group">
                <label>Publisher Name</label>
                <input type="text" className="form-control" name="publisher_name" value={formData.publisher_name} onChange={handleChange} disabled={formData.publisherId <= "-1" ? false : true} />
              </div>
            </div>
            <div className='row'>
              {/* Category ID */}
              <div className="col-md-3 form-group">
                <label>Select Category</label>
                <select id="category" className="form-control" name="categoryId" value={formData.categoryId} onChange={handleChange}>
                  <option value={-1}>-- Select --</option>
                  {categories.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.categoryName}
                    </option>
                  ))}
                </select>
                {/* <input type="text" className="form-control" name="categoryId" value={formData.categoryId} onChange={handleChange} required /> */}
              </div>
              {/* Subcategory ID */}
              <div className="col-md-3 form-group">
                <label>Select Subcategory </label>
                <input type="text" className="form-control" name="subCategoryId" value={formData.subCategoryId} onChange={handleChange}  />
              </div>
              {/* Book Languages */}
              <div className=" col-md-2 form-group">
                <label>Book Languages</label>
                {formData.bookLanguages.map((language, index) => (
                  <input key={index} type="text" className="form-control mb-2" value={language} onChange={(e) => handleChange({ target: { name: 'bookLanguages', value: e.target.value, index } })} />
                ))}
                <button type="button" className="btn btn-secondary btn-sm float-right" onClick={handleAddLanguage}>Add Language</button>
              </div>
              {/* Book Cover Type */}
              <div className="col-md-2  form-group">
                <label>Book Cover Type:</label>
                <select className="form-control" name="bookCoverType" value={formData.bookCoverType} onChange={handleChange} >
                  <option value="hardcover">Hardcover</option>
                  <option value="paperback">Paperback</option>
                  {/* <option value="All">All</option> */}
                  {/* Add more options if needed */}
                </select>
              </div>
              {/* Page Count */}
              <div className="col-md-2 form-group">
                <label>Page Count</label>
                <input type="number" className="form-control" name="pageCount" value={formData.pageCount} onChange={handleChange}  />
              </div>
            </div>
            <div className='row'>
              {/* Dimensions */}
              <div className="col-md-3 form-group">
                <label>Dimensions</label>
                <input type="text" className="form-control" name="dimensions" value={formData.dimensions} onChange={handleChange} />
              </div>
              {/* Edition */}
              <div className="col-md-3 form-group">
                <label>Edition</label>
                <input type="text" className="form-control" name="edition" value={formData.edition} onChange={handleChange} />
              </div>
              {/* Book Launch Date */}
              <div className="col-md-3 form-group">
                <label>Book Launch Date</label>
                <input type="date" className="form-control" name="bookLaunchDate" value={formData.bookLaunchDate} onChange={handleChange}  />
              </div>
              {/* Origin Country */}
              <div className="col-md-3 form-group">
                <label>Origin Country</label>
                <input type="text" className="form-control" name="originCountry" value={formData.originCountry} onChange={handleChange} />
              </div>
            </div>
            {/* Inventory management */}
            <h6 className='mt-4'>Inventory And Price Details</h6>
            <hr />
            <div className='row'>
              {/* Quantity */}
              <div className="col-md-4  form-group">
                <label>Quantity</label>
                <input type="number" className="form-control" name="quantity" value={formData.quantity} onChange={handleChange}  />
              </div>
              {/* Listing Price */}
              <div className="col-md-4 form-group">
                <label>Listing Price</label>
                <input type="number" step="0.01" className="form-control" name="listingPrice" value={formData.listingPrice} onChange={handleChange}  />
              </div>
              {/* Price */}
              <div className="col-md-4 form-group">
                <label>Price</label>
                <input type="number" step="0.01" className="form-control" name="price" value={formData.price} onChange={handleChange}  />
              </div>
            </div>
            {/* Shipping Management */}
            <h6 className='mt-4'>Shipping Details</h6>
            <hr />
            <div className='row'>
              {/* Shipping By */}
              <div className="col-md-4 form-group">
                <label>Shipping By</label>
                <select className="form-control" name="shippingBy" value={formData.shippingBy} onChange={handleChange} required>
                  <option value="0">BooksBea</option>
                  <option value="1" selected>Self Shipping</option>
                </select>
              </div>
              {/* Shipping Charge Status */}
              <div className=" col-md-4 form-group">
                <label>Shipping Charge</label>
                <select className="form-control" name="shippingChargeStatus" value={formData.shippingChargeStatus} onChange={handleChange} disabled={shippingChargeStatusDisabled} >
                  <option value="0">Free</option>
                  <option value="1">Applicable</option>
                </select>
              </div>
              {/* Shipping Charge Amount */}
              <div className="col-md-4 form-group">
                <label>Shipping Charge Amount</label>
                <input type="number" step="0.01" className="form-control" name="shippingChargeAmount" value={formData.shippingChargeAmount} onChange={handleChange} disabled={shippingChargeAmountDisabled} />
              </div>
            </div>
            {/* Description  and  images  */}
            <h6 className='mt-4'>Descriptions & Images</h6>
            <hr />
            <div className='row'>
              {/* Short Description */}
              <div className="col-md-12 form-group">
                <label>Short Description</label>
                <textarea className="form-control" name="shortDescription" value={formData.shortDescription} onChange={handleChange}  />
              </div>
              {/* Description */}
              <div className="col-md-12 form-group">
                <label>Description</label>
                <ReactQuill
                  theme="snow" // or "bubble"
                  value={formData.description}
                  onChange={handleDescriptionChange}
                />
                {/* <textarea className="form-control" name="description" value={formData.description} onChange={handleChange} /> */}
              </div>
            </div>
            <div className='row mt-5'>
              {/* Main Image */}
              <div className="col-md-6 form-group">
                <label>Main Image</label>
                <input type="file" className="form-control" name="mainImage" onChange={handleImageChange} required />

              </div>
              {/* Additional Images */}
              <div className="col-md-6 form-group">
                <label>Additional Images:</label>
                <input type="file" className="form-control" name="additionalImages" onChange={handleImagesChange} multiple/>

              </div>
            </div>
            {/* E-Book  and  Audio-Books  */}
            <h6 className='mt-4'>Audio Book And E-Book</h6>
            <hr />
            <div className='row'>
              {/* eBooks  */}
              <div className="col-md-3 form-group">
                <label>Ebook:</label>
                <select className="form-control" name="eBook" value={formData.eBook} onChange={handleChange}>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
              {/* eBookFiles */}
              <div className="col-md-3 form-group">
                <label>Ebook Files:</label>
                <input type="file" className="form-control" name="eBookFile" onChange={handleImagesChange} multiple disabled={formData.eBook !== '1' ? true: false} />
              </div>
              {/* audioBooks  */}
              <div className="col-md-3 form-group">
                <label>Audio Book:</label>
                <select className="form-control" name="audioBook" value={formData.audioBook} onChange={handleChange}>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
              {/* AudioBook Files */}
              <div className="col-md-3 form-group">
                <label>Audio Book Files:</label>
                <input type="file" className="form-control" name="audioBookFiles" onChange={handleImagesChange} multiple disabled={formData.audioBook !== '1' ? true : false} />
              </div>
            </div>
            {/* Meta  Details   */}
            <h6 className='mt-4'>Meta Details</h6>
            <hr />
            <div className='row'>
              {/* Meta Title */}
              <div className="col-md-12 form-group">
                <label>Meta Title</label>
                <input type="text" className="form-control" name="metaTitle" value={formData.metaTitle} onChange={handleChange} disabled={true}/>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                {/* <button type="submit" className="btn btn-primary btn-lg float-right">Save</button> */}
                <button
                  type="submit"
                  style={{ float: "right" }}
                  // onClick={handleSubmit}
                  disabled={isLoading}
                  className={isLoading ? 'btn btn-primary btn-lg mt-3 button-loading' : 'btn btn-lg btn-primary mt-3'}
                >
                Submit  {isLoading ? <span className="spinner"></span> : ''}
              </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      {/* {formChanged ? (
        <nav className="navbar navbar-dark bg-black fixed-bottom">
          <div className="container">
          <button
              style={{ float: "right" }}
              onClick={handleSubmit}
              disabled={isLoading}
              className={isLoading ? 'btn btn-primary mt-3 button-loading' : 'btn btn-primary mt-3'}
            >
            Submit  {isLoading ? <span className="spinner"></span> : ''}
          </button>
          </div>
        </nav>
      ) : null} */}

    </>
  );
};

export default AddBook;