import React, { useState } from 'react'
import "../assets/css/footer.css"
import config from '../config/Config';
import { Link } from 'react-router-dom';



export default function Footer() {
    const [data, setform] = useState({
        name: "",
        email: "",

    })
    const handleInputChange = (e) => {
        const { name, value, } = e.target;
        setform({
            ...data,
            [name]: value,
        });
    };

    const AddNewsLetter = () => {
        if (!data.name.trim() || !data.email.trim()) {
            alert("Please fill in both name and email.");
            return;
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "name": data.name,
            "email": data.email,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(config.api_base_url + "/api/v1/newsletter/subscribe", requestOptions)
            .then((response) => {
                if (response.code != 200) {
                    //   alert("Please Fill Valid Details")


                }

                return response.json();
            })

            .then(async (responseData) => {

                if (responseData.code == 201) {
                    alert(responseData.message)
                    setform({
                        name: "",
                        email: "",
                    });


                } else {
                    alert("Please Fill Valid Details")
                }
                console.log("RESULTS all All  details", responseData)

            })
            .catch(error => console.log('error', error));

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        AddNewsLetter()

        // You can perform actions with the form data here
        console.log('Form Data hddhd:', e);
    };


    return (
        <div>
            <footer>
                <section className="newsletter">
                    <div className="newsText">
                        <h1>Join our newsletter!</h1>
                        <p>Subscribe to our weekly newsletter and stay tunned.
                        </p>
                        <img src={require("../assets/images/right.png")} />
                    </div>
                    <div className="newsSign">
                        <form onSubmit={handleSubmit} >

                            <div className="input-field">
                                <label htmlFor="exampleInputName">Name</label> <br />
                                <input type="text" name="name" value={data.name}
                                    onChange={handleInputChange} />

                            </div>

                            <div className="input-field">
                                <label htmlFor="exampleInputEmail">E-mail</label><br />
                                <input type="email" name="email" value={data.email}
                                    onChange={handleInputChange} />
                            </div>

                            <button type="submit" name="submit">Submit</button>

                        </form>
                    </div>
                </section>
                <section className="sc-footer-section sc-footer-style2 sc-pt-120 footer-bg-image2 sc-md-pt-170">
                    <div className="container">

                        <div className="row sc-pt-80 sc-pb-80 sc-md-pb-70">
                            <div className="col-xl-4 col-md-6 col-sm-12 sal-animate" data-sal="slide-up" data-sal-duration="500" data-sal-delay="300">
                                <div className="footer-about">
                                    <div className="footer-logo sc-mb-30">
                                        <a href="/"><img src={require("../assets/images/@booksbeaLogo.png")} /></a>
                                    </div>
                                    <p className="footer-des aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                                        Platform for Booksellers, Publishers &amp; Authors to Promote and Sell Their Books Online...
                                    </p>
                                    <div className="sc-contact-number d-flex align-items-center sc-mb-35">
                                        <div className="phone-icon">
                                            <div className=" icon-phone white-bg-color ">
                                                <img src={require("../assets/images/phone-call.png")} className="" />

                                            </div>
                                        </div>
                                        <div className="contact-number">
                                            Free Support:
                                            <a data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" href="tel:526-2250" className="number aos-init aos-animate">+1-(408)
                                                755-1607</a>
                                        </div>
                                    </div>
                                    <ul className="about-icon mt-4 d-flex">
                                        <li>
                                            <a href="https://www.facebook.com/booksbea"><img src={require("../assets/images/facebook.png")} className="" /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/booksbeadotcom/"><img src={require("../assets/images/instagram.png")} className="" /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/@Booksbea369"><img src={require("../assets/images/youtube.png")} className="" /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/booksbea/"><img src={require("../assets/images/linkedin.png")} className="" /></a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/booksbeadotcom"><img src={require("../assets/images/twitter.png")} className="" /></a>
                                        </li>




                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-6 col-sm-12 sal-animate" data-sal="slide-up" data-sal-duration="500" data-sal-delay="500">
                                <div className="footer-menu-area sc-footer-user sc-pl-75 sc-md-pl-0 sc-md-mb-40 sc-lg-pl-0">
                                    <h4 className="footer-title white-color sc-md-mb-15 ">Useful Links</h4>
                                    <div className="row">
                                        <div className="col-12">
                                            <ul className="footer-menu-list aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                                                <li><Link to={"/blogs"} >Blogs</Link></li>
                                                <li><a href="#">Events</a></li>
                                                <li><a href="#">About Us</a></li>
                                                <li>   <Link to={"/contact-us"} >Contact Us</Link></li>
                                                <li><a href="#">Knowledge base</a></li>
                                                <li><a href="#">Track order</a></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-6 col-sm-12 sal-animate" data-sal="slide-up" data-sal-duration="500" data-sal-delay="700">
                                <div className="footer-menu-area sc-footer-service sc-pl-40 sc-blg-pl-30 sc-lg-pl-0 sc-md-mt-20 sc-sm-pt-10">
                                    <h4 className="footer-title white-color sc-md-mb-15">Our Services</h4>
                                    <ul className="footer-menu-list aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                                        <li><a href="#">Join As Readers</a></li>
                                        <li><a href="#">Join As Book Sellers</a></li>
                                        <li>
                  <Link  to={"/books-review"} >Reviews</Link>
                </li>
                                        <li><a href="#">Partnership Enquiries</a></li>
                                        <li><a href="#">Become Affiliate</a></li>
                                        <li><a href="#">Book Free Podcast</a></li>

                                    </ul>
                                </div>


                            </div>
                            <div className="col-xl-4 col-md-6 col-sm-12 sal-animate" data-sal="slide-up" data-sal-duration="500" data-sal-delay="900">
                                <div className="footer-menu-area sc-footer-recent footer-menu-area-left sc-pl-35 sc-blg-pl-15 sc-lg-pl-0 sc-sm-pt-30">
                                    <h4 className="footer-title white-color sc-md-mb-15">Policies</h4>
                                    <ul className="footer-menu-list aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                                        <li><a href="#">Privacy Policies</a></li>
                                        <li><a href="#">Terms &amp; Conditions</a></li>
                                        <li><a href="#">Refund Policies</a></li>
                                        <li><a href="#">Shipping Policy</a></li>
                                        <li><a href="#">Membership Cancellation</a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="copyright-area border-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="copyright-text text-center sal-animate d-flex justify-content-center" data-sal="slide-up" data-sal-duration="800" data-sal-delay="400">
                                        <p>© 2024 <a href="#" target="_blank"> Booksbea.com </a> All Rights Reserved.</p>
                                        <p className='ps-5'>Developed by <a className='' style={{ color: "#0F7FC2" }} href="https://www.smileitsolutions.com/" target="_blank">Smile It Solutions</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        </div>
    )
}
