import React, { useCallback, useEffect, useState } from "react";

import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import config from "../config/Config";
import {useNavigate,  Link } from "react-router-dom";
// import { config } from "dotenv";


const ManageBook = () => {
  //   const [data,setdata]=useState([])
  const [userId, setUserId] = useState("")

  const [detailsTable, setdetailsTable] = useState("35")
  const [details, setDetails] = useState("");
  const [data, setData] = useState([])
  const [selectedRows, setSelectedRows] = useState([]);
  const handleChange = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  // const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
  const columns = [
    {
      name: "Image",
      selector: (row) => <img width={30} height={30} src={row.mainImage != null ? config.api_base_url + `/api/v1/images?fileName=${row.mainImage}` : "https://thumbs.dreamstime.com/b/corrupted-file-document-icon-linear-simple-element-illustration-bad-concept-outline-symbol-design-vector-logo-can-be-used-web-134295411.jpg"} />,
      sortable: false
    },
    {
      name: "Title",
      selector: "title",
      sortable: true
    },
    {
      name: "ISBN",
      selector: "isbn",
      sortable: true
    },
    {
      name: "Inventory",
      selector: "quantity",
      sortable: true
    },
    {
      name: "Price",
      selector: "price",
      sortable: true
    },


    {
      name: "Status",
      //   selector: (row) => (row.publishStatus === "0" ? "Pending":row.publishStatus === "1" ? "Published":row.publishStatus === "2" ? "Rejected" : "neww"),
      cell: (row) => {
        const isPending = row.publishStatus === "0";
        return (
          <>

            <button
              style={{
                backgroundColor: row.publishStatus === "0" ? "#9B700D" : row.publishStatus === "1" ? "Green" : "red", marginRight: "5px", color: "white", borderRadius: "4px", border: "none", fontWeight: "600",
                textTransform: "uppercase",
                padding: " 3px 6px",
                minWidth: " 19px",
              }}
              onClick={() => handleClick(row)}
            >

              {row.publishStatus === "0" ? "Pending" : row.publishStatus === "1" ? "Approved" : "reject"}

            </button>


          </>
        );
      },
      sortable: true
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (row) => {
        //   const isPending = row.publishStatus === "0";
        return (
          <>

            <i
              key={row._id}
              style={{
                marginRight: "12px",
                fontSize: "17px"
              }}
              onClick={() => handleEditBookDetails(row)}
              className="fa fa-pencil"
            ></i>
            <i
              onClick={() => handleClick(row)}
              className="fa fa-trash"
              style={{
                color: "red",
                fontSize: "17px"
              }}
            ></i>
          </>
        );
      },
    },
  ];


  /**
   * Get  Token and  user  id  from   token
   */
  // const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    GetVehicleDetails(page, perPage);
  }, [page, perPage]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };
  useEffect(() => {
    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);
    if (parsedUsername) {
      setUserId(parsedUsername.userId)
    } else {
      console.log('No username found in local storage');
    }
  }, []);
  useEffect(() => {
    if (userId) {
      // Only make the API call if userId is available
      GetVehicleDetails();
    }
  }, [userId]);
  
  const tableData = {
    columns,
    data
  };


  const navigate = useNavigate();
  // const Detailsset = (item) => {
  //   console.log(item, "ItemGet")

  //   if(!item.metaTitle || item.metaTitle === "") {
  //     navigate(`/books/details/${item._id}`, { state: item });
  //   } else {
  //     navigate(`/books/details/${item.metaTitle}`, { state: item });
  //   }
  // }
  const handleEditBookDetails = async (item) => {
    navigate(`/manage-book/edit/${item._id}`, { state: item });


  };

  const handleClick = async (title) => {
    console.log(title._id);
    await setdetailsTable(JSON.stringify(title.id))
  };
 

  // const GetVehicleDetails = () => {
  //   var raw = JSON.stringify({
  //     "pageNumber": 1,
  //     "pageSize": 10,
  //     "filters": [
  //       {
  //         field: 'userId',
  //         condition: 'eq',
  //         value: userId,
  //       }, 
  //     ],
  //   });

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: raw,
  //     redirect: 'follow'
  //   };

  //   fetch(config.api_base_url + "/api/v1/books/list", requestOptions)


  //     .then((response) => {
  //       if (response.status != 200) {
  //         // alert("Please Fill Valid Details")

  //       }

  //       return response.json();
  //     })
  //     .then(async (responseData) => {
  //       console.log("RESULTS HEREforData: for user details id", responseData)
  //       setData(responseData.data)

  //       // console.log(data,"Checking Dqat")


  //       // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









  //     })
  //     .catch(error => console.log('error', error));
  // }

  const GetVehicleDetails = (pageNumber = 1, pageSize = 10) => {
    var raw = JSON.stringify({
      "pageNumber": pageNumber,
      "pageSize": pageSize,
      "filters": [
        {
          field: 'userId',
          condition: 'eq',
          value: userId,
        }, 
      ],
    });
  
    var requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: raw,
      redirect: 'follow'
    };
  
    fetch(config.api_base_url + "/api/v1/books/list", requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          console.error("Error fetching data");
          return; // Exit early if the response is not successful
        }
  
        return response.json();
      })
      .then((responseData) => {
        console.log("Fetched data:", responseData);
  
        // Update the state with the fetched data
        setData(responseData.data || []); // Fallback to an empty array if data is undefined
        setTotalRows(responseData.total || 0); // Fallback to 0 if total is undefined
      })
      .catch(error => console.log('Error fetching vehicle details:', error));
  };
  
  return (
    <>
      <div className="main-sec">
        <div className="d-flex justify-content-end">
          <Link to={"/add-book"} className="btn btn-primary mb-3 mt-1">
            Add Book
          </Link>
        </div>
        <div className="container-fluid shadow">

        <DataTableExtensions {...tableData}>
      <DataTable
        title="Book List"
        columns={columns}
        data={data}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
      />
    </DataTableExtensions>
        </div>





      </div>
    </>
  );
}
export default ManageBook;
