import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import config from '../config/Config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CertificateUpload = () => {
    const [images, setImages] = useState([]);
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState("");

    useEffect(() => {
        const storedUsername = localStorage.getItem('MyToken');
        const parsedUsername = JSON.parse(storedUsername);
        if (parsedUsername) {
            setUserId(parsedUsername.userId);
        } else {
            console.log('No username found in local storage');
        }
        fetchCertificates();
    }, [userId]);

    const handleImageChange = (e) => {
        const files = e.target.files;
        setImages([...images, ...files]);
    };

    const fetchCertificates = async () => {
        try {
            const response = await fetch(`${config.api_base_url}/api/v1/user/getCertificates?userId=${userId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setCertificates(data.data.certificates);
        } catch (error) {
            console.error('Error fetching certificates:', error);
        }
    };

    const uploadImages = async () => {
        setLoading(true);
        const formData = new FormData();
        images.forEach((image) => {
            formData.append(`certificates`, image);
        });
        try {
            const response = await fetch(`${config.api_base_url}/api/v1/user/uploadCertificates?userId=${userId}`, {
                method: 'PUT',
                body: formData,
            });
            const data = await response.json();
            console.log('Upload response:', data);
            toast.success("Certificates Uploaded Successfully !", { position: toast.POSITION.BOTTOM_RIGHT });
        } catch (error) {
            console.error('Error uploading images:', error);
            toast.error('Error uploading images ', { position: toast.POSITION.BOTTOM_RIGHT });
        } finally {
            setLoading(false);
            fetchCertificates();
            setImages([]);
        }
    };

    return (
        <section>
            <header>
                <h3 className="">Upload Certificates</h3>
                <p className="text-center text-danger fw-bold">
                    Upload  Your  Achievements Certificate.
                </p>
            </header>
            <div className='row'>
                <div className='col-md-6'>
                    <input className='form-control p-3 form-control setcustomeinput' type="file" multiple onChange={handleImageChange} />
                </div>
                <div className='col-md-6'>
                    <button className="btn btn-primary w-100 p-2" onClick={uploadImages} disabled={images.length === 0 || loading}>
                        Upload Certificates
                    </button>
                </div>
            </div>
            <div className='row'>
                {images.map((image, index) => (
                    <div className='col-md-2 p-1 m-1' key={index}>
                        <img className='border border-dark' src={URL.createObjectURL(image)} alt={`Image ${index}`} width="100%" />
                    </div>
                ))}
            </div>
            
            <div className='row'>

                <h4 className='my-3'>Uploaded Certificates</h4>
                {certificates.length === 0 ? (
                    <p>Loading certificates...</p>
                ) : (
                    certificates.map((certificate, index) => (
                        <div className='col-md-2 p-1 m-1' key={index}>
                            <img className='border border-dark' src={`${config.api_base_url}/api/v1/images?fileName=${certificate}`} alt={`Certificate ${index}`} width="100%" />
                        </div>
                    ))
                )}
            </div>
        </section>
    );
};

export default CertificateUpload;
