
import React, { useEffect, useState } from 'react';
import { json } from 'react-router-dom';

const AuthorSearch = (props) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState("")
    const [dataSearch, setDataSearch] = useState([])

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch('https://test.booksbea.com/' + props.props.url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                console.log(jsonData)
                setData(jsonData['data']);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };


        if (props.props.url) {
            fetchData();
        }
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    // const handleChange = (e) => {
    //     console.log(e)
    //     setSearch(e)

    //     if (search.length > 2) {
    //         // console.log(data)
    //         const filteredItems = data.filter((item) =>
    //             item.firstName.toLowerCase().includes(search.toLowerCase())
    //         );
    //         setDataSearch(filteredItems)
    //     }
    // }
    const handleChange = (e) => {
        const selectedValue = e.target.value;
        const selectedOption = e.target.options[e.target.selectedIndex];
        const selectedText = selectedOption.text;
        setSearch(selectedValue);

        if (selectedValue !== '-99') { // Skip the default option
            props.onSelect({ value: selectedValue, text: selectedText , type: props.props.field === 'publicationName'? 'publisher':'author' }); // Call the onSelect callback with the selected value
        }
    };

    return (
        <>
            <select className="form-control" name="authorId" onChange={handleChange} value={props.props.selectedID}>
                <option value={-99}>--- Select --- </option>
                <option value={-1}> Other </option>
                {
                    data.map((i, index) => {
                        return (
                            <>
                                <option key={index} value={i.userId}>{props.props.field == 'firstName' ? (i[props.props.field] + " " + i.lastName) : (i[props.props.field])}</option>
                            </>
                        )
                    })
                }
              
            </select>
        </>
    )
}
export default AuthorSearch;