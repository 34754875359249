import React, { useEffect } from 'react';

const BlogSection = () => {
    useEffect(()=>{
        // Detailsbook()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });


      },[]

      )
    return (
        <section className="blog-section mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 blog_list_title">
                        <h1>Our Blogs</h1>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4">
                        <div className="single_article">
                            <div className="post_banner">
                                <img src="https://imageio.forbes.com/specials-images/imageserve/655786c04fde6098b278b4ce/Top-10-favorite-business-books-for-2023/960x0.jpg?format=jpg&width=960" alt="nf" />
                            </div>
                            <div className="post_des">
                                <span>Booksbea </span>
                                <h3>The Exciting World of BooksBea</h3>
                                <p>Welcome to the fascinating world of BooksBea, where the love for books knows no bounds! At BooksBea, we are dedicated to bringing you the best of both worlds - online and offline book shopping experiences.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="single_article">
                            <div className="post_banner">
                                <img src="https://www.instamojo.com/blog/wp-content/uploads/2015/06/ebook4.jpg" alt="nf" />
                            </div>
                            <div className="post_des">
                                <span>Booksbea</span>
                                <h3>Unlocking the Power of E-books and Audiobooks at BooksBea</h3>
                                <p>Experience the magic of literature in digital form with our extensive collection of e-books and audiobooks. Dive into a world of storytelling that transcends traditional boundaries, providing you with unparalleled convenience and accessibility.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="single_article">
                            <div className="post_banner">
                                <img src="https://cdn.penguin.co.in/wp-content/uploads/2022/09/Business-01-scaled.jpg" alt="nf" />
                            </div>
                            <div className="post_des">
                                <span>Booksbea</span>
                                <h3>Explore the Latest Book Releases at BooksBea</h3>
                                <p>Stay up-to-date with the literary world by exploring our latest book releases. From bestsellers to hidden gems, BooksBea offers a diverse collection to cater to every reader's taste. Find your next favorite read with us!</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-12 text-center more_articles">
                        <a className="disabled" href="#">
                            <button><i className="fa fa-angle-double-left" aria-hidden="true"></i> Prev</button>
                        </a>
                        <a className="" href="?pageno=2">
                            <button>Next <i className="fa fa-angle-double-right" aria-hidden="true"></i></button>
                        </a>
                    </div>
                </div> */}
            </div>
        </section>
    );
};

export default BlogSection;
