import React, { useEffect, useState } from 'react'
import config from '../config/Config'

export default function Order() {
    const [newOrder, setNeworder] = useState([])
    const [userId, setUserId] = useState("")



    useEffect(() => {    
        const storedUsername = localStorage.getItem('MyToken');
        const parsedUsername = JSON.parse(storedUsername);    
        if (parsedUsername) {      
          setUserId(JSON.stringify(parsedUsername.userId));     
        } else {
          console.log('No username found in local storage');
        } 
      }, []);

    useEffect(() => {
            GetOrder()
        }, []
    )
    const GetOrder = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(config.api_base_url + `/api/v1/booking/getUserOrders/${userId}`, requestOptions)
            .then((response) => {
                if (response.status != 200) {
                    // alert("Please Fill Valid Details")

                }
                return response.json();
            })
            .then(async (responseData) => {
                console.log("RESULTS HEREforData: for order details ", responseData.data)
                setNeworder(responseData.data)
            })
            .catch(error => console.log('error', error));
    }

    function formatDate(dateString) {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        const day = date.getDate();
        let dayStr = day.toString();
      
        // Appending proper suffix for the day
        if (day >= 11 && day <= 13) {
          dayStr += 'th';
        } else if (day % 10 === 1) {
          dayStr += 'st';
        } else if (day % 10 === 2) {
          dayStr += 'nd';
        } else if (day % 10 === 3) {
          dayStr += 'rd';
        } else {
          dayStr += 'th';
        }
      
        return `${dayStr} ${date.toLocaleDateString('en-US', options).substring(4)}`;
      }
    //   if (!newOrder || !Array.isArray(newOrder)) {
    //     // If data is not available or not an array, return a message or null
    //     return <p>No data available</p>; // Or return null or another placeholder component
    //   }
    
    return (
        <div className='main-order'>
            <div className='container'>
                <div class="search-bars w-50">
                    <input type="text" class="form-control newinput" placeholder="Find Your Order" /><span class="icon-banner"><a href=""><i class="fa fa-search" aria-hidden="true"></i></a></span>
                </div>
                {
                    newOrder !== null ? 
                        ( !newOrder || !Array.isArray(newOrder)   ? 
                            (  
                                <span>No order found</span> ) : 
                            (
                                newOrder.map((item, index) => {
                                return (
                                    <div className='card bg-white border  p-3 mb-3'>
                                        <div className='row'>
                                            <div className='col-sm-2 '>
                                            
                                                <div className='Side1 d-flex justify-content-center'>
                                                    <img src={require("../assets/images/fast-delivery.png")} />
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='ti'>
                                                    <h6>Order Id : booksbea_{item.id}</h6>
                                                    <p>Date :  {item.createdAt}</p>
                                                </div>
                                            </div>
                                            <div className='col-sm-2'>
                                                <p>INR-/ {item.total_amount}</p>
                                            </div>
                                            <div className='col-sm-2'>
                                                <h6 class="AO0UbU">{
                                                    item.bookingStatus == "Confirmed" ? (<span className='text-success'>Confirmed</span>) : (<span className='text-warning'>{item.bookingStatus}</span>)
                                                }</h6>
                                                {/* <p style={{ fontSize: "12px" }}>Your Item Has been Delivered</p> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                                })
                            )
                        )                      
                    : (<span>Searching for  your orders . </span>)                    
                }
            </div>
        </div>
    )
}
