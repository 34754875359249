// MultipleFieldForm.js
import React, { useEffect, useState } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Select from 'react-select'

import 'react-quill/dist/quill.snow.css'; // import styles
import ReactQuill from 'react-quill'; // import ReactQuill

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import config from '../config/Config';


// import Breadcrumb from 'component/Breadcrumb';



const AddProduct = () => {
  // State to manage form fields
  const [formData, setFormData] = useState({
    firstName: '',
    authorName:"",
    lastName: '',
    publisherName:"",
    email: '',
    barcodes: "",
    skU: "",
    shippingCharge: "",
    isbn: "",
    authorId: "",
    publisherId: "",
    categoryId: '13344',
    subCategoryId: "23455",
    pageCount: "0",
    audioBook: "1",
    eBook: "0",
    quantity: "",
    listingPrice: "0",
    price: "0",
    shippingBy: "0",
    shippingChargeStatus: "0",
    shippingChargeAmount: "200",
    bookLaunchDate: "23/02/2023",
    audioBookUpload:"",
    EbookUpload:"",




    // Add more fields as needed
  });

  const [categories,setcategories] = useState([
    'Sci-Fi', 'Health', 'Travel', 'Sports', 'Poetry',
    'Comics', 'Courses', 'Fiction', 'Spiritual', 'Biography',
    'Parenting', 'Childrens', 'Gardening', 'Magazines',
    'Businesses', 'Non-Fiction', 'Academic Books',
    'Competitive Exams', 'Discovery & Research'
  ]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        window.location.reload();
      }
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [inputValue, setInputValue] = useState('');
  const [inpId,setInputId]=useState("")
  const [inpIda,setInputIda]=useState("")
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOptionList, setSelectedOptionList] = useState('');
  const [isFetching, setIsFetching] = useState(false); // Track the fetching status
  // const [addButtonAutho,setaddButtonAutho]=useState(false)
  const [addButtonpublisher,setaddButtonpublisher]=useState(false)



  const handleInputChangeList = async (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (!selectedOption || value !== selectedOption) {
      setSelectedOption('');
    }

    if (value === 'Other') {
      setaddButtonpublisher(true);
    }
  else  if (value.length >= 2) {
      setIsFetching(true); // Start fetching data
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };

        const response = await fetch(`https://test.booksbea.com/api/v1/list/publisher/search?value=${value}`, requestOptions);
        const result = await response.json();
        console.log('API Result:', result.data);

        // setFilteredOptions(result.data);
        if (result.data.length === 0) {
          setaddButtonpublisher(true)

          setInputValue('Other');
          setSelectedOptionList('Other');
          setInputId(-1);
        } else {
          setFilteredOptions(result.data);
          setaddButtonpublisher(false)
        }
      } catch (error) {
        console.error('API Error:', error);
      } finally {
        setIsFetching(false); // Finish fetching data
      }
    } else {
      setFilteredOptions([]);
      setaddButtonpublisher(false)

    }
  };
  //   const handleInputChangeLista = async (event) => {
  //   const value = event.target.value;
  //   setInputValuea(value);

  //   if (!selectedOptionLista || value !== selectedOptionLista) {
  //     setSelectedOptionLista('');
  //   }
  //   if (value === 'Other') {
  //     setaddButtonAutho(true);
  //   }
  // else if (value.length >= 2) {
  //     setIsFetchinga(true);
  //     try {
  //       const requestOptions = {
  //         method: 'GET',
  //         redirect: 'follow'
  //       };

  //       const response = await fetch(`https://test.booksbea.com/api/v1/list/author/search?value=${value}`, requestOptions);
  //       const result = await response.json();
  //       console.log('API Result:', result.data);

  //       if (result.data.length === 0) {

  //         setInputValuea('Other');
  //         setSelectedOptionLista('Other');

  //         setInputIda(-1);
  //         setaddButtonAutho(true);
  //       } else {
  //         setFilteredOptionsa(result.data);
  //         setaddButtonAutho(false)
  //       }
  //     } catch (error) {
  //       console.error('API Error:', error);
  //     } finally {
  //       setIsFetchinga(false);
  //     }
  //   } else {
  //     setFilteredOptionsa([]);
  //     setaddButtonAutho(false)
  //   }
  // };

  const handleOptionClick = (option) => {
    setSelectedOption(option.publicationName);
    setInputValue(option.publicationName);
    setInputId(option.userId)
    setFilteredOptions([]);
  };



  // author



  const [inputValuea, setInputValuea] = useState('');
  const [filteredOptionsa, setFilteredOptionsa] = useState([{ value: '-1', label: 'Other' }]); // Include 'Other' by default
  const [isFetchinga, setIsFetchinga] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [addButtonAutho,setaddButtonAutho]=useState(false)
  console.log('Selected DataFor aut:', inputValuea);
  // console.log(selectedOption.value,"selectedOption.value")

  const fetchData = async () => {
    setIsFetchinga(true);
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://test.booksbea.com/api/v1/list/author/search?value=${inputValuea}`, requestOptions);
      const result = await response.json();

      if (result.data && result.data.length > 0) {
        const options = result.data.map((option) => ({
          value: option.userId,
          label: `${option.firstName} ${option.lastName}`
        }));
        // Add 'Other' option along with API response data
        setFilteredOptionsa([...options, { value: '-1', label: 'Other' }]);
      }
    } catch (error) {
      console.error('API Error:', error);
    } finally {
      setIsFetchinga(false);
    }
  };

  const handleInputChangeLista = (value) => {
    console.log(value,"value===>>>>>>>>>>>>>>>>>>>>>>>>>>.")
    setInputValuea(value);
    if (value.length >= 2) {
      fetchData();
    }
  };
  const [inputValuea1, setInputValuea1] = useState('');
  const [filteredOptionsa1, setFilteredOptionsa1] = useState([{ value: '-1', label: 'Other' }]); // Include 'Other' by default
  const [isFetchinga1, setIsFetchinga1] = useState(false);
  const [selectedOption12, setSelectedOption12] = useState(null);
  const [addButtonAutho1,setaddButtonAutho1]=useState(false)
  console.log('Selected Data for pub:', inputValuea1);

  const fetchData1 = async () => {
    setIsFetchinga1(true);
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://test.booksbea.com/api/v1/list/publisher/search?value=${inputValuea1}`, requestOptions);
      const result = await response.json();

      if (result.data && result.data.length > 0) {
        const options = result.data.map((option) => ({
          value: option.userId,
          label: `${option.firstName} ${option.lastName}`
        }));
        // Add 'Other' option along with API response data
        setFilteredOptionsa1([...options, { value: '-1', label: 'Other' }]);
      }
    } catch (error) {
      console.error('API Error:', error);
    } finally {
      setIsFetchinga1(false);
    }
  };

  const handleInputChangeLista1 = (value) => {
    setInputValuea1(value);

    if (value.length >= 2) {
      fetchData1();
    }
  };
  const [auhorAdd1,setauhorAdd1]=useState(false)

  const addNewAu1=()=>{
    setauhorAdd1(!auhorAdd1)

  }


  // const handleInputChangeLista = async (event) => {
  //   const value = event.target.value;
  //   setInputValuea(value);


  //   if (!selectedOptionLista || value !== selectedOptionLista) {
  //     setSelectedOptionLista('');
  //   }

  //   if (value.length >= 2) {
  //     setIsFetchinga(true); // Start fetching data
  //     try {
  //       const requestOptions = {
  //         method: 'GET',
  //         redirect: 'follow'
  //       };

  //       const response = await fetch(`https://test.booksbea.com/api/v1/list/author/search?value=${value}`, requestOptions);
  //       const result = await response.json();
  //       console.log('API Result:', result.data);

  //       setFilteredOptionsa(result.data);
  //     } catch (error) {
  //       console.error('API Error:', error);
  //     } finally {
  //       setIsFetchinga(false); // Finish fetching data
  //     }
  //   } else {
  //     setFilteredOptionsa([]); // <-- Fix: Update the correct state
  //   }
  // };




  const handleOptionClicka = (option) => {
    // setSelectedOptionLista(option.firstName);
    setInputValuea(option.firstName);
   console.log(option,"option")
   setInputIda(option.userId)
    setFilteredOptionsa([]);
  };





  useEffect(() => {
    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);

    if (parsedUsername) {
      console.log(parsedUsername);
      // setemail(parsedUsername.email)
      setUserId(parsedUsername.userId)
    } else {
      console.log('No username found in local storage');
    }
    Detailsbook()

  }, []);

  const Detailsbook=()=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(config.api_base_url+"/api/v1/category/getList", requestOptions)
    .then((response) => {
      if(response.status != 200){
          alert("Please Fill Valid Details")


      }

      return response.json();
  })
      .then(async (responseData) => {
          console.log("Category list==>>>data", responseData)
          setcategories(responseData.data)






          // nav("/");




          // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









        })
      .catch(error => console.log('error', error));
  }
  const [userId, setUserId] = useState("")




  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (e) => {
    // const { name, files } = e.target;

      const file = e.target.files[0];
      setSelectedFile(file)


      console.log(file, "selectedImage")

  };

  const [selectedpdf, setSelectedpdf] = useState(null);
  const handlePdfChange = (e) => {
    // const { name, files } = e.target;

      const file = e.target.files[0];
      setSelectedpdf(file)


      console.log(file, "selectedImage")

  };




  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedImages, setSelectedImages] = useState([]);
  // const [selectedOption, setSelectedOption] = useState('');
  const [selectedOption1, setSelectedOption1] = useState('');
  const [selectedOption2, setSelectedOption2] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const [content, setContent] = useState('');
  const [content1, setContent1] = useState('');

  const handleEditorChange = (value) => {
    setContent(value);
    console.log(value);
  };
  const handleEditorChange1 = (value) => {
    setContent1(value);
    console.log(value);
  };


  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
    console.log(e.target.value, "e.target.value")
  };
  const handleDropdownChange1 = (e) => {
    setSelectedOption1(e.target.value);
    console.log(e.target.value, "e.target.value")
  };
  const [selectEbook,setSelectedOptionEbook]=useState("")
  const [selectAudio,setSelectedOptionAudioBook]=useState("")
  const handleDropdownEbook = (e) => {
    setSelectedOptionEbook(e.target.value);
    console.log(e.target.value, "e.target.value")
  };
  const handleDropdownAudioBook = (e) => {
    setSelectedOptionAudioBook(e.target.value);
    console.log(e.target.value, "e.target.value")
  };
  const handleDropdownChange2 = (e) => {
    setSelectedOption2(e.target.value);
    console.log(e.target.value, "e.target.value")
  };


  const handleImagesChange = (e) => {
    const files = e.target.files;

    // You can perform additional validations or processing here

    setSelectedImages([...selectedImages, ...files]);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    // You can perform additional validations or processing here

    await setSelectedImage(file);
    console.log(selectedImage, "selectedImage")
  };
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // You can perform actions with the form data here
    console.log('Form Data hddhd:', formData);
  };

const [selectCat,setselectCat]=useState("")
const [auhorAdd,setauhorAdd]=useState(false)
const [publisherAdd,setpublisherAdd]=useState(false)
const addNewAu=()=>{
  setauhorAdd(!auhorAdd)

}
const addNewPu=()=>{
  setpublisherAdd(!publisherAdd)

}

  const handleChange = (e) => {
    // Update the selected option when the dropdown value changes
    setselectCat(e.target.value);
    console.log(e.target.value, "e.target.value")
  };


  const handleSubmit1 = (e) => {
    e.preventDefault();
    checke( inputValuea, inputValuea1);
    // Rest of your handleSubmit logic
  };



console.log("filererrttt.....>>>>",filteredOptionsa[0].value)



  const checke = () => {
    setIsLoading(true)
    const formdatas = new FormData();

    formdatas.append("title", formData.firstName);
    formdatas.append("shortDescription", content);
    formdatas.append("description", content1);
    formdatas.append("userId", userId);
    formdatas.append("barcode", formData.barcodes);
    formdatas.append("author_name", formData.authorName);
    formdatas.append("publisher_name", formData.publisherName);

    formdatas.append("isbn", formData.isbn);
    formdatas.append("authorId", filteredOptionsa[0].value);
    formdatas.append("publisherId", filteredOptionsa1[0].value);

    formdatas.append("categoryId", selectCat);
    formdatas.append("subCategoryId", formData.subCategoryId);
    formdatas.append("bookLanguages", "[\"English\",\"Hindi\"]");
    formdatas.append("pageCount", formData.pageCount);
    formdatas.append("eBook", selectEbook);
    formdatas.append("sku", formData.skU);
    formdatas.append("audioBook", selectAudio);
    formdatas.append("quantity", formData.quantity);
    formdatas.append("listingPrice", formData.listingPrice);
    formdatas.append("price", formData.price);
    formdatas.append("shippingBy", formData.shippingBy);
    formdatas.append("shippingChargeStatus", formData.shippingChargeStatus);
    formdatas.append("shippingChargeAmount", "5.0");
    formdatas.append("bookLaunchDate", formData.bookLaunchDate);
    formdatas.append("metaFields", "");
    formdatas.append("mainImage", selectedImage);
    formdatas.append("audioBookFiles",selectedFile);
    formdatas.append("eBookFile",selectedpdf);
    // formdatas.append("additionalImages",selectedImages);
    selectedImages.forEach((image) => {
      formdatas.append(`additionalImages`, image);



      // formdatas.append(`additionalImages[${index}]`,
      // {
      //     uri: image,
      //     type: 'image/jpg',
      //     name: image
      // });

      //   console.log(`additionalImages[${index}]`, image)
    });



    var requestOptions = {
      method: 'POST',
      body: formdatas,
      redirect: 'follow',


    };
    console.log(formData, "check forme");
    console.log(inputValuea1,inputValuea,'inputValuea1inputValuea1inputValuea1inputValuea1inputValuea1inputValuea1');


    // return false

    fetch(config.api_base_url + "/api/v1/books/addnew", requestOptions)
      .then((response) => {
        if (response.code != 201) {
          // alert("Please Fill Valid Details")


        }


        return response.json();
      })

      .then(async (responseData) => {
        if(responseData.code==201){
          // toast("Book created  successfully")
          toast.success("Book created  successfully");

          // alert("Book created  successfully")
          setIsLoading(false)

        }

    else if (responseData.code == 404) {
      // alert("Please fill all details")
      toast.error("Please fill all details");

      setIsLoading(false)
    }

    else if (responseData.code == 409) {
          // alert("Please Change Title or Isbn")
          toast.error("Please Change Title or ISBN");

          setIsLoading(false)
        }
        else if (responseData.code == 500) {
          // alert("Please Fill All Details")
          setIsLoading(false)

          toast.error("Please FIll Valid Details!")

        } else {
          console.log("Profile Details==>>>data", responseData)
          // alert('Book Created Successfully')
        }


      })
      .catch(error => console.log('error', error));

  }

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]




  return (
    <>
      <section className='book_form'>
        <Grid
          container
          //   className='mt-5'
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "50px ", marginTop: "100px", }}
        //   sx={{ backgroundColor: theme.palette.common.black, height: '100%', minHeight: '100vh' }}
        >

          <Card className='p-5 newpppset shadow  border ' style={{ background: "#F7F7FA", borderRadius: "20px" }}>
            <div className='mainHeader_'>
              <h3> Add Your Book</h3>
            </div>

            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className='row'>
                <div className='col-md-6' >

                  <div className='main '>
                    <label htmlFor="exampleInputEmail1" className='form-lable mb-3' >
                      Book Name:
                      <input
                        id='exampleInputEmail1'
                        type="text"
                        name="firstName"
                        className='form-control'
                        placeholder='Title Of Book'
                        value={formData.firstName}
                        onChange={handleInputChange}
                      />
                    </label>



                  </div>
                </div>
                <div className='col-md-6' >
                  <div className='main'>
                    <label htmlFor="exampleInputEmail1" className='form-lable mb-3' >
                      Sku:
                      <input
                        id='exampleInputEmail1'
                        type="text"
                        name="skU"
                        className='form-control'
                        placeholder='Sku'
                        value={formData.skU}
                        onChange={handleInputChange}
                      />
                    </label>

                  </div>
                </div>
              </div>



              <div className='row'>
                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="exampleInputEmail1" className='form-lable mb-3' >
                      Front Cover Image:
                      <input
                        type="file"
                        id="imageUpload"
                        name="imageUpload"
                        accept="image/*"
                        className='form-control'
                        onChange={handleImageChange}
                      />

                      {selectedImage && (
                        <div>
                          <p>Selected Image:</p>
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="Selected"
                            style={{ maxWidth: '100%', maxHeight: '100px' }}
                          />
                        </div>
                      )}
                    </label>

                  </div>


                </div>
                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="exampleInputEmail1" className='form-lable mb-3' >
                      Additional Image (Front and Back)
                      <input
                        className='form-control'
                        type="file"
                        id="imagesUpload"
                        name="imagesUpload"
                        accept="image/*"
                        multiple
                        onChange={handleImagesChange}
                      />

                      {selectedImages.length > 0 && (
                        <div>
                          <p>Selected Images:</p>
                          {selectedImages.map((image, index) => (
                            <img
                              key={index}
                              src={URL.createObjectURL(image)}
                              alt={`Selected ${index}`}
                              style={{ maxWidth: '200px', maxHeight: '100px', marginRight: '10px' }}
                            />
                          ))}
                        </div>
                      )}
                    </label>

                  </div>


                </div>
                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="authorId" className='form-lable mb-3' >
                      Barcode
                      <input
                        id='authorId'
                        type="text"
                        name="barcodes"
                        className='form-control'
                        placeholder='Barcode'
                        value={formData.barcodes}
                        onChange={handleInputChange}
                      />
                    </label>

                  </div>

                </div>

              </div>
              <div className='row'>
              <div className='col-md-4'>
                  <div className='main position-relative'>
                    <label htmlFor="authorname" className='form-lable mb-3' >
                      Authore Name
                      <Select
        value={selectedOption}
        options={filteredOptionsa}
        onInputChange={handleInputChangeLista}
        onChange={(selected) => {
          setSelectedOption(selected);
          setInputValuea(selected ? selected.value : '');
          setaddButtonAutho(selected && selected.value === '-1');

        }}
        isLoading={isFetchinga}
        placeholder='Select Author or type to search'
      />

{addButtonAutho && (
  <button onClick={()=>addNewAu()} className='addAA'>Add Author</button>
)}
                    </label>




                  </div>


              {
                auhorAdd?

        <div className='main'>
          <label htmlFor="authorname" className='form-lable mb-3'>
            Author Name
            <input
              id='auth'
              type="text"
              name="authorName" // Change the name to match your state
              className='form-control'
              placeholder='Write Author Name here'
              value={formData.authorName}
              onChange={handleInputChange}
            />
          </label>
        </div>
     :null
              }
                </div>


                <div className='col-md-4'>
                  <div className='main position-relative'>
                    <label htmlFor="Publisher" className='form-lable mb-3' >
                      Publisher Name
                      <Select
        value={selectedOption12}
        options={filteredOptionsa1}
        onInputChange={handleInputChangeLista1}
        onChange={(selected) => {
          setSelectedOption12(selected);
          setInputValuea1(selected ? selected.value : '');
          setaddButtonAutho1(selected && selected.value === '-1');

        }}
        isLoading={isFetchinga1}
        placeholder='Select Publisher search'
      />

{addButtonAutho1 && (
  <button onClick={()=>addNewAu1()} className='addAA'>Add Publisher</button>
)}
                    </label>




                  </div>


              {
                auhorAdd1?
                <div className='main'>
          <label htmlFor="authorname" className='form-lable mb-3'>
            Publisher  Name
            <input
              id='auth'
              type="text"
              name="publisherName" // Change the name to match your state
              className='form-control'
              placeholder='Write Publisher Name here'
              value={formData.publisherName}
              onChange={handleInputChange}
              // value={formData.authorName}
              // onChange={handleInputChange}
            />
          </label>
        </div>:null
              }
                </div>
                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="isbn" className='form-lable mb-3' >
                      ISBN:
                      <input
                        id='isbn'
                        type="text"
                        name="isbn"
                        className='form-control'
                        placeholder='ISBN '
                        value={formData.isbn}
                        onChange={handleInputChange}
                      />
                    </label>

                  </div>
                </div>
              </div>
{/*
              {
                publisherAdd?
                <div className='row'>
                <div className='col-md-4'></div>
      <div className='col-md-4'>
        <div className='main'>
          <label htmlFor="authorname" className='form-lable mb-3'>
            Publisher Name
            <input
              id='auth'
              type="text"
              name="publisherName" // Change the name to match your state
              className='form-control'
              placeholder='Write Publisher Name here'
              value={formData.publisherName}
              onChange={handleInputChange}
            />
          </label>
        </div>
      </div>
    </div>:null
              } */}





              {/*  */}

              <div className='row'>
                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="exampleInputEmail1" className='form-lable mb-3' >
                      Category
                      <p>
                        {/* <select className='form-control w-100' value={selectedOption} onChange={handleChange}>
            <option value="True">True</option>
            <option value="False">False</option>

          </select> */}
                        <select id="category" className="form-select" value={selectCat} onChange={handleChange}>
                          {categories.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.categoryName}
                            </option>
                          ))}
                        </select>
                      </p>
                    </label>

                  </div>
                </div>


                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="exampleInputEmail1" className='form-lable mb-3' >
                      Sub Category
                      <input
                        id='pageCount'
                        type="number"
                        name="pageCount"
                        className='form-control'
                        placeholder='Sub Category'
                        // value={formData.pageCount}
                        // onChange={handleInputChange}
                      />
                    </label>

                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="exampleInputEmail1" className='form-lable mb-3' >
                      Shipping Charge:
                      <select
                        className='w-100 form-control'
                        id="exampleInputEmail1"
                        name="dropdown"
                        value={selectedOption2}
                        onChange={handleDropdownChange2}
                      >
                        <option value="">Select...</option>
                        <option value="option1">Self Shipping</option>
                        <option value="option2">Booksbea</option>
                        {/* <option value="option3">Option 3</option> */}

                      </select>
                    </label>

                  </div>
                </div>
              </div>

              {/*  */}

              <div className='row'>
                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="pageCount" className='form-lable mb-3' >
                      Page Count
                      <input
                        id='pageCount'
                        type="number"
                        name="pageCount"
                        className='form-control'
                        placeholder='Page Count'
                        value={formData.pageCount}
                        onChange={handleInputChange}
                      />
                    </label>

                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="eBook" className='form-lable mb-3' >
                      E-Book
                      {/* <input
                        id='eBook'
                        type="text"
                        name="eBook"
                        className='form-control'
                        placeholder='E-Book'
                        value={formData.eBook}
                        onChange={handleInputChange}
                      /> */}
                       <p>
                        <select
                          className='w-100 form-control'
                          id="exampleInputEmail1"
                          name="dropdown"
                          value={selectEbook}
                          onChange={handleDropdownEbook}
                        >
                          <option value="">Select...</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                          {/* <option value="option3">Option 3</option> */}

                        </select>
                      </p>
                    </label>

                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="audioBook" className='form-lable mb-3' >
                      AudioBook
                      <p>
                        <select
                          className='w-100 form-control'
                          id="exampleInputEmail1"
                          name="dropdown"
                          value={selectAudio}
                          onChange={handleDropdownAudioBook}
                        >
                          <option value="">Select...</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                          {/* <option value="option3">Option 3</option> */}

                        </select>
                      </p>
                    </label>

                  </div>
                </div>
              </div>
              <div className='row'>
              <div className='col-md-6'>
                  <div className='main'>
                    <label htmlFor="eBookFile" className='form-lable mb-3' >
                     Upload E-book
                      <input
                        id='eBookFile'
                        type="file"
                        name="eBookFile"
                        className='form-control'
                        placeholder='Page Count'
                        accept="application/pdf"
                        // value={formData.EbookUpload}
                        onChange={handlePdfChange}
                      />
                    </label>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='main'>
                    <label htmlFor="audioBookFiles" className='form-lable mb-3' >
                     Upload Audio Book
                      <input
                        id='audioBookFiles'
                        type="file"
                        name="audioBookFiles"
                        className='form-control'
                        placeholder='Page Count'
                        accept="audio/mpeg"
                        // value={formData.audioBookUpload}
                        onChange={handleFileChange}
                      />
                    </label>

                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="quantity" className='form-lable mb-3' >
                      Quantity
                      <input
                        id='exampleInputEmail1'
                        type="text"
                        name="quantity"
                        className='form-control'
                        placeholder='Total Quantity'
                        value={formData.quantity}
                        onChange={handleInputChange}
                      />
                    </label>

                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="listingPrice" className='form-lable mb-3' >
                      listingPrice
                      <input
                        id='listingPrice'
                        type="text"
                        name="listingPrice"
                        className='form-control'
                        placeholder='Product Name'
                        value={formData.listingPrice}
                        onChange={handleInputChange}
                      />
                    </label>

                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='main'>
                    <label htmlFor="price" className='form-lable mb-3' >
                      Price
                      <input
                        id='price'
                        type="text"
                        name="price"
                        className='form-control'
                        placeholder='Product Name'
                        value={formData.price}
                        onChange={handleInputChange}
                      />
                    </label>

                  </div>
                </div>
              </div>

              <div className='main'>
                <label htmlFor="floatingTextarea" className='form-lable mb-3' >
                  Short Description:
                  <ReactQuill
                    theme="snow" // or "bubble"
                    value={content}
                    onChange={handleEditorChange}
                  />
                </label>

              </div>
              <div className='main'>
                <label htmlFor="floatingTextarea" className='form-lable mb-3' >
                  Description:
                  <ReactQuill
                    theme="snow" // or "bubble"
                    value={content1}
                    onChange={handleEditorChange1}
                  />
                </label>

              </div>

              {/* Add more fields as needed */}
              {/* <button onClick={() => checke()} className='btn btn-primary mt-3' style={{ float: "right" }} type="submit">Submit</button> */}

              <button
              style={{ float: "right" }}
      onClick={handleSubmit1}
      disabled={isLoading}
      className={isLoading ? 'btn btn-primary mt-3 button-loading' : 'btn btn-primary mt-3'}
    >
    Submit  {isLoading ? <span className="spinner"></span> : ''}
    </button>
            </form>


            {/* <button onClick={()=>NewBook()} style={{float:"right"}} type="submit">Submit</button> */}


          </Card>
        </Grid>
        <ToastContainer />
      </section>
    </>

  );
};

export default AddProduct;
