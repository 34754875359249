import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar-edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import config from '../config/Config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ProfilePhotoUpload = ({ data }) => {
  const [profileData, setProfileData] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [userId, setUserId] = useState('');
  const [editorPreview, setEditorPreview] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to handle popup visibility

  useEffect(() => {
    setProfileData(data);
  }, [data]);

  useEffect(() => {
    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);

    if (parsedUsername) {
      console.log(parsedUsername);
      setUserId(parsedUsername.userId);
    } else {
      console.log('No username found in local storage');
    }
  }, []);

  const onClose = () => {
    setEditorPreview(null);
    setSelectedFile(null);
  };

  const onCrop = (view) => {
    setEditorPreview(view);
  };

  const handleCameraClick = () => {
    setIsPopupOpen(true); // Open the popup
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close the popup
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!editorPreview) {
      toast.error('Please select and edit a profile photo first!', { position: toast.POSITION.BOTTOM_RIGHT });
      return;
    }

    const blob = await fetch(editorPreview).then((res) => res.blob());
    const file = new File([blob], 'profileImage.png', { type: 'image/png' });

    var formdata = new FormData();
    formdata.append('profileImage', file);

    var requestOptions = {
      method: 'PUT',
      body: formdata,
      redirect: 'follow',
    };

    fetch(config.api_base_url + `/api/v1/user/profileImage?userId=${userId}`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          toast.error('Please Fill Valid Details', { position: toast.POSITION.BOTTOM_RIGHT });
        }
        return response.json();
      })
      .then(async (responseData) => {
        console.log('Profile Details==>>>data', responseData);
        toast.success('Profile image updated successfully!', { position: toast.POSITION.BOTTOM_RIGHT });
        setIsPopupOpen(false); // Close popup on success
      })
      .catch((error) => console.log('error', error));
  };

  return (
    <div className="profile-photo-upload position-relative pro">
      <div className="image-preview" style={{ width: '150px', borderRadius: '50%', position: 'relative' }}>
        {editorPreview ? (
          <img src={editorPreview} alt="Edited Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        ) : profileData ? (
          <img
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            src={config.api_base_url + `/api/v1/images?fileName=${profileData}`}
            alt="Preview"
          />
        ) : (
          <FontAwesomeIcon icon={faUserCircle} size="6x" />
        )}
        <button className="camera-icon-button" onClick={handleCameraClick}>
          <FontAwesomeIcon icon={faCamera} size="2x" />
        </button>
      </div>

      {/* Popup for Avatar Editing */}
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-popup" onClick={handleClosePopup}>×</button>
            <Avatar
              width={"100%"}
              height={295}
              onCrop={onCrop}
              onClose={onClose}
              src={selectedFile}
              label="Choose a profile picture"
            />
            <button type="button" className="btn-set" onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default ProfilePhotoUpload;
