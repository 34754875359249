import React, { useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../assets/css/Review.css"
import CircularProgressBar from '../Component/ProgressRating'; // Adjust the path based on your project structure
import { Link } from 'react-router-dom';


const ReviewPage = () => {
    const [percentage, setPercentage] = useState(0);

    const [cards, setAllCards] = useState([
        {
            id: 1,
            // icon:require("../assets/images/best.png")
        },
        {
            id: 2,
            icon: require("../assets/images/icon1.png")
        },
        {
            id: 3,
            // icon:require("../assets/images/icon3.png")
        },








    ])

    useEffect(() => {
        // Example: Set percentage to 50% after 1 second
        const timeout = setTimeout(() => {
            setPercentage(50);
        }, 1000);

        return () => clearTimeout(timeout);
    }, []);


    useEffect(()=>{
        // Detailsbook()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });


      },[]

      )
    const rating = 8.5;
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        focus: false,
        pauseOnHover: true,
        arrow: true,

        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>



            <div className='ReviewFirstSlide'>
                <div className='container'>
                    <div class="gp-slide-caption">
                        <div className='newRate position-relative'>
                            <CircularProgressBar rating={rating} />
                            <div>
                                <h6 className='setText'>Great</h6>
                            </div>
                        </div>
                        <div className='newRate1 position-relative'>
                            {/* <CircularProgressBar rating={rating} /> */}
                            <div>
                                <h3 className='text-white text-center'>7.7</h3>


                                <h6 className='text-white'>User Ave</h6>
                            </div>
                            <div>
                                <h6 className='setText'></h6>
                            </div>
                        </div>
                        <h2 class="gp-slide-caption-title">Titan Fall</h2><h4 class="gp-slide-caption-text">Efficiently fashion integrated infomediaries without client-based scenarios. Professionally strategize backward-compatible niche markets after dynamic methods of empowerment. Compellingly create enabl...</h4></div>

                </div>
                <div className='setTopSHa'></div>
                <div class="gp-bottom-bg-gradient-overlay"></div>

            </div>

            <section className='section-1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-8'>


                            <div className='setHeading'>
                                <h5><span style={{ color: "red" }}>L</span>atest Book</h5>
                            </div>
                            <div className='row'>
                                {
                                    cards.map((i, index) => {
                                        return (
                                            <div className='col-sm-4 mb-3 '>
                                            <Link to={"/SinglePage"} target="_blank" >
                                                <div className='Crab1 position-relative'>
                                                <div className="overlay"></div>
                                                    <img src={require("../assets/images/Taurus.png")} />
                                                    <div className='ratingset'>
                                                        <CircularProgressBar rating={rating} />
                                                    </div>

                                                    <h3>The Book Of Tranus</h3>

                                                </div>
                                                </Link>

                                            </div>

                                        )
                                    }

                                    )

                                }


                            </div>

                            <div>





                            </div>





                        </div>
                        <div className='col-sm-4'>
                            <div class="wpb_column vc_column_container vc_col-sm-4">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                        <div id="gp_ranking_wrapper_1" class="gp-ranking-wrapper gp-vc-element ">
                                            <div class="gp-inner-loop">
                                                <div className='setHeading'>
                                                    <h5><span style={{ color: "red" }}>N</span>ew Book</h5>
                                                </div>
                                                <section class="top gp-post-item gp-rated-item gp-top-ranked-item  post-2704 page type-page status-publish has-post-thumbnail gp_hubs-hub-games gp_hubs-hub-pc gp_hubs-hub-ps3 gp_hubs-hub-ps4 gp_hubs-hub-wii release-date-april-2014 genre-racing rating-children developed-by-respawn-entertainment publisher-activision">

                                                    <div class="gp-ranking-number-overlay">1</div>
                                                    <div class="gp-bg-overlay-dark"></div>
                                                    <div class="gp-foreground-overlay">
                                                        <div class="gp-post-thumbnail gp-loop-featured">
                                                            <div class=" gp-image-above"> <a href="https://gauge.ghostpool.com/mario-kart-8/"> <img src="https://gauge.ghostpool.com/wp-content/uploads/photodune-2360363-color-the-town-s-120x120.jpg" data-rel="https://gauge.ghostpool.com/wp-content/uploads/photodune-2360363-color-the-town-s-240x240.jpg" width="120" height="120" alt="Mario Kart 8" class="gp-post-image" /> </a></div>
                                                        </div>
                                                        <div class="gp-loop-content gp-image-align-left">
                                                            <div class="gp-rating-wrapper">
                                                                <div class="gp-site-rating-wrapper gp-large-rating gp-rating-gauge">
                                                                    <div class="gp-rating-inner">
                                                                        <div class="gp-score-clip gp-no-score-clip-1">
                                                                            <div class="gp-score-spinner" ></div>
                                                                        </div>
                                                                        <div class="gp-score-clip gp-no-score-clip-2">
                                                                            <div class="gp-score-filler"></div>
                                                                        </div>
                                                                        <div class="gp-score-inner">
                                                                            <div class="gp-score-table">
                                                                                <div class="gp-score-cell"> 9.4</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <h4 class="gp-rating-text">Amazing</h4>
                                                                </div>
                                                            </div>
                                                            <h3 class="gp-loop-title"><a href="https://gauge.ghostpool.com/mario-kart-8/" title="Mario Kart 8">Mario Kart 8</a></h3>
                                                            <div class="gp-loop-meta"> <span class="gp-post-meta">Release Date: <a href="https://gauge.ghostpool.com/release-date/april-2014/" rel="tag">April 2014</a></span></div>
                                                        </div>
                                                    </div>
                                                </section>

                                                <section class="backGr gp-post-item gp-rated-item gp-other-ranked-item  post-2563 page type-page status-publish has-post-thumbnail gp_hubs-hub-games gp_hubs-hub-pc gp_hubs-hub-ps3 gp_hubs-hub-ps4 gp_hubs-hub-wii gp_hubs-hub-xbox-360 gp_hubs-hub-xbox-one release-date-may-2015 genre-action genre-first-person-shooter genre-team-based rating-mature developed-by-bluepoint-games developed-by-respawn-entertainment publisher-activision" >
                                                    <div class="gp-ranking-number-overlay">2</div>
                                                    <div class="gp-bg-overlay-dark"></div>
                                                    <div class="gp-foreground-overlay d-flex position-relative">
                                                        <div class="gp-post-thumbnail gp-loop-featured">
                                                            <div class=" gp-image-align-left"> <a href="https://gauge.ghostpool.com/call-of-duty-ghosts/"> <img src="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-80x80.jpg" data-rel="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-160x160.jpg" width="80" height="80" alt="Call Of Duty: Ghosts" class="gp-post-image" /> </a></div>
                                                        </div>

                                                        <div className='details'>
                                                            <h3 className='text-white'>The Taures</h3>
                                                            <p className='text-white'>Release Date 23/09/2023</p>

                                                        </div>
                                                        <div className='ratingset'>
                                                            <CircularProgressBar rating={rating} />
                                                        </div>

                                                    </div>
                                                </section>
                                                <section class="backGr gp-post-item gp-rated-item gp-other-ranked-item  post-2563 page type-page status-publish has-post-thumbnail gp_hubs-hub-games gp_hubs-hub-pc gp_hubs-hub-ps3 gp_hubs-hub-ps4 gp_hubs-hub-wii gp_hubs-hub-xbox-360 gp_hubs-hub-xbox-one release-date-may-2015 genre-action genre-first-person-shooter genre-team-based rating-mature developed-by-bluepoint-games developed-by-respawn-entertainment publisher-activision" >
                                                    <div class="gp-ranking-number-overlay">2</div>
                                                    <div class="gp-bg-overlay-dark"></div>
                                                    <div class="gp-foreground-overlay d-flex position-relative">
                                                        <div class="gp-post-thumbnail gp-loop-featured">
                                                            <div class=" gp-image-align-left"> <a href="https://gauge.ghostpool.com/call-of-duty-ghosts/"> <img src="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-80x80.jpg" data-rel="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-160x160.jpg" width="80" height="80" alt="Call Of Duty: Ghosts" class="gp-post-image" /> </a></div>
                                                        </div>

                                                        <div className='details'>
                                                            <h3 className='text-white'>The Taures</h3>
                                                            <p className='text-white'>Release Date 23/09/2023</p>

                                                        </div>
                                                        <div className='ratingset'>
                                                            <CircularProgressBar rating={rating} />
                                                        </div>

                                                    </div>
                                                </section>
                                                <section class="backGr gp-post-item gp-rated-item gp-other-ranked-item  post-2563 page type-page status-publish has-post-thumbnail gp_hubs-hub-games gp_hubs-hub-pc gp_hubs-hub-ps3 gp_hubs-hub-ps4 gp_hubs-hub-wii gp_hubs-hub-xbox-360 gp_hubs-hub-xbox-one release-date-may-2015 genre-action genre-first-person-shooter genre-team-based rating-mature developed-by-bluepoint-games developed-by-respawn-entertainment publisher-activision" >
                                                    <div class="gp-ranking-number-overlay">2</div>
                                                    <div class="gp-bg-overlay-dark"></div>
                                                    <div class="gp-foreground-overlay d-flex position-relative">
                                                        <div class="gp-post-thumbnail gp-loop-featured">
                                                            <div class=" gp-image-align-left"> <a href="https://gauge.ghostpool.com/call-of-duty-ghosts/"> <img src="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-80x80.jpg" data-rel="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-160x160.jpg" width="80" height="80" alt="Call Of Duty: Ghosts" class="gp-post-image" /> </a></div>
                                                        </div>

                                                        <div className='details'>
                                                            <h3 className='text-white'>The Taures</h3>
                                                            <p className='text-white'>Release Date 23/09/2023</p>

                                                        </div>
                                                        <div className='ratingset'>
                                                            <CircularProgressBar rating={rating} />
                                                        </div>

                                                    </div>
                                                </section>

                                            </div>
                                        </div>
                                        <div class="vc_empty_space" style={{ height: "40px" }}><span class="vc_empty_space_inner"></span></div>

                                        <div class="vc_empty_space" style={{ height: "40px" }}><span class="vc_empty_space_inner"></span></div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
            </section>

            <section className='mt-5'>
                <div className='container'>
                <div class='wdog '>

<div className='row'>
<div className='col-sm-3'>
                    <div>
                        <img src={require("../assets/images/nnn.jpg")}  />
                    </div>
                    </div>
                    <div className='col-sm-7'>

                    <div className='content_bottom'>
                    <h3>watch Dogs</h3>
                    <p > <span style={{background:"red",color:"white",padding:"6px"}}>ps4</span> <span style={{background:"#0F7FC2",color:"white",padding:"6px"}}>ps4</span> </p>
                    <p>Seamlessly embrace front-end core competencies without sticky convergence. Quickly fashion front-end human capital without viral markets. Energistically facilitate technically sound sources before error-free e-commerce. Compellingly transition user f...</p>

                    </div>
                    </div>
                    <div className='col-sm-2 d-flex justify-content-center'>

                    <div className='newratingset'>
                                                            <CircularProgressBar rating={rating} />
                                                            <h5 className='text-danger text-center'>GOOD</h5>
                                                        </div>



                    </div>
                                        </div>
                    <div></div>

                </div>
                </div>
            </section>
            {/* <section className='sec-22 mt-5'>
            <div class="container">
            <h5>Other Book</h5>
            <div className='row'>

            {
                                    cards.map((i, index) => {
                                        return (
        <div class="col-sm-3">
            <div class="card border-0 mb-4">
                <img src="https://gauge.ghostpool.com/wp-content/uploads/photodune-5076048-las-vegas-m-374x244.jpg"
                        alt="" />
                        <div className='p-2'>
                <h4>Proactively redefine flexible</h4>
                <p>Of all delectable islands the Neverland is the snuggest and most compact, not large and sprawly, you
                    know, with tedious distances between one adventur...</p>
                <p class="othernews_card_a"><a href="#" class="othernews_card_a"><i class="fa fa-user" aria-hidden="true"></i> GHOSTPOOL </a> <span><i class="fa fa-clock-o" aria-hidden="true"></i> AUGEST 28,2014</span></p>
                </div>
            </div>
        </div>
                                        )})}
        </div>

</div>
</section> */}

<section className='secVg setABg'>
<div className='container'>
<div className='row'>
<div className='col-sm-6'>
<form class="form">
  <h2>CONTACT US</h2>
  <p type="Name:"><input placeholder="Write your name here.."></input></p>
  <p type="Phone:"><input placeholder="Write your phone here"></input></p>
  <p type="Email:"><input placeholder="Let us know how to contact you back.."></input></p>
  <p type="Country:"><input placeholder="Write your Country name"></input></p>
  <p type="Book Title:"><input placeholder="Write Your Book Title"></input></p>
  <p type="No of Pages :"><input placeholder="No of Pages in a Book"></input></p>
  <p type="Language:"><input placeholder="Write Your Book Language"></input></p>

  <p type="Message:"><input placeholder="What would you like to tell us.."></input></p>

  <button>Send Message</button>

</form>
</div>
</div>
</div>
</section>
{/* <section class=" set-book-section2 setABg" >
  <div class="container">
    <div class="row">
      <div class="des">
        <h3><span className='text-danger'>F</span>ill Details</h3>

      </div>
      <div class="col-xl-6">


  <form class="review">
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Phone</label>
      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Name</label>
      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Email</label>
      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Message</label>
      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
    </div>
    <div class="mb-3 form-check">
      <input type="checkbox" class="form-check-input" id="exampleCheck1" />
      <label class="form-check-label" for="exampleCheck1">check in</label>
    </div>
    <div class="btn-set">
      <a  type="submit " class="text-dark" href="">Submit</a>

    </div>

  </form>
</div>
<div className='col-xl-5'>
<div>
    <img src={require("../assets/images/2152177.jpg")} className='img-fluid' />
</div>

</div>
</div>
</div>


</section> */}











        </>
    )
}
export default ReviewPage;
