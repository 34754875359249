import React, { useEffect, useState } from 'react'
import "../assets/css/Authore.css"
import { Link, useNavigate } from 'react-router-dom'
import config from '../config/Config';
import NewFollow from '../Component/Follow';



export default function Authorepage() {
    const [cards, setauthore] = useState(null)

    useEffect(() => {
        // Detailsbook()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        AllAuthore()

    }, []

    )
    const AllAuthore = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(config.api_base_url + "/api/v1/list/author", requestOptions)
            .then((response) => {
                if (response.code != 200) {
                    //   alert("Please Fill Valid Details")


                }

                return response.json();
            })

            .then(async (responseData) => {
                console.log("RESULTS all All  details", responseData.data)
                await setauthore(responseData.data.authors)




                // nav("/");




                // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









            })
            .catch(error => console.log('error', error));
    }
    const navigate = useNavigate();

    const AuthoreDetails = async (item) => {
        const { userId, authorDetails } = item;
        console.log(item, "ItemGet")
        //    const urlFriendlyAuthorName = encodeURIComponent(item.authorDetails.lastName).replace(/%20/g, '-');
        const urlFriendlyAuthorName = `${encodeURIComponent(item.authorDetails.firstName).replace(/%20/g, '-')}-${encodeURIComponent(item.authorDetails.lastName).replace(/%20/g, '-')}`;

        //  navigate(`/authors/${item.authorDetails.firstName}${urlFriendlyAuthorName}`, { state: item });

        const lowercaseAuthorName = urlFriendlyAuthorName.toLowerCase();
        // const url = `/authors/${encodeURIComponent(authorDetails.firstName)}-${encodeURIComponent(authorDetails.lastName)}-${userId}`;
        console.log(urlFriendlyAuthorName, "urlFriendlyAuthorName")

        // Use the `navigate` function to redirect to the lowercase URL
        let dynamicUserName = `${lowercaseAuthorName}-${userId}`
        navigate(`/authors/${dynamicUserName}`);
        //  navigate(`/authors/${urlFriendlyAuthorName}`, { state: item });

        // navigate(`/authors/${item.id}`, { state: item});



    }
    return (
        <div>
            {cards !== null ?
                <>

                    <section class="leader_bg">

                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="title_top_writer">

                                        <h1><span style={{ color: "red" }}>A</span>uthor's Leaderboard</h1>

                                        <h3>Explore more about your favorite authors and their other amazing articles & blogs on the site. Follow them for new Updates & Blogs by them.</h3>

                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="image_top_writer">
                                        <img src={require("../assets/images/leader-board-image.png")} alt="" />



                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    <section class="top_five">
                        <div class="container">
                            <div class="row">

                                <div class="col-12">
                                    <div class="top_five_title text-center">
                                        <h2><span style={{ color: "red" }}>O</span>ur Top Five Authors</h2>
                                    </div>
                                </div>
                                {
                                    cards.map((i, index) => {
                                        return (
                                            <>
                                                {
                                                    index <= 4 ? <div class="col-md-4 col-lg-2 col-sm-12 m-auto">
                                                        <div class="five_leaders">
                                                            {/* <img src={require("../assets/images/smile-it-solutions-author-profile.jpg")} alt="" /> */}
                                                            <img className='cursor-pointer'  id="image" src={i.authorDetails.profileImage != null && i.authorDetails.profileImage != "" ? config.api_base_url + `/api/v1/images?fileName=${i.authorDetails.profileImage}` :`https://cdn-icons-png.flaticon.com/512/4791/4791601.png`} alt="" />





                                                            <img class="top_badge" src={require("../assets/images/olive-leaf-logo-png.png")} alt="" />

                                                            <p><span>#{index+1}</span></p>

                                                            <h3 className='cursor-pointer'  onClick={() => AuthoreDetails(i)}>{i.authorDetails.firstName} {i.authorDetails.lastName}</h3>
                                                        </div>
                                                    </div> : null
                                                }

                                            </>


                                        )

                                    }

                                    )
                                }

                                {/* <div class="col-md-4 col-lg-2 col-sm-12 m-auto">
            <div class="five_leaders">
                                    <img src={require("../assets/images/smile-it-solutions-author-profile.jpg")} alt="" />




                <img class="top_badge" src={require("../assets/images/olive-leaf-logo-png.png")} alt="" />

                <p><span>#1</span></p>

                <h3>Laurem Lipsame</h3>
            </div>
        </div>
           <div class="col-md-4 col-lg-2 col-sm-12 m-auto">
            <div class="five_leaders">
                                    <img src={require("../assets/images/smile-it-solutions-author-profile.jpg")} alt="" />




                <img class="top_badge" src={require("../assets/images/olive-leaf-logo-png.png")} alt="" />

                <p><span>#1</span></p>

                <h3>Laurem Lipsame</h3>
            </div>
        </div>
          <div class="col-md-4 col-lg-2 col-sm-12 m-auto">
            <div class="five_leaders">
                                    <img src={require("../assets/images/smile-it-solutions-author-profile.jpg")} alt="" />




                <img class="top_badge" src={require("../assets/images/olive-leaf-logo-png.png")} alt="" />

                <p><span>#1</span></p>

                <h3>Laurem Lipsame</h3>
            </div>
        </div>
            <div class="col-md-4 col-lg-2 col-sm-12 m-auto">
            <div class="five_leaders">
                                    <img src={require("../assets/images/smile-it-solutions-author-profile.jpg")} alt="" />




                <img class="top_badge" src={require("../assets/images/olive-leaf-logo-png.png")} alt="" />

                <p><span>#1</span></p>

                <h3>Laurem Lipsame</h3>
            </div>
        </div>
           <div class="col-md-4 col-lg-2 col-sm-12 m-auto">
            <div class="five_leaders">
                                    <img src={require("../assets/images/smile-it-solutions-author-profile.jpg")} alt="" />




                <img class="top_badge" src={require("../assets/images/olive-leaf-logo-png.png")} alt="" />

                <p><span>#1</span></p>

                <h3>Laurem Lipsame</h3>
            </div>
        </div> */}

                            </div>
                        </div>

                    </section>





                    <section class="leaderboard">





                        <div class="container">




                            {/* <!--<div class="leader-title text-center pb-4">-->
<!--   <h1>Top Writers</h1>-->
<!--</div>--> */}
                            <div class=" col-12 text-center pb-4"><h2 class="top_title_writers"><span style={{ color: "red" }}>O</span>ur Top Authors</h2></div>

                            <div class="row justify-content-center">

                                {
                                    cards.map((i, index) => {
                                        return (
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <div class="leader-details "  >
                                                    {/* <Link to='/SingleAuthore' target="_blank" > */}

                                                    <div class="position_no">
                                                        <span>#{index+1}</span>
                                                    </div>
                                                    <img id="image" onClick={() => AuthoreDetails(i)} className='cursor-pointer' src={i.authorDetails.profileImage != null && i.authorDetails.profileImage != "" ? config.api_base_url + `/api/v1/images?fileName=${i.authorDetails.profileImage}` : `https://cdn-icons-png.flaticon.com/512/4791/4791601.png`} alt="" />
                                                    <h3 onClick={() => AuthoreDetails(i)}  className='cursor-pointer'>{i.authorDetails.firstName} {i.authorDetails.lastName}</h3>
                                                    {/* <p>Administrator</p> */}
                                                    <div class="row pt-3 align-items-center">
                                                        <div class="col-4">
                                                            <div class="total_articles articlee_view text-center">
                                                                <img src={require("../assets/images/1.png")} alt="" />
                                                                <h3>Followers</h3>
                                                                <span id="follow_count1">{i.followerCount}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="total_articles articlee_view text-center">
                                                                <img src={require("../assets/images/2.png")} alt="" />
                                                                <h3> Books</h3>
                                                                <span>{i.bookCount ? i.bookCount : 0}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="total_views articlee_view text-center">
                                                                <img src={require("../assets/images/3.png")} alt="" />
                                                                <h3>Visitors</h3>
                                                                <span>{i.profileVisitCount}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* </Link> */}

                                                    <hr />


                                                    <NewFollow followingId={i.authorDetails.userId} />

                                                    {/* <button><a href="login?page=top-content-writers">Follow</a></button> */}
                                                    <button onClick={() => AuthoreDetails(i)}> Know Me </button>
                                                </div>
                                            </div>
                                        )

                                    }

                                    )
                                }


                            </div>
                        </div>
                    </section></> : <div className="loader-overlay">
                    {/* You can style this loader overlay as needed */}
                    <div className="loader-container">
                        <img src={require("../assets/images/Loader.gif")} alt="Loading" className="loader" />
                    </div>
                </div>
            }



        </div>
    )
}
