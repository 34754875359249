import React, { useState, useMemo, useEffect } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import "../assets/css/Authore.css"
import "../assets/css/SingleAuthore.css"
import isoLangs from 'iso-639-1';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import config from '../config/Config';
import QuickViewModal from '../Component/QuickView'
import draftToHtml from 'draftjs-to-html';
import AddToWishlist from '../Component/AddToWishlist';
import NewFollow from '../Component/Follow';




const languageOptions = isoLangs.getLanguages(isoLangs.getAllCodes()).map(lang => ({
  value: lang.code,
  label: lang.name,
}));


export default function SinglePublisher() {
  const [isOpen, setIsOpen] = useState(false);

  const [visibleCount, setVisibleCount] = useState(9);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { dynamicUserName } = useParams();
  const [userBookCount, setUserBookCount] = useState('0');

  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [followerCount , setfollowerCount] = useState('0')
   const [profileVisitCount, setprofileVisitCount] = useState('0')



  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && event.target.classList.contains('modal')) {
        closeModal();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);
  const fetchCertificates = async () => {
    try {
      const response = await fetch(`${config.api_base_url}/api/v1/user/getCertificates?userId=${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setCertificates(data.data.certificates);
    } catch (error) {
      console.error('Error fetching certificates:', error);
    }
  };

  const openModal = () => {
    setIsOpen(true);
    fetchCertificates()
    // fetchData(); // Call API when modal opens
  };

  const closeModal = () => {
    setIsOpen(false);
  }


  // Now you can use username and userId in your component logic
  //  console.log('Username==============>>>>>>>>>>>.:', username);
  console.log('User ID:', dynamicUserName);
  //  const [userId,setUserId]=useState("")
  const str = dynamicUserName;
  const elements = str.split("-");
  const userId = elements[elements.length - 1];

  const [value, setValue] = useState('Select country')
  const options = useMemo(() => countryList().getData(), [])
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
  };
  // const languageOptions = [
  //   { value: 'en', label: 'English' },
  //   { value: 'fr', label: 'French' },
  //   // Add more languages as needed
  // ];

  const handleChange = (event) => {
    const selectedOption = languageOptions.find(option => option.value === event.target.value);
    setSelectedLanguage(selectedOption);
  };

  const changeHandler = value => {
    setValue(value)
  }

  const [cards, setauthore] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [dataPublisher, setdataPublisher] = useState()
  // const truncatedContent = dataPublisher.aboutUser?.split(' ').slice(0, 30).join(' ');


  useEffect(() => {
    if (!userId) {
      // Handle the case when userId is not available, you can redirect or show an error message
      console.error('User ID is not available');
      // Example: Redirect to a default page
      navigate('/');
    } else {
      AuthorAlldetails();
    }
  }, [userId, navigate]);
  useEffect(() => {
    // Detailsbook()
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });


  }, []

  )

  const AuthorAlldetails = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(config.api_base_url + `/api/v1/user/details/${userId}`, requestOptions)
      .then((response) => {
        if (response.status != 200) {
          alert("Please Fill Valid Details")


        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("RESULTS HEREforData: for", responseData.data)

        setauthore(responseData.data.books)
        setUserBookCount(responseData.data.userBookCount)

        await setdataPublisher(responseData.data.userData)
        await setfollowerCount(responseData.data.followerCount)
        
        await setprofileVisitCount(responseData.data.profileVisitCount)

        //  setdata(responseData.data)
        // selectLa(responseData.data.bookLanguages)





        // nav("/");




        // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









      })
      .catch(error => console.log('error', error));
  }
  console.log(dataPublisher, "dataPublisher")
  const [showFullText, setShowFullText] = useState(false);
  const Detailsset = (item) => {
    if(!item.metaTitle || item.metaTitle === "") {
      navigate(`/books/details/${item._id}`, { state: item });
    } else {
      navigate(`/books/details/${item.metaTitle}`, { state: item });
    }
  }
  const handleCardClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  // const truncateText = (text, wordCount) => {
  //    const words = text.split(' ');

  //    if (words.length > wordCount) {
  //      const truncatedWords = words.slice(0, wordCount);
  //      return `${truncatedWords.join(' ')} ...`;
  //    } else {
  //      return text;
  //    }
  //  };


  const AuthoreDetails = async (item) => {
    const { id, firstName } = item;
    console.log(item, "ItemGet")
    const urlFriendlyAuthorName = encodeURIComponent(item.authorDetails.lastName).replace(/%20/g, '-');

    navigate(`/authors/${item.authorDetails.firstName}${urlFriendlyAuthorName}`, { state: item });

    // navigate(`/authors/${item.id}`, { state: item});



  }

  
  const truncateText = (text, wordCount) => {
    const words = text.split(' ');

    if (words.length > wordCount) {
      const truncatedWords = words.slice(0, wordCount);
      return `${truncatedWords.join(' ')} ...`;
    } else {
      return text;
    }
  };

  const renderText = () => {
    const htmlContent = draftToHtml(JSON.parse(dataPublisher.aboutUser));
    if (showFullText) {
      return <span dangerouslySetInnerHTML={{ __html: htmlContent }}></span>;
    } else {
      const truncatedContent = htmlContent.split(' ').slice(0, 30).join(' ');
      return <span dangerouslySetInnerHTML={{ __html: truncatedContent }}></span>;
    }
  };

  const showMoreItems = () => {
    setVisibleCount(cards.length); // Show all items when "Show More" is clicked
  };

  return (
    <div>
      {cards !== null && dataPublisher ? <section className="leader">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="leader-pro">
                <div className="row">
                  <div className="col-md-4">
                    <div className="per-profile">
                      <div className="rank_no">
                        <span>#1</span>
                      </div>


                      {/* <!--<img src="images/shubham-smile-it.png" alt="">--> */}

                      <img src={config.api_base_url + `/api/v1/images?fileName=${dataPublisher.profileImage}`} alt="" />



                      <div className="follow_btn">
                        <NewFollow followerCount={followerCount} AuthorAlldetails={AuthorAlldetails} followingId={userId} />
                      </div>

                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="per-details">
                      <h2>{dataPublisher.publicationName} </h2>
                      {/* <span><p>Administrator</p></span> */}
                      {/* <p className='heightOfuser'>{dataPublisher.aboutUser}</p> */}
                      {/* <p className=''>

                      {showFullText ? dataPublisher.aboutUser : truncateText(dataPublisher.aboutUser, 34)}
                    </p>
                    <div className='d-flex justify-content-end'>
                    {dataPublisher.aboutUser.length > 30 && (

                      <a className='AddRead' onClick={() => setShowFullText(!showFullText)}>
                        {showFullText ? 'Read Less' : 'Read More'}
                      </a>
                    )}
                    </div> */}
                      {/* <p className="">
                      {
                        showFullText
                        ?  ( <span dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(dataPublisher.aboutUser))}}></span> ) 
                        : ( <span dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(dataPublisher.aboutUser))}}></span> )?.split(' ').slice(0, 30).join(' ')}
                    </p>                 
                    <div className="d-flex justify-content-end">
                      {dataPublisher.aboutUser?.length > 30 && (
                        <a className="AddRead" onClick={() => setShowFullText(!showFullText)}>
                          {showFullText ? 'Read Less' : 'Read More'}
                        </a>
                      )}
                    </div> */}
                      <p className="">
                        {renderText()}
                      </p>
                      <div className="d-flex justify-content-start">
                        {dataPublisher.aboutUser?.length > 30 && (
                          <a style={{textDecoration:"underline"}} className="AddRead" onClick={() => setShowFullText(!showFullText)}>
                            {showFullText ? 'Read Less' : 'Read More'}
                          </a>
                        )}
                      </div>

                      {/* <!--<p>tis libero cupiditate placeat assumenda neque!</p>--> */}
                      <div className="row pt-3 align-items-center">

                        <div className="col-4">
                          <div className="total_articles articlee_view text-center">


                            <img src={require("../assets/images/1.png")} alt="" />


                            <h3>Followers: <span id="follow_count">{followerCount}</span></h3>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="total_articles articlee_view text-center">
                            <img src={require("../assets/images/2.png")} alt="" />
                            <h3>Book: <span>{userBookCount}</span></h3>
                          </div>
                        </div>
                       
                        <div className="col-4">
                          <div className="total_views articlee_view text-center">
                            <img src={require("../assets/images/3.png")} alt="" />
                            <h3>Visitor: <span>{profileVisitCount}</span></h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div className="authors_post">
                <div className="row ">
                  <div className="col-md-6 ">
                    <h1>Publisher's Books</h1>
                  </div>
                  <div className="col-md-6 text-center d-flex justify-content-around">
                    <Select className='w-50 me-2 newItemMob mt-4 h-50'  placeholder="Select country" options={options} value={value} onChange={changeHandler} />

                    <Select
        className='w-50 newItemMob mt-4 h-50'
        options={languageOptions}
        value={selectedLanguage}
        onChange={handleLanguageChange}
        placeholder="Select language"
      />
                    {/* <select style={{

                      borderRadius: "4px",
                      border: "1px solid #ddd",
                      height: "47%"

                    }} className=' mt-4 addedSelect' value={selectedLanguage?.value} onChange={handleChange}>
                      <option value="" >Select a language</option>
                      {languageOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select> */}
                  </div>
                  {/* <!-- Post --> */}

                  {/* <!-- Post -->. */}

                  {/* <!-- Post --> */}

                  {/* <!-- Post --> */}

                  {/* <!-- Post --> */}

                  {/* <!-- Post --> */}


                  {/* <!-- Post --> */}

                  {/* <!-- Post -->
				  <!--------------- */}
                  {
                    
                    cards.slice(0, visibleCount).map((item, index) => {
                      return (
                        <>
                       
                        <div className="col-lg-4 col-md-4" key={index}>
                          <div key={index} className="new_arrival_sec">
                            <div className="p-2">
                              <div className="book1">
                                <div className="book_img">
                                  <img onClick={() => Detailsset(item)} src={config.api_base_url + `/api/v1/images?fileName=${item.mainImage}`} alt="" />
                                  {/* <img src={require("../assets/images/book444.webp")} /> */}
                                  {/* <span className='iconHEart'><i className="fa fa-heart-o" aria-hidden="true"></i></span> */}
                                  <AddToWishlist  productId={item} />

                                </div>
                                {/* <div><i className="fa fa-heart" aria-hidden="true"></i></div> */}


                                <div className="price_details">
                                  {/* <div>
                                                <img src={item.icon}  className='iconClass'/>
                                            </div> */}
                                  <div className='d-flex mb-2'>
                                    <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>                                            <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                    <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                    <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                    <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>

                                  </div>
                                  <h4>Rs. {item.listingPrice}</h4>
                                  <h2>Rs. {item.price}</h2>

                                  <a className='text-white' onClick={() => Detailsset(item)} >

                                    Buy Now
                                  </a>
                                </div>

                              </div>
                              <div className="book_details mt-3">
                                <div className="book_name">
                                  <p className='mb-0' onClick={() => Detailsset(item)}>       {truncateText(item.title, 4)}</p>
                                  {item.authorId == "-1" ? <p className='mt-0'  >By: {item.authorDetails.firstName} {item.authorDetails.lastName} </p> :

                                    <p className='mt-0' onClick={() => AuthoreDetails(item)}>By: {item.authorDetails.firstName} {item.authorDetails.lastName} </p>}


                                </div><div className="book_icon">
                                  {/* <span><i className="fa fa-heart" aria-hidden="true"></i></span> */}
                                  <span><i className="fa fa-book" aria-hidden="true"></i></span>
                                  {
                                    item.audioBook == "0" ? <span><i className="fa fa-microphone-slash" aria-hidden="true"></i></span>

                                      :
                                      <span><i className="fa fa-microphone" aria-hidden="true"></i></span>}
                                  <span onClick={() => handleCardClick(item)}><i className="fa fa-eye" aria-hidden="true"></i></span>
                                  <span><i className="fa fa-shopping-cart" aria-hidden="true"></i></span>
                                  <span><i className="fa fa-share-alt" aria-hidden="true"></i></span>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        
                        </>

                      )
                    }

                    )
                  }

                  {visibleCount < cards.length && (
                    <div className='d-flex justify-content-center ViewButton mt-3'>
                                    <Link  onClick={showMoreItems} className='fw-bold text-dark'>View More</Link>
                                </div>
      )}

                  <QuickViewModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    item={selectedItem}
                  />


                  {/* <!-- Post -- */}

                  {/* <!-- Post --
                  <div className="col-lg-6 col-md-6">
                     <div className="single-blog">
                        <a href="#">
                           <div className="blog-cover">
                              <img src="images/blog/top-recommended-programming-languages-for-mobile-app-development-in-2021.png" alt="Top Recommended Programming Languages For Mobile App Development In 2021">
                           </div>
                        </a>
                        <div className="blog-details">
                           <div className="blog-info">
                              <span className="categories">
                              technical                           </span>
                              <h3>
                                 <a href="#">
                                 Top Recommended Programming Languages For Mobile A...                              </a>
                              </h3>
                              <a href="#">
                                 <p>Take a pause and think about how many times you use different apps in ...</p>
                              </a>

                              <div className="row">
                                  <div className="col-6 text-left">
                                     <div className="like_dis_count">
                                        <i className="fa fa-thumbs-up"></i> <span>372</span>
                                        <i className="fa fa-thumbs-down"></i> <span>122</span>
                                     </div>
                                  </div>
                                  <div className="col-6 text-right">
                                     <div className="like_dis_count">
                                        <p><i className="fa fa-eye" aria-hidden="true"></i> <span>3422</span></p>
                                     </div>
                                  </div>
                            </div>

                           </div>
                        </div>
                     </div>
                  </div>---->
                  <!-- Post --> */}


                </div>
              </div>

            </div>






            <div className="col-lg-4">
              <div className="leader-pro_service">
                <h3>My Awards &amp; Achievements</h3>

                <div id="carouselExampleControls" className="carousel slide mt-3" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item awards active">
                      {
                        (dataPublisher.certificates && dataPublisher.certificates !== null) ? (<img className="d-block w-100" src={`${config.api_base_url}/api/v1/images?fileName=${JSON.parse(dataPublisher.certificates)[0]}`} alt={`Certificate 0`} width="100%" />) : (<img src={require("../assets/images/award.png")} className="d-block w-100" alt="..." />)
                      }
                      {/* <img src={require("../assets/images/award.png")} className="d-block w-100" alt="..." /> */}
                      {/* <div className="award_title">

                        <h5>Upload your Awards &amp; Achievements here.</h5>

                      </div> */}
                      <div className="award_title">

                        <a><h5 onClick={openModal}  >Show All Awards &amp; Achievements here.</h5></a>
                        <div>
                          {/* <button className="btn btn-primary" onClick={openModal}>Open Modal</button> */}
                          <div className={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                            <div className="modal-dialog modal-xl" role="document">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">Achievements And Certificate</h5>
                                  {/* <button type="button" className="close" onClick={closeModal} aria-label="Close">
                                                    
                                                   </button> */}
                                  <span aria-hidden="true" onClick={closeModal}>&times;</span>
                                </div>
                                <div className="modal-body">
                                  {/* Display API data */}
                                  <div className="row ">
                                    {/* {imageUrls.map((url, index) => (
                                                         <div className="col-md-4" key={index}>
                                                            <img src={url} className="img-fluid" alt={`Image ${index + 1}`} />
                                                         </div>
                                                      ))} */}
                                    {certificates.length === 0 ? (
                                      <p>Loading certificates...</p>
                                    ) : (
                                      certificates.map((certificate, index) => (
                                        <div className='col-md-4 col-6 my-1' key={index}>
                                          <img className='border border-dark' src={`${config.api_base_url}/api/v1/images?fileName=${certificate}`} alt={`Certificate ${index}`} width="100%" />
                                        </div>
                                      ))
                                    )}
                                  </div>
                                </div>
                                {/* <div className="modal-footer">
                                                   <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                                                </div> */}
                              </div>
                            </div>
                          </div>
                          {isOpen && <div className="modal-backdrop fade show"></div>}
                        </div>
                      </div>
                    </div>
                    {/* <!---
					<div className="carousel-item awards">
					 <img src="images/cert-2.jpg" className="d-block w-100" alt="...">

					  <div className="award_title">
						  <h5>Received for XYZ Achievement from XYZ institution.</h5>
						</div>
					</div>
					<!---------
					<div className="carousel-item awards">
					  <img src="images/cert-3.jpg" className="d-block w-100" alt="...">
					  <div className="award_title">
						  <h5>Received for XYZ Achievement from XYZ institution.</h5>
						</div>
					</div>
					------> */}
                  </div>


                </div>
              </div>
              <div className="leader-pro_service">
                <h3> Introduction </h3>
                <div className="mt-3">
                  {dataPublisher.videoIntroduction !== null || dataPublisher.videoIntroduction !== " " ?

                    <iframe
                      title={`YouTube Video `}
                      width="100%"
                      height="210px"
                      src={`https://www.youtube.com/embed/${dataPublisher.videoIntroduction}`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                    :
                    <img src={require("../assets/images/video.png")} className="d-block w-100" alt="..." />}

                  {/* <img src={require("../assets/images/video.png")} className="d-block w-100" alt="..." /> */}
                </div>
              </div>

              <div className="leader-pro_service">
                <h3>Connect with me</h3>
                <span>Submit Your Bid</span>
                <div className="bid_form">
                  <form method="POST" action="includes/article-bid" encType="multipart/form-data">
                    <input type="text" name="name" placeholder="Enter Your Name" required="" />
                    <input type="hidden" name="post_author" value="1" />
                    <input type="hidden" name="url" value="admin-1" />
                    <input type="email" name="email" placeholder="Enter Your Email" />
                    <input type="email" name="phone" placeholder="Enter Your Phone" />
                    <input type="email" name="phone" placeholder="Enter Your Country Name" />

                    \
                    <input type="email" name="phone" placeholder="Want Publisher for Country" />
                    <input type="email" name="phone" placeholder=" Enter many copies distribution" />

                    {/* <input type="email" name="phone" placeholder="Enter Your Language" /> */}

                    <input type="number" name="req" placeholder="Message" required="" />
                    <button type="submit" name="submit_form">Submit</button>
                  </form>
                </div>
              </div>


            </div>


          </div>
        </div>
      </section> : <div className="loader-overlay">
        {/* You can style this loader overlay as needed */}
        <div className="loader-container">
          <img src={require("../assets/images/Loader.gif")} alt="Loading" className="loader" />
        </div>
      </div>
      }




    </div>
  )
}
