import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import config from "../config/Config";
// import { config } from "dotenv";


const ManageOrder=()=> {


//   const [data,setdata]=useState([])
  const [detailsTable,setdetailsTable]=useState("35")
  const [details, setDetails] = useState("");
const [data,setData]=useState([])
  const [selectedRows, setSelectedRows] = useState([]);
  const handleChange = useCallback(state => {
    	setSelectedRows(state.selectedRows);
    	}, []);

      // const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
      const columns = [
        {
            name: "#",
            selector: "id",
            sortable: true
          },
        // {
        //   name: "Customer id",
        //   selector: "userId",
        //   sortable: true
        // },
        {
            name: "Customer",
            selector: "userId",
            sortable: true
          },
        {
          name: "Location",
          selector: "addressId",
          sortable: true,
        //   cell: (d) => (
        //     <a href="" target="_blank" className="dlink">
        //       {d.director}
        //     </a>
        //   )
        },
        // {
        //   name: "Genres",
        //   selector: "genres",
        //   sortable: true,
        //   cell: (d) => <span>{d.genres.join(", ")}</span>
        // },
        {
          name: "Order Date",
          selector: "createdAt",
          sortable: true
        },
        {
            name: "Amount",
            selector: "total_amount",
            sortable: true
          },
        {
          name: "Status",
        //   selector: "bookingStatus",
          cell: (row) => {
            // const isPending = row.publishStatus === "0";
            return (
              <>

                  <button
                    style={{ backgroundColor:  row.publishStatus === "0"?"#f7b924": row.publishStatus =="Confirmed"?"Green":"#d1d1d1", marginRight: "5px",color:"black",borderRadius:"4px",border:"none",  fontWeight: "600",
    textTransform:"uppercase",
    padding:" 3px 6px",
    minWidth:" 19px", }}
                    onClick={() => handleClick(row)}
                  >
{row.bookingStatus}
                  </button>


              </>
            );
          },
          sortable: true
        },
        {
          name: "Action",
          sortable: false,
          selector: "null",
          cell: (row) => {
            //   const isPending = row.publishStatus === "0";
              return (
                <>

                  <i
                    key={row.title}
                    style={{
                        marginRight:"12px",
                        fontSize:"17px"
                    }}
                    onClick={() => handleClick(row)}
                    className="fa fa-pencil"
                  ></i>
                  <i
                    onClick={() => handleClick(row)}
                    className="fa fa-trash"
                    style={{
                       color:"red",
                       fontSize:"17px"
                    }}
                  ></i>
                </>
              );
            },
        }
      ];


  const handleClickSet=async(item)=>{
    console.log(item,"item")
  await  setdetailsTable(item.id)
  setDetails(item)

//   GetVehicleDetails()

  }

//   const  GetVehicleData=()=>{
//     var requestOptions = {
//       method: 'GET',
//       redirect: 'follow'
//     };

//     fetch(`http://198.177.123.10:4000/v1/getAllTransaction`, requestOptions)
//     .then((response) => {
//       if(response.status != 200){
//           alert("Please Fill Valid Details")

//       }

//       return response.json();
//   })
//       .then(async (responseData) => {
//           console.log("RESULTS HEREforData: for booking id", responseData)
// setdata(responseData)
// // console.log(data,"Checking Dqat")


//           // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









//         })
//       .catch(error => console.log('error', error));
//   }
  useEffect(()=>{
    GetVehicleDetails()
    // GetVehicleData()
    // GetVehicleDetails()



  },[]

  )
  const tableData = {
    columns,
    data
  };
  const handleClick = async(title) => {

    console.log(`You clicked me! ${JSON.stringify(title.id)}`);
   await setdetailsTable(JSON.stringify(title.id))
//    GetVehicleDetails()


    // GetVehicleDetails()

  };
  // const handleClick = (row) => {
  //   // Assuming you have a function to fetch details based on the ID
  //   // This is just a placeholder, you should replace it with your logic
  //   fetchDetailsById(row.id)
  //     .then((detailsData) => {
  //       setDetails(detailsData);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching details:', error);
  //     });
  // };

//   console.log(data,"Checking Data by User==><<<<<")

// const tableData = {
//     columns,
//     data
//   };

  const  GetVehicleDetails=()=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    // {{newServerUrl}}/api/v1/booking/getSellerOrders/12
    fetch(config.api_base_url+"/api/v1/booking/getSellerOrders/12", requestOptions)
    .then((response) => {
      if(response.status != 200){
          // alert("Please Fill Valid Details")

      }

      return response.json();
  })
      .then(async (responseData) => {
          console.log("RESULTS HEREforData: for user details id", responseData.data)
          setData( responseData.data)

// console.log(data,"Checking Dqat")


          // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









        })
      .catch(error => console.log('error', error));
  }
  return (
    <div className="main-sec">
    <div className="container-fluid shadow">
           <DataTableExtensions {...tableData}>
<DataTable title="Order List" columns={columns} data={data} pagination fixedHeader fixedHeaderScrollHeight="500px"
highlightOnHover

/>
</DataTableExtensions>
</div>





    </div>
  );
}
export default ManageOrder;
