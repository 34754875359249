import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../Component/Header';
import config from '../config/Config';
import QuickViewModal from '../Component/QuickView';
import { Helmet } from 'react-helmet';


export default function Audio() {
    const [cards,setAllCards]=useState([




    ])
    useEffect(()=>{
        Detailsbook()

      },[]

      )
      useEffect(()=>{
        // Detailsbook()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });


      },[]

      )
      const truncateText = (text, wordCount) => {
        const words = text.split(' ');

        if (words.length > wordCount) {
            const truncatedWords = words.slice(0, wordCount);
            return `${truncatedWords.join(' ')} ...`;
        } else {

            return text;
        }
    };
    const [selectedItem, setSelectedItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const handleCardClick = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    };
      const Detailsbook=()=>{
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };

        fetch(config.api_base_url+"/api/v1/books/find", requestOptions)
        .then((response) => {
          if(response.code != 200){
            //   alert("Please Fill Valid Details")


          }

          return response.json();
      })

          .then(async (responseData) => {
              console.log("RESULTS all booking details", responseData)
              setAllCards(responseData.data)



              // nav("/");




              // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









            })
          .catch(error => console.log('error', error));
      }

      const navigate = useNavigate();
      const Detailsset=(item)=>{
        console.log(item,"ItemGet")

        navigate(`/Detailspage`, { state: item});



      }

      const noAudioBooksFound = cards.length > 0 && cards.every((item) => item.audioBook === '0');

  return (
    <div>
     <Helmet>
      <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
      <title>Booksbea | Audiobook Hub for Sellers and Buyers</title>
      <meta name="description" content="Explore, Sell, Listen | Booksbea, the Audiobook Hub for Authors, Publishers, and Buyers. Your stories, now in every ear." />
      <link rel="canonical" href="https://booksbea.com/audio-book" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Booksbea | Audiobook Hub for Sellers and Buyers" />
      <meta property="og:description" content="Explore, Sell, Listen | Booksbea, the Audiobook Hub for Authors, Publishers, and Buyers. Your stories, now in every ear." />
      <meta property="og:url" content="https://booksbea.com/audio-book" />
      <meta property="og:site_name" content="Booksbea" />
      <meta property="og:image" content="" />
      <meta name="google-site-verification" content="372xuYTYARs27axg1pNORzoCFDXQwCJC-SicJZAOTnA" />
    </Helmet>
    <section class="section-2" >
            <div class="container ">
            {/* <Category /> */}

                <div class="row mb-4 mt-4">
                    <div class="col-sm-6">


                    </div>
                    <div class="col-sm-6">

                    </div>

                </div>

                <div class="">
                    <div class="col-xl-9 col-sm-12">
                        <div class="slider-1">
                            {/* <h3 class=""> <span>F</span>ind Your Books</h3> */}
                        </div>
                    </div>
                    <div class="row">

                    {/* <div className='row set_FIlter'>
                            <div className='col-sm-4 d-flex align-items-center'>
                                <div className='position-relative'>
                                    <input type='text' className='serach bar ' placeholder='Search..' />
                                    <a><i class="fa fa-search iconsSe" aria-hidden="true"></i></a>
                                </div>

                            </div>
                            <div class="col-sm-12 col-md-12 col-xl-8 mt-2" style={{ paddingRight: "38px" }}>

                                <div class="d-flex justify-content-end set-flex pt-2">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item pe-2" role="presentation">
                                            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-home" type="button" role="tab"
                                                aria-controls="pills-home" aria-selected="true">Most Popular</button>
                                        </li>
                                        <li class="nav-item pe-2" role="presentation">
                                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-profile" type="button" role="tab"
                                                aria-controls="pills-profile" aria-selected="false">New Arrival</button>
                                        </li>
                                        <li class="nav-item pe-2" role="presentation">
                                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-profile" type="button" role="tab"
                                                aria-controls="pills-profile" aria-selected="false">Best Rated</button>
                                        </li>
                                        <li class="nav-item pe-2" role="presentation">
                                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-profile" type="button" role="tab"
                                                aria-controls="pills-profile" aria-selected="false">Recommended</button>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            </div> */}

                        <div style={{}} class="mt-5 row pro d-flex justify-content-center">

                        {noAudioBooksFound ? (
                            <div className="p-2 AudioBook_">
                                <h3>No audio books found</h3>
                            </div>
                        ) : (
                            cards.map((item, index) => (
                                <>
            {item.audioBook !== "0" && (
            <div class="new_arrival_sec">
                                                    <div className='p-2'>
                                                        <div class="book1">
                                                            <div class="book_img">
                                                                <img src={config.api_base_url + `/api/v1/images?fileName=${item.mainImage}`} alt="" />
                                                                {/* <img src={require("../assets/images/book444.webp")} /> */}
                                                                                                                                <span className='iconHEart'><i class="fa fa-heart-o" aria-hidden="true"></i></span>

                                                            </div>
                                                            {/* <div><i class="fa fa-heart" aria-hidden="true"></i></div> */}


                                                            <div class="price_details">
                                                                {/* <div>
                                                <img src={item.icon}  className='iconClass'/>
                                            </div> */}
                                                                <div className='d-flex mb-2'>
                                                                    <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>                                            <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                                                    <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                                                    <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                                                    <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>

                                                                </div>
                                                                <h4>Rs. {item.listingPrice}</h4>
                                                                <h2>Rs. {item.price}</h2>

                                                                <a className='text-white' onClick={() => Detailsset(item)} >

                                                                    Buy Now
                                                                </a>
                                                            </div>

                                                        </div>



                                                        <div class="book_details mt-3">
                                                            <div class="book_name">
                                                                <p className='mb-0'>       {truncateText(item.title, 4)}</p>
                                                                <p className='mt-0'>
  By: {item.authorDetails ? `${item.authorDetails.firstName} ${item.authorDetails.lastName}` : 'Unknown Author'}
</p>


                                                            </div><div class="book_icon">
                                                                {/* <span><i class="fa fa-heart" aria-hidden="true"></i></span> */}
                                                                <span><i class="fa fa-book" aria-hidden="true"></i></span>
                                                                {
                                                                    item.audioBook == "0" ? <span><i class="fa fa-microphone-slash" aria-hidden="true"></i></span>

                                                                        :
                                                                        <span><i class="fa fa-microphone" aria-hidden="true"></i></span>}

                                                                <span onClick={() => handleCardClick(item)}><i class="fa fa-eye" aria-hidden="true"></i></span>
                                                                <span><i class="fa fa-shopping-cart" aria-hidden="true"></i></span>
                                                                <span><i class="fa fa-share-alt" aria-hidden="true"></i></span>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                )}

            </>
                            ))
                        )}



<QuickViewModal
                show={showModal}
                onHide={() => setShowModal(false)}
                item={selectedItem}
            />










                        </div>


                    </div>


                </div>
            </div>


        </section>



    </div>
  )
}
