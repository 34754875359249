import React, { useEffect, useState } from 'react'
import "../assets/css/style.css"
import "../assets/css/bookcontent.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import config from '../config/Config';
import QuickViewModal from '../Component/QuickView';

const Detailspage = () => {

  const { state } = useLocation();

  const { _id } = useParams();
  // Use the id to fetch data or for other purposes
  // console.log(_id, "idfetch");
  const [userId, setUserId] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [data, setdata] = useState("");
  const [lan, selectLa] = useState([])
  const [imagesset, selectImage] = useState([])
  const [detailsautore, setdetailsautore] = useState("")
  const [listAuthoreBook, setRelatedBook] = useState([])
  const [listCategoryBook, setlistCategoryBook] = useState([])
  const [publisherDetails, setPublisherDetails] = useState(null)

  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);





  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const handleCardClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);

    if (parsedUsername) {
      console.log(parsedUsername);

      setUserId(parsedUsername.userId)


    } else {
      console.log('No username found in local storage');
    }
    // AllAddress()

  }, []);
  const navigate = useNavigate();
  const AddCart = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "productId": data._id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };


    fetch(config.api_base_url + `/api/v1/cart/add/${userId}`, requestOptions)
      .then((response) => {
        if (response.status != 201) {
          alert("Please Fill Valid Details")


        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("RESULTS HEREforData: for", responseData)
        navigate(`/Checkout`, { state: responseData.data });



      })
      .catch(error => console.log('error', error));


  }
  const AddCart12 = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "productId": data._id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };


    fetch(config.api_base_url + `/api/v1/cart/add/${userId}`, requestOptions)
      .then((response) => {
        if (response.status != 201) {
          alert("Please Fill Valid Details")


        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("RESULTS HEREforData: for", responseData)



      })
      .catch(error => console.log('error', error));


  }
  const truncateText = (text, wordCount) => {
    const words = text.split(' ');
    if (words.length > wordCount) {
      const truncatedWords = words.slice(0, wordCount);
      return `${truncatedWords.join(' ')} ...`;
    } else {

      return text;
    }
  };
  // const { id } = useParams();
  useEffect(() => {
    // window.alert(_id)
    if (!_id) {
      // Redirect to /books if id is not provided     
      navigate(`/books`);
      // return null; // Return null to avoid rendering anything in this component
    } else {
      Detailsbook()
    }
  }, []

  )
  // Check if id exists

  const Detailsbook = () => {


    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(config.api_base_url + `/api/v1/books/findbookdetails?id=${_id}`, requestOptions)
      .then((response) => {
        if (response.status != 200) {
          alert("Please Fill Valid Details")


        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("RESULTS HEREforData: for", responseData.data)
        await selectImage(responseData.data.additionalImages)
        setdata(responseData.data)
        setdetailsautore(responseData.authorDetails)
        selectLa(responseData.data.bookLanguages)
        setRelatedBook(responseData.authorBooks)
        setlistCategoryBook(responseData.categoryBooks)
        setPublisherDetails(responseData.publisherDetails)

      })
      .catch(error => console.log('error', error));


  }
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    // autoplaySpeed: 3000,
    focus: false,
    pauseOnHover: true,
    arrow: false,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  var settings4 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    focus: false,
    pauseOnHover: true,
    arrow: false,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]

  };

  // const Detailsset = (item) => {
  //   // console.log(item, "ItemGet")
  //   navigate(`/books/details/${item._id}`, { state: item });
  //   window.location.reload();
  //   // window.scrollTo({
  //   //   top: 0,
  //   //   behavior: 'smooth'
  //   // });

  // }
  const Detailsset = (item) => {
    if (!item.metaTitle || item.metaTitle === "") {
      navigate(`/books/details/${item._id}`, { state: item });
    } else {
      navigate(`/books/details/${item.metaTitle}`, { state: item });
    }
    window.location.reload();
  }

  return (
    <>
      {data ?
        <div>



          <section className="set-book-section ">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 mb-5">
                  <div className="d-flex justify-content-between">
                    <div className="category-set">
                      <a>
                        Home
                      </a>
                      {/* <p>Book ID: {id}</p> */}
                      &nbsp;/&nbsp;
                      <a>Advance</a>

                      {/* <a>Taures</a> */}
                      <a></a>
                    </div>
                    <div className="category-set">
                      <a className="set-author-name">
                        {/* {detailsautore.firstName!=null && detailsautore.firstName!=""? detailsautore.firstName:null} */}
                        {detailsautore && detailsautore.firstName != null && detailsautore.firstName !== "" ? detailsautore.firstName : data.author_name}



                      </a>

                    </div>
                  </div>



                </div>
                <div className="col-sm-12 col-xl-2 col-md-12">
                  <ul className="item-side-book d-grid">



                    {imagesset.map((imageUrl, index) => (
                      <li className="setLi">
                        <img key={index} src={config.api_base_url + `/api/v1/images?fileName=${imageUrl}`} alt={`Image ${index + 1}`} style={{ width: '100px', height: '100px', margin: '5px' }} />
                      </li>
                    ))}
                    {/* <img src={`http://198.177.123.10:4001/api/v1/images?fileName=${i.image}`} className='img-src' width={"50%"} /> */}



                    {/*
                    <li className="mt-4 setLi">
                        <img className="img-src" src={require("../assets/images/book2.webp")} width="50%" />

                    </li>
                    <li className="mt-4 setLi">
                        <img className="img-src" src={require("../assets/images/book3.webp")} width="50%" />

                    </li> */}
                  </ul>




                </div>

                <div className="col-sm-12 col-xl-4 col-md-12">

                  <div className="picSet-f" >
                    {/* <div className="position-absolute bg-white ReedableSEt" id="reedable">
                    <a style={{cursor:"pointer",fontWeight:"bold"}} href="readbook.html">Read a Book</a>
                  </div> */}
                    <img src={config.api_base_url + `/api/v1/images?fileName=${data.mainImage}`} width="100%" className="img-fluid" id="imagechange" style={{ height: "65vh" }} />
                  </div>
                </div>
                <div id="setulImage">
                  <ul className="item-side-book d-flex">

                    <li className="setLi mt-2">
                      <img className="img-src" src={require("../assets/images/book1.webp")} width="50%" />

                    </li>
                    <li className="mt-2 setLi">
                      <img className="img-src" src={require("../assets/images/book2.webp")} width="50%" />

                    </li>
                    <li className="mt-2 setLi">
                      <img className="img-src" src={require("../assets/images/book5.webp")} width="50%" />

                    </li>
                  </ul>
                </div>
                <div className="col-sm-12 col-xl-6 col-md-12">
                  <div className="category-content">
                    <div className="slider-1 mt-5 position-relative">
                      <h3 className=""> <span></span>{data != "" ? data.title : "data.title"}</h3>
                      <p className='Eyeee'><i className="fa fa-eye" aria-hidden="true"></i></p>
                      <span className="" ><h6 className="" style={{ paddingLeft: "44px" }} >4.5 <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i><i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i><i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i><i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i></h6></span>
                    </div>
                    {/* <p className="mt-5">
                    {data.description}
                  </p> */}

                    <p className='mt-5 setDec' dangerouslySetInnerHTML={{ __html: data.shortDescription }} />
                    <div className="woocommerce-product-details__additional-information">
                      {/* <!-- <h2>Additional information</h2> --> */}

                      <table className="woocommerce-product-attributes shop_attributes">
                        <tbody><tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--attribute_pa_publisher ">
                          <th className="woocommerce-product-attributes-item__label">Publisher:</th>
                          <td className=""><a className="fw-bold text-dark">{
                            publisherDetails != null && publisherDetails.publicationName != "" ? publisherDetails.publicationName : data.publisher_name
                          }</a>
                          </td>
                        </tr>
                          <tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--attribute_pa_year-of-publishing">
                            <th className="woocommerce-product-attributes-item__label">Name of Author:</th>
                            <td className="woocommerce-product-attributes-item__value"><a className="fw-bold text-dark"> {detailsautore && detailsautore.firstName != null && detailsautore.firstName !== "" ? detailsautore.firstName : data.author_name}</a>
                            </td>
                          </tr>
                          <tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--attribute_pa_year-of-publishing">
                            <th className="woocommerce-product-attributes-item__label">Year of Publishing:</th>
                            <td className="woocommerce-product-attributes-item__value"><a className="fw-bold text-dark">{data.bookLaunchDate}</a>
                            </td>
                          </tr>
                          <tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--attribute_pa_number-of-pages">
                            <th className="woocommerce-product-attributes-item__label">Number of pages:</th>
                            <td className="woocommerce-product-attributes-item__value"><a className="fw-bold text-dark">{data.pageCount}</a>
                            </td>
                          </tr>
                          <tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--attribute_pa_isbn">
                            <th className="woocommerce-product-attributes-item__label">ISBN:</th>
                            <td className="woocommerce-product-attributes-item__value"><a className="fw-bold text-dark">{data.isbn}</a>
                            </td>
                          </tr>
                          <tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--attribute_pa_isbn">
                            <th className="woocommerce-product-attributes-item__label">Price:</th>
                            <td className="woocommerce-product-attributes-item__value"> <span className='spanPrice' id="ctl00_phBody_ProductDetail_lblListPrice">₹{data.listingPrice}</span> <a className="fw-bold text-danger" style={{ fontSize: "30px" }}>₹{data.price}</a>
                            </td>
                          </tr>

                        </tbody></table>
                    </div>
                    <div className="row mt-4">
                      <div id="ctl00_phBody_ProductDetail_divaction " className='d-flex'>
                        <div id="ctl00_phBody_ProductDetail_divAddtoCart" style={{ width: "250px", maxWidth: "48%", marginRight: "10px" }}>
                          <Link onClick={() => AddCart()}>
                            <input type="button" className="btn themebackground text-white " value="Buy Now" />
                          </Link>


                        </div>
                        <input type="button" className="btn  border themeborder themecolor bg-white" style={{ width: "250px", maxWidth: "48%" }} value="Add to Cart" onClick={() => AddCart12()} />

                      </div>

                      <div id="setOnlinesection" className="row mt-4" >
                        <div className="col-sm-6">
                          <div className="set-btn  d-flex justify-content-center">
                            <a id="buy" style={{ background: "white" }}>
                              Offline
                            </a>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="set-btn  d-flex justify-content-center">
                            <a className="border-0" style={{ background: "white" }} id="rent">
                              Online
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="row" id="detailsId">
                        <div className="col-sm-4">

                          <div>
                            <table className="variations" cellSpacing="0">
                              <tbody>
                                <tr>
                                  <td className="label"><label htmlFor="book-type">Book Type:</label></td>

                                </tr>
                              </tbody>
                            </table>

                          </div>

                        </div>
                        <div className="col-sm-6">
                          <td className="value" >
                            <select id="book-type" className="border-0" name="attribute_book-type" data-attribute_name="attribute_book-type" data-show_option_none="yes">
                              <option value="">Choose an option</option><option value="1" className="attached enabled">E-Book</option>
                              <option value="2" className="attached enabled">Book</option>
                            </select>
                            {/* <!-- <a className="reset_variations" href="#" style="visibility: visible;">Clear</a>		 --> */}
                          </td>

                        </div>
                        <div className="col-sm-2">

                        </div>
                      </div>
                      <div className="row" id="rentdetail">
                        <div className="col-sm-4">

                          <div>
                            <table className="variations" cellSpacing="0">
                              <tbody>
                                <tr>
                                  <td className="label"><label htmlFor="book-type">Book Type:</label></td>

                                </tr>
                              </tbody>
                            </table>

                          </div>

                        </div>
                        <div className="col-sm-6">
                          <td className="value" >
                            <select id="book-type" className="border-0" name="attribute_book-type" data-attribute_name="attribute_book-type" data-show_option_none="yes">
                              <option value="">Choose an option</option>
                              <option value="1" className="attached enabled">E-Book</option>
                            </select>
                            {/* <!-- <a className="reset_variations" href="#" style="visibility: visible;">Clear</a>		 --> */}
                          </td>

                        </div>
                        <div className="col-sm-2">

                        </div>
                      </div>

                      <div className="row mt-4 " id="priceSEtSECTION" >
                        <div className="col-sm-4">
                          <div className="set-type-button">
                            <h6>Price:</h6>
                          </div>
                        </div>
                        <div className="col-sm-6">

                          <div className="price-set">
                            <h6 className="text-ce
                            " ><i className="fa fa-inr" aria-hidden="true"></i> <span id="price">20.00</span></h6>

                          </div>

                        </div>

                      </div>
                      <div id="postalSection" className="row mt-4" >
                        <div className=" col-xl-6 col-md-6  col-sm-12">
                          <div>
                            <input onChange="setValidPin" type="number" className="p-2 border-0" id="checkPostal" style={{ outline: "none" }} name="fname" placeholder="Enter Postal Code" />

                          </div>
                          <a id="setError" className="text-danger fs-6">No extra charges</a>


                        </div>
                        <div className=" col-xl-6 col-md-6  col-sm-12">
                          <div className="set-btn mt-1 margineSubmint" id="" >
                            <a style={{ color: "white" }}>Submit</a>
                          </div>


                        </div>

                      </div>


                      <div className="row mt-4 " id="setRO_IMG">
                        <div className="col-sm-4">
                          <div>

                            <span className="set-qty">Qty:</span><input type="number" id="quantity" name="quantity" min="1" max="5" value="1" className="setpadding_input " />
                          </div>

                        </div>
                        <div className="col-sm-8 d-flex">
                          <div className="mt-3 pe-3">
                            <a className="buyBtn">

                              <span><i className="fa fa-shopping-cart me-2" aria-hidden="true"></i></span>Buy Now
                            </a>

                          </div>
                          <div className="div-icon ">
                            <a>
                              <i className="fa fa-heart-o" aria-hidden="true"></i>
                            </a>
                          </div>

                        </div>
                        <div className="col-sm-">


                        </div>

                      </div>


                    </div>



                  </div>

                </div>


              </div>

            </div>





          </section>


          <section className='tabsFor set-book-section1 mt-0'>
            <div className='container '>
              <div className='mt-2 MenuItem border-bottom border-2'>
                <button onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'active' : ''}>
                  Details
                </button>
                <button onClick={() => handleTabClick(2)} className={activeTab === 2 ? 'active' : ''}>
                  Description
                </button>
                <button onClick={() => handleTabClick(3)} className={activeTab === 3 ? 'active' : ''}>
                  Reviews
                </button>
              </div>



              <div className=''>
                {activeTab === 1 &&
                  <div className='bg-white p-3'>
                    <div className="row" id="bookdetail">
                      <div className="col-sm-12"><div className="des"><h3>Product Details</h3></div></div>
                      <div className="col-sm-6">
                        <ul className="list-unstyled detailfont14 border-right">

                          <li><span className="fw-bold">Publisher: </span>{
                            publisherDetails != null && publisherDetails.publicationName != "" ? publisherDetails.publicationName : data.publisher_name
                          }</li>
                          {/* <li><span className="fw-bold">Publisher Imprint: </span>Viz Media</li> */}
                          <li><span className="fw-bold">Sku </span>{data.sku}</li>
                          <li><span className="fw-bold">Language: </span> {lan.map((i, index) => {
                            return (
                              <span key={index}>
                                {i.replace(/<[^>]*>/g, '')}
                              </span>
                            )
                          }

                          )}  </li>
                          {/* <li><span className="fw-bold">Returnable: </span>Y</li> */}
                          <li><span className="fw-bold">Dimensions : </span>{data.dimensions}</li>
                          <li><span className="fw-bold">Weight: </span>{data.dimensions}</li>
                          {/* <li><span className="fw-bold">Width: </span>147 mm</li> */}
                          <li><span className="fw-bold">Binding : </span> {data.bookCoverType.map((i, index) => {
                            return (
                              <span key={index}>
                                {i.replace(/<[^>]*>/g, '')}
                              </span>
                            )
                          }

                          )}  </li>
                        </ul>
                      </div>
                      <div className="col-sm-6">
                        <ul className="list-unstyled detailfont14">
                          <li><span className="fw-bold">ISBN-10: </span>{data.isbn}</li>
                          <li><span className="fw-bold">Publisher Date: </span>{data.bookLaunchDate}</li>
                          <li><span className="fw-bold">No of Pages: </span>{data.pageCount}</li>
                          <li><span className="fw-bold">Series Title: </span>{data.title}</li>
                          <li><span className="fw-bold">Book Edition: </span>{data.edition}</li>

                        </ul>
                      </div>
                    </div>
                  </div>
                }
                {activeTab === 2 && <div className='bg-white p-3'><div className="container"><div className="row"><div className="col-xl-12"></div><div className="col-xl-12">    <p className='bg-white' dangerouslySetInnerHTML={{ __html: data.description }} /></div></div></div></div>}
                {activeTab === 3 && <div className='bg-white p-3'>
                  <section className="p-2">
                    <div className="container">
                      <div className="row">
                        <div className="des">
                          <h3>Product Review</h3>

                        </div>
                        <div className="cmt_person">
                          <div className="profile_box">
                            <div className="profile_pic mb-4">
                              <img src="https://w7.pngwing.com/pngs/695/655/png-transparent-head-the-dummy-avatar-man-tie-jacket-user.png" alt="" />
                            </div>
                            <div className="profile_name">
                              <h3>Lorem Lipsame </h3>
                              <p className="text-danger">3 days ago</p>

                            </div>
                          </div>

                          <div className="msg_reply">
                            <div className="here_comt">
                              <p>I made that account so I could get free coins by liking some game company.</p>
                            </div>
                            <div className="cmt_views">
                              <ul>
                                <li style={{ borderRight: "1px solid #ddd" }}><i className="fa fa-thumbs-up" style={{ color: "#fabc0bf2" }}></i> 210</li>
                                <li><i className="fa fa-thumbs-down " style={{ color: "red" }}></i> 2</li>
                              </ul>
                              <ul>
                                <li className="img_viewer"> <img src="https://wallpapers.com/images/featured/87h46gcobjl5e4xu.jpg" alt="" /></li>
                                <li><i className="fa fa-ellipsis-v"></i></li>
                              </ul>

                            </div>
                          </div>
                        </div>
                        <div className="cmt_person">
                          <div className="profile_box">
                            <div className="profile_pic mb-4">
                              <img src="https://w7.pngwing.com/pngs/695/655/png-transparent-head-the-dummy-avatar-man-tie-jacket-user.png" alt="" />
                            </div>
                            <div className="profile_name">
                              <h3>Lorem Lipsame </h3>
                              <p className="text-danger">3 days ago</p>

                            </div>
                          </div>

                          <div className="msg_reply">
                            <div className="here_comt">
                              <p>I made that account so I could get free coins by liking some game company.</p>
                            </div>
                            <div className="cmt_views">
                              <ul>
                                <li style={{ borderRight: "1px solid #ddd" }}><i className="fa fa-thumbs-up" style={{ color: "#fabc0bf2" }}></i> 210</li>
                                <li><i className="fa fa-thumbs-down " style={{ color: "red" }}></i> 2</li>
                              </ul>
                              <ul>
                                <li className="img_viewer"> <img src="https://w7.pngwing.com/pngs/695/655/png-transparent-head-the-dummy-avatar-man-tie-jacket-user.png" alt="" /></li>
                                <li><i className="fa fa-ellipsis-v"></i></li>
                              </ul>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </section>
                  <section className=" set-book-section2">
                    <div className="container">
                      <div className="row">
                        <div className="des">
                          <h3>Add a review</h3>

                        </div>
                        <div className="col-xl-12">

                          <div className="comment-form-rating"><label htmlFor="rating" className="pe-3">Your rating</label><p className="stars">						<span>							<a className="star-1" href="#">1</a>							<a className="star-2" href="#">2</a>							<a className="star-3" href="#">3</a>							<a className="star-4" href="#">4</a>							<a className="star-5" href="#">5</a>						</span>					</p><select name="rating" id="rating" aria-required="true" required="" style={{ display: "none" }}>
                            <option value="">Rate…</option>
                            <option value="5">Perfect</option>
                            <option value="4">Good</option>
                            <option value="3">Average</option>
                            <option value="2">Not that bad</option>
                            <option value="1">Very poor</option>
                          </select></div>
                          <form className="review">
                            <div className="mb-3">
                              <label htmlFor="exampleInputEmail1" className="form-label">YOUR REVIEW *</label>
                              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="exampleInputPassword1" className="form-label">Name</label>
                              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="exampleInputPassword1" className="form-label">Email</label>
                              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3 form-check">
                              <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                              <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                            </div>
                            <div className="btn-set">
                              <a type="submit " className="text-dark" href="">Submit</a>

                            </div>

                          </form>
                        </div>
                      </div>
                    </div>


                  </section>

                </div>}
              </div>
            </div>





          </section>



          {/* <!-- releted product --> */}


          <section className="set-book-section2">
            <div className='container'>
              <div className="des d-flex justify-content-between">
                <h3>More Book From the Author</h3>
                <div className="d-flex justify-content-end">
                  <Link to="/author">
                    <a href="" className="Newss" >See all <span> <i className="fa fa-arrow-right" aria-hidden="true"></i></span></a>
                  </Link>
                </div>

              </div>


              <Slider {...settings2}>
                {
                  listAuthoreBook.map((item, index) => {
                    return (
                      <div className='cardsss_au' key={index}>
                        <div className="new_arrival_sec">
                          <div className='p-2'>
                            <div className="book1">
                              <div className="book_img">
                                <img onClick={() => Detailsset(item)} src={config.api_base_url + `/api/v1/images?fileName=${item.mainImage}`} alt="" />
                                {/* <img src={require("../assets/images/book444.webp")} /> */}
                                <span className='iconHEart'><i className="fa fa-heart-o" aria-hidden="true"></i></span>

                              </div>
                              {/* <div><i className="fa fa-heart" aria-hidden="true"></i></div> */}


                              <div className="price_details">
                                {/* <div>
                                                <img src={item.icon}  className='iconClass'/>
                                            </div> */}
                                <div className='d-flex mb-2'>
                                  <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>                                            <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                  <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                  <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                  <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>

                                </div>
                                <h4>Rs. {item.listingPrice}</h4>
                                <h2>Rs. {item.price}</h2>

                                <a className='text-white' onClick={() => Detailsset(item)} >

                                  Buy Now
                                </a>
                              </div>

                            </div>



                            <div className="book_details mt-3">
                              <div className="book_name">
                                <p className='mb-0' onClick={() => Detailsset(item)}>       {truncateText(item.title, 4)}</p>
                                {/* Check if authorDetails is defined before accessing its properties */}
                                <p className='mt-0'>By: {item.authorDetails ? `${item.authorDetails.firstName} ${item.authorDetails.lastName}` : 'Unknown Author'}</p>


                              </div><div className="book_icon">
                                {/* <span><i className="fa fa-heart" aria-hidden="true"></i></span> */}
                                <span><i className="fa fa-book" aria-hidden="true"></i></span>
                                {
                                  item.audioBook == "0" ? <span><i className="fa fa-microphone-slash" aria-hidden="true"></i></span>

                                    :
                                    <span><i className="fa fa-microphone" aria-hidden="true"></i></span>}

                                <span onClick={() => handleCardClick(item)}><i className="fa fa-eye" aria-hidden="true"></i></span>
                                <span><i className="fa fa-shopping-cart" aria-hidden="true"></i></span>
                                <span><i className="fa fa-share-alt" aria-hidden="true"></i></span>

                              </div>

                            </div>
                          </div>

                        </div>

                      </div>

                    )
                  }

                  )
                }

                <QuickViewModal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  item={selectedItem}
                />


              </Slider>
            </div>

          </section>


          <section className="set-book-section2">
            <div className='container'>
              <div className="des d-flex justify-content-between">
                <h3>More Book From Same Category</h3>
                <div className="d-flex justify-content-end">
                  <Link to="/PublisherPage">
                    <a href="" className="Newss">See all <span> <i className="fa fa-arrow-right" aria-hidden="true"></i></span></a>
                  </Link>
                </div>

              </div>


              <Slider {...settings4}>
                {
                  listCategoryBook.map((item, index) => {
                    return (
                      <div className='cardsss_au' key={index}>
                        <div className="new_arrival_sec">
                          <div className='p-2'>
                            <div className="book1">
                              <div className="book_img">
                                <img onClick={() => Detailsset(item)} src={config.api_base_url + `/api/v1/images?fileName=${item.mainImage}`} alt="" />
                                {/* <img src={require("../assets/images/book444.webp")} /> */}
                                <span className='iconHEart'><i className="fa fa-heart-o" aria-hidden="true"></i></span>

                              </div>
                              {/* <div><i className="fa fa-heart" aria-hidden="true"></i></div> */}


                              <div className="price_details">
                                {/* <div>
                                                <img src={item.icon}  className='iconClass'/>
                                            </div> */}
                                <div className='d-flex mb-2'>
                                  <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>                                            <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                  <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                  <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                  <i className="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>

                                </div>
                                <h4>Rs. {item.listingPrice}</h4>
                                <h2>Rs. {item.price}</h2>

                                <a className='text-white' onClick={() => Detailsset(item)} >

                                  Buy Now
                                </a>
                              </div>

                            </div>



                            <div className="book_details mt-3">
                              <div className="book_name">
                                <p className='mb-0' onClick={() => Detailsset(item)}>       {truncateText(item.title, 4)}</p>
                                {/* Check if authorDetails is defined before accessing its properties */}
                                <p className='mt-0'>By: {item.authorDetails ? `${item.authorDetails.firstName} ${item.authorDetails.lastName}` : 'Unknown Author'}</p>


                              </div><div className="book_icon">
                                {/* <span><i className="fa fa-heart" aria-hidden="true"></i></span> */}
                                <span><i className="fa fa-book" aria-hidden="true"></i></span>
                                {
                                  item.audioBook == "0" ? <span><i className="fa fa-microphone-slash" aria-hidden="true"></i></span>

                                    :
                                    <span><i className="fa fa-microphone" aria-hidden="true"></i></span>}

                                <span onClick={() => handleCardClick(item)}><i className="fa fa-eye" aria-hidden="true"></i></span>
                                <span><i className="fa fa-shopping-cart" aria-hidden="true"></i></span>
                                <span><i className="fa fa-share-alt" aria-hidden="true"></i></span>

                              </div>

                            </div>
                          </div>

                        </div>

                      </div>

                    )
                  }

                  )
                }
              </Slider>
            </div>
          </section>
        </div> :
        <div className="loader-overlay">
          {/* You can style this loader overlay as needed */}
          <div className="loader-container">
            <img src={require("../assets/images/Loader.gif")} alt="Loading" className="" />
          </div>
        </div>}

    </>
  )
}
export default Detailspage
