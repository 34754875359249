import React, { useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../assets/css/Review.css"
import CircularProgressBar from '../Component/ProgressRating'; // Adjust the path based on your project structure


const SinglePage = () => {
    const [percentage, setPercentage] = useState(0);
    const [activeTab, setActiveTab] = useState(1);
    const [data,setdata]=useState("");
    const [lan,selectLa]=useState([])
    const [imagesset,selectImage]=useState([])
    const [ratings, setRating] = useState(0);

    const handleRatingChange = (newRating) => {
      setRating(newRating);
    };

    useEffect(()=>{
        // Detailsbook()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });


      },[]

      )


    const handleTabClick = (tabNumber) => {
      setActiveTab(tabNumber);
    };

    const [cards, setAllCards] = useState([
        {
            id: 1,
            // icon:require("../assets/images/best.png")
        },
        {
            id: 2,
            icon: require("../assets/images/icon1.png")
        },
        {
            id: 3,
            // icon:require("../assets/images/icon3.png")
        },
        {
            id: 4,
            // icon:require("../assets/images/icon4.png")
        },

        {
            id: 5,
            // icon:require("../assets/images/icon5.png")
        },
        {
            id: 3,
            // icon:require("../assets/images/icon6.png")
        },
        {
            id: 4,
            // icon:require("../assets/images/icon1.png")
        },

        {
            id: 5
        }, {
            id: 4
        },

        {
            id: 5
        },

        {
            id: 5
        }, {
            id: 4
        },






    ])

    useEffect(() => {
        // Example: Set percentage to 50% after 1 second
        const timeout = setTimeout(() => {
            setPercentage(50);
        }, 1000);

        return () => clearTimeout(timeout);
    }, []);

    const rating = 8.5;
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        focus: false,
        pauseOnHover: true,
        arrow: true,

        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>





            <div className='ReviewFirstSlide'>
                <div className='container'>
                <div className='row'>
                <div className='col-sm-10'>
                <div class="gg-single ">
                        <div className='newRate position-relative'>
                            <CircularProgressBar rating={rating} />
                            <div>
                                <h6 className='setText'>Great</h6>
                            </div>
                        </div>
                        <div className='newRate1 position-relative'>
                            {/* <CircularProgressBar rating={rating} /> */}
                            <div>
                                <h3 className='text-white text-center'>7.7</h3>


                                <h6 className='text-white'>User Ave</h6>
                            </div>
                            <div>
                                <h6 className='setText'></h6>
                            </div>
                        </div>
                        {/* <div className='buy_now'>
                        <a>Buy Now</a>

                        </div> */}
                        <div className='d-flex'>
                        <div>
                        <img src={require("../assets/images/callOf.jpg")} />
                        <p className='follow'>Follow</p>
                        </div>
<div className='ms-3'>
                        <h2 class="gp-slide-caption-title">Titan Fall</h2>
                        <div className='ave'>
                        Book of the year

                        </div>
                        <p className='text-white newText'>Release Date:23/11/2023</p>
            </div>
</div>
</div>
                </div>


                </div>
                </div>
                <div className='setTopSHa'></div>
                <div class="gp-bottom-bg-gradient-overlay"></div>

            </div>
            {/* <section className='maintabs'>
            <div className='container'>
            <div className=' MenuItem border-end-0'>
        <button onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'active' : ''}>
          Details
        </button>
        <button onClick={() => handleTabClick(2)} className={activeTab === 2 ? 'active' : ''}>
         Review
        </button>
        <button onClick={() => handleTabClick(3)} className={activeTab === 3 ? 'active' : ''}>

        </button>
      </div>
      </div>
      </section> */}

            <section className='section-1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-8'>



<div className='_maincard border mt-5'>
<div className='main_header'>
<h3 className='ps-3'>The Tranus</h3>


</div>
<div className='row p-3 bg-dark ms-0 me-0'>
<div className='col-sm-2'>
<img src={require("../assets/images/call.jpg")}  className='w-100'/>

</div>
<div className='col-sm-9'>
<p className='text-white'>
Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum ad labore modi aperiam, error perferendis eos non totam accusantium officia, officiis, laborum et suscipit assumenda voluptas qui soluta id nulla!
Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum ad labore modi aperiam, error perferendis eos non totam accusantium officia, officiis, laborum et suscipit assumenda voluptas qui soluta id nulla!

</p>

</div>


</div>
<div className='main_header d-flex justify-content-between'>
<div>
<p className='mb-0'>Release Date</p>
<p className='mb-0'>
May 2023
</p>
</div>
<div>
<p className='mb-0'>Release Date</p>
<p className='mb-0'>
May 2023
</p>
</div>
<div>
<p className='mb-0'>Release Date</p>
<p className='mb-0'>
May 2023
</p>
</div>
<div>
<p className='mb-0'>Release Date</p>
<p className='mb-0'>
May 2023
</p>
</div>
<div>
<p className='mb-0'>Release Date</p>
<p className='mb-0'>
May 2023
</p>
</div>
</div>


</div>
 <div className='main_top mt-5 '>
                <h4 className='pb-3 border-bottom'>
                Monotonectally build bleeding-edge intellectual capital through cross functional potentialities. Holisticly provide access to enterprise potentialities whereas user friendly.
                </h4>
                <p>ompetently seize highly efficient communities with long-term high-impact vortals. Rapidiously morph equity invested materials and highly efficient e-markets. Collaboratively leverage existing resource-leveling e-markets with technically sound bandwidth. Interactively morph sustainable catalysts for change after 24/7 “outside the box” thinking. Professionally implement frictionless convergence before 24/365 leadership skills. Compellingly deliver multidisciplinary e-commerce vis-a-vis future-proof technology. Professionally streamline efficient e-markets without pandemic models. Collaboratively impact team driven vortals for functional experiences. Dramatically scale principle-centered metrics and resource sucking web-readiness. Enthusiastically enhance cross-platform growth strategies and viral strategic theme areas.

Rapidiously formulate compelling resources whereas interdependent deliverables. Seamlessly procrastinate plug-and-play methodologies via functionalized e-business. Globally synthesize next-generation web services before mission-critical models. Synergistically maintain efficient convergence before customer directed expertise. Assertively transition frictionless mindshare whereas ubiquitous infomediaries. Enthusiastically innovate efficient customer service and distinctive systems. Authoritatively productivate market-driven ideas and dynamic platforms. Competently incentivize performance based ROI for exceptional initiatives. Appropriately synergize value-added communities for robust benefits. Professionally transition customized bandwidth for just in time processes. Uniquely iterate dynamic leadership skills and progressive convergence. Globally enhance tactical supply chains for viral relationships. Energistically customize seamless alignments vis-a-vis installed base synergy. Objectively innovate innovative potentialities with resource maximizing deliverables. Compellingly transform error-free imperatives with emerging meta-services.</p>

<img src={require("../assets/images/gire.jpg")}  className='img-fluid'/>

<p>Appropriately expedite accurate applications after economically sound bandwidth. Dramatically transform enterprise alignments before viral resources. Progressively leverage other’s fully researched total linkage and reliable portals. Phosfluorescently conceptualize clicks-and-mortar channels for team building functionalities. Proactively build visionary infomediaries vis-a-vis turnkey relationships.

Intrinsicly network stand-alone web-readiness through multidisciplinary alignments. Rapidiously redefine process-centric portals whereas impactful quality vectors. Continually synergize cooperative content for cross functional users. Uniquely cultivate inexpensive systems with compelling strategic theme areas. Synergistically empower accurate outsourcing rather than cross-media expertise.

Efficiently evisculate synergistic functionalities without orthogonal innovation. Uniquely revolutionize performance based alignments after bricks-and-clicks human capital. Completely productize flexible technologies without magnetic services. Distinctively harness focused results and cross functional applications. Distinctively iterate prospective expertise rather than robust collaboration and idea-sharing.

Compellingly envisioneer cutting-edge infomediaries whereas effective technologies. Competently synthesize inexpensive markets via high standards in systems. Enthusiastically enable stand-alone leadership after cross functional mindshare. Assertively foster orthogonal e-business whereas real-time opportunities. Seamlessly synergize go forward e-tailers without backend technology.

Objectively mesh proactive customer service before optimal growth strategies. Collaboratively envisioneer exceptional mindshare and client-centric e-business. Objectively embrace timely materials rather than interactive architectures. Professionally deliver backend data with premium users. Enthusiastically parallel task resource-leveling leadership skills with user-centric results.

Competently customize B2C methods o</p>
                </div>

                            <div>





                            </div>





                        </div>
                        <div className='col-sm-4'>

                        <div className='mt-5 mb-5 radting_card'>
                        <div className='row'>
                        <div className='col-sm-7 sidesec'>
                        <h3>
                            Average User Rating
                        </h3>
                        <a>Write a Review</a>
                        <p>3 User Review</p>

                        </div>
                        <div className='col-sm-5 d-flex justify-content-center'>
                        <div className='rate '>
                        <h3 className='text-white mb-0'>{ratings}</h3>
                               </div>

</div>

                        </div>

                        <div className='new-w'>
                        <div className='d-flex justify-content-center'>
        {[...Array(10)].map((_, index) => (
          <button
            key={index + 1}
            onClick={() => handleRatingChange(index + 1)}
            style={{ background: index + 1 <= ratings ? '#ffcc00' : 'transparent',color:index + 1 <= ratings ? 'black' : 'white',width:"30px" }}

          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className='d-flex justify-content-between mt-3'>
      <p className='text-white'>Your Rating</p>
      <p className='text-white'>{ratings}</p>

      </div>



                        </div>


                        </div>


                            <div class="wpb_column vc_column_container vc_col-sm-4">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                        <div id="gp_ranking_wrapper_1" class="gp-ranking-wrapper gp-vc-element ">
                                            <div class="gp-inner-loop">
                                                <div className='setHeading'>
                                                    {/* <h5><span style={{ color: "red" }}>N</span>ew Book</h5> */}
                                                </div>
                                                <section class="top gp-post-item gp-rated-item gp-top-ranked-item  post-2704 page type-page status-publish has-post-thumbnail gp_hubs-hub-games gp_hubs-hub-pc gp_hubs-hub-ps3 gp_hubs-hub-ps4 gp_hubs-hub-wii release-date-april-2014 genre-racing rating-children developed-by-respawn-entertainment publisher-activision">

                                                    <div class="gp-ranking-number-overlay">1</div>
                                                    <div class="gp-bg-overlay-dark"></div>
                                                    <div class="gp-foreground-overlay">
                                                        <div class="gp-post-thumbnail gp-loop-featured">
                                                            <div class=" gp-image-above"> <a href="https://gauge.ghostpool.com/mario-kart-8/"> <img src="https://gauge.ghostpool.com/wp-content/uploads/photodune-2360363-color-the-town-s-120x120.jpg" data-rel="https://gauge.ghostpool.com/wp-content/uploads/photodune-2360363-color-the-town-s-240x240.jpg" width="120" height="120" alt="Mario Kart 8" class="gp-post-image" /> </a></div>
                                                        </div>
                                                        <div class="gp-loop-content gp-image-align-left">
                                                            <div class="gp-rating-wrapper">
                                                                <div class="gp-site-rating-wrapper gp-large-rating gp-rating-gauge">
                                                                    <div class="gp-rating-inner">
                                                                        <div class="gp-score-clip gp-no-score-clip-1">
                                                                            <div class="gp-score-spinner" ></div>
                                                                        </div>
                                                                        <div class="gp-score-clip gp-no-score-clip-2">
                                                                            <div class="gp-score-filler"></div>
                                                                        </div>
                                                                        <div class="gp-score-inner">
                                                                            <div class="gp-score-table">
                                                                                <div class="gp-score-cell"> 9.4</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <h4 class="gp-rating-text">Amazing</h4>
                                                                </div>
                                                            </div>
                                                            <h3 class="gp-loop-title"><a href="https://gauge.ghostpool.com/mario-kart-8/" title="Mario Kart 8">Mario Kart 8</a></h3>
                                                            <div class="gp-loop-meta"> <span class="gp-post-meta">Release Date: <a href="https://gauge.ghostpool.com/release-date/april-2014/" rel="tag">April 2014</a></span></div>
                                                        </div>
                                                    </div>
                                                </section>

                                                <section class="backGr gp-post-item gp-rated-item gp-other-ranked-item  post-2563 page type-page status-publish has-post-thumbnail gp_hubs-hub-games gp_hubs-hub-pc gp_hubs-hub-ps3 gp_hubs-hub-ps4 gp_hubs-hub-wii gp_hubs-hub-xbox-360 gp_hubs-hub-xbox-one release-date-may-2015 genre-action genre-first-person-shooter genre-team-based rating-mature developed-by-bluepoint-games developed-by-respawn-entertainment publisher-activision" >
                                                    <div class="gp-ranking-number-overlay">2</div>
                                                    <div class="gp-bg-overlay-dark"></div>
                                                    <div class="gp-foreground-overlay d-flex position-relative">
                                                        <div class="gp-post-thumbnail gp-loop-featured">
                                                            <div class=" gp-image-align-left"> <a href="https://gauge.ghostpool.com/call-of-duty-ghosts/"> <img src="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-80x80.jpg" data-rel="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-160x160.jpg" width="80" height="80" alt="Call Of Duty: Ghosts" class="gp-post-image" /> </a></div>
                                                        </div>

                                                        <div className='details'>
                                                            <h3 className='text-white'>The Taures</h3>
                                                            <p className='text-white'>Release Date 23/09/2023</p>

                                                        </div>
                                                        <div className='ratingset'>
                                                            <CircularProgressBar rating={rating} />
                                                        </div>

                                                    </div>
                                                </section>
                                                <section class="backGr gp-post-item gp-rated-item gp-other-ranked-item  post-2563 page type-page status-publish has-post-thumbnail gp_hubs-hub-games gp_hubs-hub-pc gp_hubs-hub-ps3 gp_hubs-hub-ps4 gp_hubs-hub-wii gp_hubs-hub-xbox-360 gp_hubs-hub-xbox-one release-date-may-2015 genre-action genre-first-person-shooter genre-team-based rating-mature developed-by-bluepoint-games developed-by-respawn-entertainment publisher-activision" >
                                                    <div class="gp-ranking-number-overlay">2</div>
                                                    <div class="gp-bg-overlay-dark"></div>
                                                    <div class="gp-foreground-overlay d-flex position-relative">
                                                        <div class="gp-post-thumbnail gp-loop-featured">
                                                            <div class=" gp-image-align-left"> <a href="https://gauge.ghostpool.com/call-of-duty-ghosts/"> <img src="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-80x80.jpg" data-rel="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-160x160.jpg" width="80" height="80" alt="Call Of Duty: Ghosts" class="gp-post-image" /> </a></div>
                                                        </div>

                                                        <div className='details'>
                                                            <h3 className='text-white'>The Taures</h3>
                                                            <p className='text-white'>Release Date 23/09/2023</p>

                                                        </div>
                                                        <div className='ratingset'>
                                                            <CircularProgressBar rating={rating} />
                                                        </div>

                                                    </div>
                                                </section>
                                                <section class="backGr gp-post-item gp-rated-item gp-other-ranked-item  post-2563 page type-page status-publish has-post-thumbnail gp_hubs-hub-games gp_hubs-hub-pc gp_hubs-hub-ps3 gp_hubs-hub-ps4 gp_hubs-hub-wii gp_hubs-hub-xbox-360 gp_hubs-hub-xbox-one release-date-may-2015 genre-action genre-first-person-shooter genre-team-based rating-mature developed-by-bluepoint-games developed-by-respawn-entertainment publisher-activision" >
                                                    <div class="gp-ranking-number-overlay">2</div>
                                                    <div class="gp-bg-overlay-dark"></div>
                                                    <div class="gp-foreground-overlay d-flex position-relative">
                                                        <div class="gp-post-thumbnail gp-loop-featured">
                                                            <div class=" gp-image-align-left"> <a href="https://gauge.ghostpool.com/call-of-duty-ghosts/"> <img src="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-80x80.jpg" data-rel="https://gauge.ghostpool.com/wp-content/uploads/photodune-5584418-nuclear-future-s-160x160.jpg" width="80" height="80" alt="Call Of Duty: Ghosts" class="gp-post-image" /> </a></div>
                                                        </div>

                                                        <div className='details'>
                                                            <h3 className='text-white'>The Taures</h3>
                                                            <p className='text-white'>Release Date 23/09/2023</p>

                                                        </div>
                                                        <div className='ratingset'>
                                                            <CircularProgressBar rating={rating} />
                                                        </div>

                                                    </div>
                                                </section>

                                            </div>
                                        </div>
                                        <div class="vc_empty_space" style={{ height: "40px" }}><span class="vc_empty_space_inner"></span></div>

                                        <div class="vc_empty_space" style={{ height: "40px" }}><span class="vc_empty_space_inner"></span></div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
            </section>


















        </>
    )
}
export default SinglePage;
