import React, { useState, useMemo, useEffect } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import "../assets/css/Authore.css"
import "../assets/css/SingleAuthore.css"
import isoLangs from 'iso-639-1';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import config from '../config/Config';
import QuickViewModal from '../Component/QuickView'
import draftToHtml from 'draftjs-to-html';
import AddToWishlist from '../Component/AddToWishlist';
import NewFollow from '../Component/Follow';



const languageOptions = isoLangs.getLanguages(isoLangs.getAllCodes()).map(lang => ({
   value: lang.code,
   label: lang.name,
}));


export default function SinglePublisher() {
   const [isOpen, setIsOpen] = useState(false);

   const navigate = useNavigate();
   const { state } = useLocation();
   const { dynamicUserName } = useParams();
   const [userBookCount, setUserBookCount] = useState('0');

   const [certificates, setCertificates] = useState([]);
   const [loading, setLoading] = useState(false);
   const [cards, setauthore] = useState(null)
   const [showModal, setShowModal] = useState(false);
   const [selectedItem, setSelectedItem] = useState(null);
   const [dataPublisher, setdataPublisher] = useState()
   const [sellerStore , setsellerStore] = useState([])
   const [followerCount , setfollowerCount] = useState('0')
   const [profileVisitCount, setprofileVisitCount] = useState('0')


   useEffect(() => {
      const handleOutsideClick = (event) => {
         if (isOpen && event.target.classList.contains('modal')) {
            closeModal();
         }
      };

      document.addEventListener('click', handleOutsideClick);

      return () => {
         document.removeEventListener('click', handleOutsideClick);
      };
   }, [isOpen]);

   const fetchCertificates = async () => {
      try {
         const response = await fetch(`${config.api_base_url}/api/v1/user/getCertificates?userId=${userId}`);
         if (!response.ok) {
            throw new Error('Failed to fetch data');
         }
         const data = await response.json();
         setCertificates(data.data.certificates);
      } catch (error) {
         console.error('Error fetching certificates:', error);
      }
   };

   const openModal = () => {
      setIsOpen(true);
      fetchCertificates()
   };

   const closeModal = () => {
      setIsOpen(false);
   }


   // Now you can use username and userId in your component logic
   //  console.log('Username==============>>>>>>>>>>>.:', username);
   //  const [userId,setUserId]=useState("")

   const str = dynamicUserName;
   const elements = str.split("-");
   const userId = elements[elements.length - 1];

   const [value, setValue] = useState('')
   const options = useMemo(() => countryList().getData(), [])
   const [selectedLanguage, setSelectedLanguage] = useState(null);

   const handleChange = (event) => {
      const selectedOption = languageOptions.find(option => option.value === event.target.value);
      setSelectedLanguage(selectedOption);
   };

   const changeHandler = value => {
      setValue(value)
   }

   

   useEffect(() => {
      if (!userId) {
         // Handle the case when userId is not available, you can redirect or show an error message
         console.error('User ID is not available');
         // Example: Redirect to a default page
         navigate('/');
      } else {
         AuthorAlldetails();
      }
   }, [userId, navigate]);

   useEffect(() => {
      // Detailsbook()
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });


   }, [] )

   const AuthorAlldetails = () => {
      var requestOptions = {
         method: 'GET',
         redirect: 'follow'
      };

      fetch(config.api_base_url + `/api/v1/user/details/${userId}`, requestOptions)
         .then((response) => {
            if (response.status != 200) {
               alert("Please Fill Valid Details")
            }
            return response.json();
         })
         .then(async (responseData) => {
            setauthore(responseData.data.books)
            setUserBookCount(responseData.data.userBookCount)
            await setdataPublisher(responseData.data.userData)
            await setsellerStore(responseData.data.sellerStore)
            await setfollowerCount(responseData.data.followerCount)
            await setprofileVisitCount(responseData.data.profileVisitCount)
         })
         .catch(error => console.log('error', error));
   }
   console.log(dataPublisher, "dataPublisher")
   const [showFullText, setShowFullText] = useState(false);
   const Detailsset = (item) => {
      if(!item.metaTitle || item.metaTitle === "") {
        navigate(`/books/details/${item._id}`, { state: item });
      } else {
        navigate(`/books/details/${item.metaTitle}`, { state: item });
      }
    }
   const handleCardClick = (item) => {
      setSelectedItem(item);
      setShowModal(true);
   }; 


   const AuthoreDetails = async (item) => {
      const { id, firstName } = item;
      console.log(item, "ItemGet")
      const urlFriendlyAuthorName = encodeURIComponent(item.authorDetails.lastName).replace(/%20/g, '-');

      navigate(`/authors/${item.authorDetails.firstName}${urlFriendlyAuthorName}`, { state: item });

      // navigate(`/authors/${item.id}`, { state: item});



   }
   const truncateText = (text, wordCount) => {
      const words = text.split(' ');

      if (words.length > wordCount) {
         const truncatedWords = words.slice(0, wordCount);
         return `${truncatedWords.join(' ')} ...`;
      } else {
         return text;
      }
   };

   const renderText = () => {
      const htmlContent = draftToHtml(JSON.parse(dataPublisher.aboutUser));
      if (showFullText) {
         return <span dangerouslySetInnerHTML={{ __html: htmlContent }}></span>;
      } else {
         const truncatedContent = htmlContent.split(' ').slice(0, 30).join(' ');
         return <span dangerouslySetInnerHTML={{ __html: truncatedContent }}></span>;
      }
   };


   return (
      <div>
         {cards !== null && dataPublisher ? <section class="leader">
            <div class="container">
               <div class="row">
                  <div class="col-lg-8">
                     <div class="leader-pro">
                        <div class="row">
                           <div class="col-md-4">
                              <div class="per-profile">
                                 <div class="rank_no">
                                    <span>#1</span>
                                 </div>
                                 <img src={config.api_base_url + `/api/v1/images?fileName=${dataPublisher.profileImage}`} alt="" />
                                 <div class="follow_btn">
                                    <NewFollow followingId={userId} />
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-8">
                              <div class="per-details">
                                 <h2>{sellerStore.shopName} </h2>
                                 <p className="">
                                    {renderText()}
                                 </p>
                                 <div className="d-flex justify-content-end">
                                    {dataPublisher.aboutUser?.length > 30 && (
                                       <a className="AddRead" onClick={() => setShowFullText(!showFullText)}>
                                          {showFullText ? 'Read Less' : 'Read More'}
                                       </a>
                                    )}
                                 </div>
                                 <div class="row pt-3 align-items-center">
                                    <div class="col-4">
                                       <div class="total_articles articlee_view text-center">
                                          <img src={require("../assets/images/1.png")} alt="" />
                                          <h3>Followers: <span id="follow_count">{followerCount}</span></h3>                                       </div>
                                    </div>
                                    <div class="col-4">
                                       <div class="total_articles articlee_view text-center">
                                          <img src={require("../assets/images/2.png")} alt="" />
                                          <h3>Book: <span>{userBookCount}</span></h3>
                                       </div>
                                    </div>
                                    <div class="col-4">
                                       <div class="total_views articlee_view text-center">
                                          <img src={require("../assets/images/3.png")} alt="" />
                                          <h3>Visitor: <span>{profileVisitCount}</span></h3>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="authors_post">
                        <div class="row ">
                           <div class="col-md-6 ">
                              <h1>Store's Books</h1>
                           </div>
                           <div class="col-md-6 text-center d-flex justify-content-around">
                              <Select className='w-50 newItemMob mt-4 h-50' options={options} value={value} onChange={changeHandler} />
                              <select style={{

                                 borderRadius: "4px",
                                 border: "1px solid #ddd",
                                 height: "47%"

                              }} className=' mt-4 addedSelect' value={selectedLanguage?.value} onChange={handleChange}>
                                 <option value="" disabled>Select a language</option>
                                 {languageOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                       {option.label}
                                    </option>
                                 ))}
                              </select>
                           </div>
                           
                           {
                              
                              !cards || cards.length <= 0 ? (<span>No Book Found </span>): (
                              cards.map((item, index) => {
                                 return (
                                    <div class="col-lg-4 col-md-4">
                                       <div key={index} className="new_arrival_sec">
                                          <div className="p-2">
                                             <div class="book1">
                                                <div class="book_img">
                                                   <img onClick={() => Detailsset(item)} src={config.api_base_url + `/api/v1/images?fileName=${item.mainImage}`} alt="" />
                                                   {/* <span className='iconHEart'><i class="fa fa-heart-o" aria-hidden="true"></i></span> */}
                                                   <AddToWishlist  productId={item} />

                                                </div>
                                                <div class="price_details">                                                 
                                                   <div className='d-flex mb-2'>
                                                      <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>                                            <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                                      <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                                      <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                                      <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                                   </div>
                                                   <h4>Rs. {item.listingPrice}</h4>
                                                   <h2>Rs. {item.price}</h2>
                                                   <a className='text-white' onClick={() => Detailsset(item)} >
                                                      Buy Now
                                                   </a>
                                                </div>

                                             </div>
                                             <div class="book_details mt-3">
                                                <div class="book_name">
                                                   <p className='mb-0' onClick={() => Detailsset(item)}>       {truncateText(item.title, 4)}</p>
                                                   {item.authorId == "-1" ? <p className='mt-0'  >By: {item.authorDetails.firstName} {item.authorDetails.lastName} </p> :
                                                      <p className='mt-0' onClick={() => AuthoreDetails(item)}>By: {item.authorDetails.firstName} {item.authorDetails.lastName} </p>}
                                                </div><div class="book_icon">
                                                   {/* <span><i class="fa fa-heart" aria-hidden="true"></i></span> */}
                                                   <span><i class="fa fa-book" aria-hidden="true"></i></span>
                                                   {
                                                      item.audioBook == "0" ? <span><i class="fa fa-microphone-slash" aria-hidden="true"></i></span>

                                                         :
                                                         <span><i class="fa fa-microphone" aria-hidden="true"></i></span>}

                                                   <span onClick={() => handleCardClick(item)}><i class="fa fa-eye" aria-hidden="true"></i></span>
                                                   <span><i class="fa fa-shopping-cart" aria-hidden="true"></i></span>
                                                   <span><i class="fa fa-share-alt" aria-hidden="true"></i></span>

                                                </div>

                                             </div>
                                          </div>
                                       </div>

                                    </div>

                                 )
                              }

                              ))
                           }
                           <QuickViewModal
                              show={showModal}
                              onHide={() => setShowModal(false)}
                              item={selectedItem}
                           />
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="leader-pro_service">
                        <h3>My Awards &amp; Achievements</h3>
                        <div id="carouselExampleControls" class="carousel slide mt-3" data-ride="carousel">
                           <div class="carousel-inner">
                              <div class="carousel-item awards active">
                                 {
                                    (dataPublisher.certificates && dataPublisher.certificates !== null) ? (<img class="d-block w-100" src={`${config.api_base_url}/api/v1/images?fileName=${JSON.parse(dataPublisher.certificates)[0]}`} alt={`Certificate 0`} width="100%" />) : (<img src={require("../assets/images/award.png")} class="d-block w-100" alt="..." />)
                                 }
                                 <div class="award_title">
                                    <a><h5 onClick={openModal}  >Show All Awards &amp; Achievements here.</h5></a>
                                    <div>
                                       <div className={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                                          <div className="modal-dialog modal-xl" role="document">
                                             <div className="modal-content">
                                                <div className="modal-header">
                                                   <h5 className="modal-title">Achievements And Certificate</h5>
                                                   <span aria-hidden="true" onClick={closeModal}>&times;</span>
                                                </div>
                                                <div className="modal-body">
                                                   {/* Display API data */}
                                                   <div className="row ">
                                                      {certificates.length === 0 ? (
                                                         <p>Loading certificates...</p>
                                                      ) : (
                                                         certificates.map((certificate, index) => (
                                                            <div className='col-md-4 col-6 my-1' key={index}>
                                                               <img className='border border-dark' src={`${config.api_base_url}/api/v1/images?fileName=${certificate}`} alt={`Certificate ${index}`} width="100%" />
                                                            </div>
                                                         ))
                                                      )}
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       {isOpen && <div className="modal-backdrop fade show"></div>}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="leader-pro_service">
                        <h3> Introduction </h3>
                        <div class="mt-3">
                           {dataPublisher.videoIntroduction !== null || dataPublisher.videoIntroduction !== " " ?

                              <iframe
                                 title={`YouTube Video `}
                                 width="100%"
                                 height="210px"
                                 src={`https://www.youtube.com/embed/${dataPublisher.videoIntroduction}`}
                                 frameBorder="0"
                                 allowFullScreen
                              ></iframe>
                              :
                              <img src={require("../assets/images/video.png")} class="d-block w-100" alt="..." />}

                           {/* <img src={require("../assets/images/video.png")} class="d-block w-100" alt="..." /> */}
                        </div>
                     </div>

                     <div class="leader-pro_service">
                        <h3>Connect with me</h3>
                        <span>Submit Your Bid</span>
                        <div class="bid_form">
                           <form method="POST" action="includes/article-bid" enctype="multipart/form-data">
                              <input type="text" name="name" placeholder="Enter Your Name" required="" />
                              <input type="hidden" name="post_author" value="1" />
                              <input type="hidden" name="url" value="admin-1" />
                              <input type="email" name="email" placeholder="Enter Your Email" />
                              <input type="email" name="phone" placeholder="Enter Your Phone" />
                              <input type="email" name="phone" placeholder="Enter Your Country Name" />

                              \
                              <input type="email" name="phone" placeholder="Want Publisher for Country" />
                              <input type="email" name="phone" placeholder=" Enter many copies distribution" />

                              {/* <input type="email" name="phone" placeholder="Enter Your Language" /> */}

                              <input type="number" name="req" placeholder="Message" required="" />
                              <button type="submit" name="submit_form">Submit</button>
                           </form>
                        </div>
                     </div>


                  </div>


               </div>
            </div>
         </section> : <div className="loader-overlay">
            {/* You can style this loader overlay as needed */}
            <div className="loader-container">
               <img src={require("../assets/images/Loader.gif")} alt="Loading" className="loader" />
            </div>
         </div>
         }




      </div>
   )
}
