import { faBell, faBook, faCartShopping, faList, faSignOut, faTasks, faTasksAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const Account=()=>{
    const [allDetails,setAllDetails]=useState([{
        route: "/MyAccount",
        icon: faUser,
        label: "Manage Profile",
        content:"Update and edit your profile information",

      },
      {
        route: "/manage-book",
        icon: faBook,
        label: "Manage Books",
        content:"Explore, read, and organize your bookshelf",

      },{
        route: "/ManageOrder",
        icon: faCartShopping,
        label: "Manage Order",
        content:"Track, modify, and organize your order",
      },,{
        route: "/Subscription",
        icon: faTasks,
        label: "Our Plans",
        content:"Select subscription plans available.",
      },{
        route: "/",
        icon: faList,
        label: "Account Summary",
        content:"Review summary of your account details",
      },{
        route: "/Signup",
        icon: faTasksAlt,
        label: "Activities",
        content:"Explore, engage, and enjoy various activities",
      },{
        route: "/Signup",
        icon: faSignOut,
        label: "Logout",
        content:"Securely sign out from your account.",
      }])

      useEffect(()=>{
        // Detailsbook()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });


      },[]

      )
    return(

        <section className="main_account">
        <div className="container">
        <div className="row">
        <div className="col-sm-12">
        <h3 className="ps-3 ">Account</h3>
        </div>
        {
            allDetails.map((item,index)=>{
                return(
                    <div className="col-sm-4">
                    <Link to={item.route}>
                    <div className="Account_cards">
                    <div>
                    <FontAwesomeIcon size="lg" icon={item.icon} className='iconSet'/>

                        <h6>{item.label}</h6>
                        <p>{item.content}</p>
                    </div>

</div>
</Link>
</div>

                )
            }

            )
        }


        </div>

        </div>

        </section>
    )

}
export default Account