import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressBar = ({ rating }) => {
  const normalizedRating = Math.min(Math.max(rating, 0), 10); // Ensure the rating is between 0 and 10

  return (
    <div className='rotate'>
      <CircularProgressbar
        value={normalizedRating * 10} // Scale the rating to match the percentage (0-100)
        text={`${normalizedRating.toFixed(1)}`} // Display the rating with one decimal place
        strokeWidth={10}
        styles={buildStyles({
          textColor: 'white',
          fontSize:'26px',
          pathColor: '#3498db',
          trailColor: '#d6d6d6',
        })}
      />
    </div>
  );
};

export default CircularProgressBar;