import React, { useEffect, useState } from 'react'
import "../assets/css/Authore.css"
import "../assets/css/SingleAuthore.css"
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import config from '../config/Config';


export default function Categoriespage(props) {
  const { state } = useLocation();
  const [dataCat, setCatData] = useState("")
  const { username } = useParams();

  const CategoryData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`https://test.booksbea.com/api/v1/category/getCategoryById?field=name&value=${username}`, requestOptions)
      .then((response) => {
        if (response.status != 200) {
          alert("Please Fill Valid Details  sososo")



        }

        return response.json();
      })
      .then(async (responseData) => {

        console.log("RESULTS HEREforData: for  send data materriall all ", responseData)
        setCatData(responseData.data)
        dataCatList()




      })
      .catch((error) => console.error(error));
  }
  console.log(username, '<<<username')

  const [cards, setItemCard] = useState(null)


  // console.log(props.location.data,"dataChecking on click");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [userId, setUserId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    console.log("click");
    setIsModalOpen(true)

  }

  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    // Check if the state is available and set imageLoaded to true
    if (username) {
      CategoryData();
      if(dataCat){
        dataCatList();
      }
     


      console.log(state, "statte")
      setImageLoaded(true);

    }
  }, [username]);
  useEffect(() => {

    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);

    if (parsedUsername) {
      console.log(parsedUsername);

      setUserId(parsedUsername.userId)


    } else {
      console.log('No username found in local storage');
    }
    // AllAddress()

  }, []);


  useEffect(() => {
    // This useEffect depends on userId and calls AllAddress when userId changes and is valid
    if (userId) {


    }
  }, [userId]);


  const dataCatList = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "pageNumber": 1,
      "pageSize": 10,
      "filters": [
        {
          "field": "categoryId",
          "condition": "eq",
          "value": dataCat.id
        }
      ]
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    console.log(raw, "raw")

    fetch(`${config.api_base_url}/api/v1/books/find`, requestOptions)
      .then((response) => {
        if (response.code !== 200) {
          // Handle error
          console.error("Error fetching data");
        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("RESULTS all booking details", responseData);
        if (responseData.code == "200") {
          setItemCard(responseData.data)
        } else {
          alert("No Data Found")
        }

      })
      .catch((error) => console.log('error', error));

  }
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleCardClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  const AuthoreDetails = async (item) => {
    const { id, firstName } = item;
    console.log(item, "ItemGet")
    const urlFriendlyAuthorName = encodeURIComponent(item.authorDetails.lastName).replace(/%20/g, '-');

    navigate(`/authors/${item.authorDetails.firstName}${urlFriendlyAuthorName}`, { state: item });

    // navigate(`/authors/${item.id}`, { state: item});



  }
  const truncateText = (text, wordCount) => {
    const words = text.split(' ');

    if (words.length > wordCount) {
      const truncatedWords = words.slice(0, wordCount);
      return `${truncatedWords.join(' ')} ...`;
    } else {

      return text;
    }
  };

  const navigate = useNavigate();
 
  const Detailsset = (item) => {
    if (!item.metaTitle || item.metaTitle === "") {
      navigate(`/books/details/${item._id}`, { state: item });
    } else {
      navigate(`/books/details/${item.metaTitle}`, { state: item });
    }
    window.location.reload();
  }


  const AddCart = (item) => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "productId": item._id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };


    fetch(config.api_base_url + `/api/v1/cart/add/${userId}`, requestOptions)
      .then((response) => {
        if (response.status != 201) {
          alert("Please Fill Valid Details")



        }

        return response.json();
      })
      .then(async (responseData) => {
        alert(responseData.message)
        console.log("RESULTS HEREforData: for", responseData)




      })
      .catch(error => console.log('error', error));


  }


  return (
    <div>
      {cards !== null ?
        <section class="leader">

          <div class="container">
            <div class="row">
              <div class="col-lg-10">
                <div class="leader-pro">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="per-profile">
                        <div class="rank_no">
                          <span>#1</span>
                        </div>


                        {/* <!--<img src="images/shubham-smile-it.png" alt="">--> */}

                        {/* <img src={config.api_base_url+`/api/v1/images?fileName=${state.categoryImage}`} alt={state.categoryName} /> */}

                        {dataCat && dataCat.categoryImage && (
                          <img
                            src={config.api_base_url + `/api/v1/images?fileName=${dataCat.categoryImage}`}
                            alt={dataCat.categoryName}
                          />
                        )}



                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="per-details">
                        <h2>{dataCat.categoryName}</h2>

                        {/* <span><p>Administrator</p></span>
                      */}
                        {dataCat && dataCat.description && (
                          <p> {dataCat.description}.</p>
                        )}
                        {/* <!--<p>tis libero cupiditate placeat assumenda neque!</p>--> */}
                        <div class="row pt-3 align-items-center">

                          <div class="col-4">
                            <div class="total_articles articlee_view text-center">


                              <img src={require("../assets/images/1.png")} alt="" />


                              <h3>Followers: <span id="follow_count">1</span></h3>
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="total_articles articlee_view text-center">
                              <img src={require("../assets/images/2.png")} alt="" />
                              <h3>Articles: <span>1</span></h3>
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="total_views articlee_view text-center">
                              <img src={require("../assets/images/3.png")} alt="" />
                              {/* <h3>Views: <span>2M<!--</span--></span></h3> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="authors_post">
                  <div class="row justify-content-center">
                    <div class="col-md-12 text-center">
                      <h1>Related Book</h1>
                    </div>
                    {/* <!-- Post --> */}

                    {/* <!-- Post -->. */}

                    {/* <!-- Post --> */}

                    {/* <!-- Post --> */}

                    {/* <!-- Post --> */}

                    {/* <!-- Post --> */}


                    {/* <!-- Post --> */}

                    {/* <!-- Post -->
       <!--------------- */}
                    {
                      cards.length > 0 ? (
                        cards.map((item, index) => {
                          return (
                            <div class="new_arrival_sec">
                              <div className='p-2'>
                                <div class="book1">
                                  <div class="book_img">
                                    <img  onClick={() => Detailsset(item)} src={config.api_base_url + `/api/v1/images?fileName=${item.mainImage}`} alt="" />
                                    {/* <img src={require("../assets/images/book444.webp")} /> */}
                                    <span className='iconHEart'><i class="fa fa-heart-o" aria-hidden="true"></i></span>

                                  </div>
                                  {/* <div><i class="fa fa-heart" aria-hidden="true"></i></div> */}


                                  <div class="price_details">
                                    {/* <div>
                                             <img src={item.icon}  className='iconClass'/>
                                         </div> */}
                                    <div className='d-flex mb-2'>
                                      <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>                                            <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                      <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                      <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                      <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>

                                    </div>
                                    <h4 >Rs. {item.listingPrice}</h4>
                                    <h2>Rs. {item.price}</h2>

                                    <a className='text-white'
                                      onClick={() => Detailsset(item)}
                                    >

                                      Buy Now
                                    </a>
                                  </div>

                                </div>



                                <div class="book_details mt-3 ">
                                  <div class="book_name">
                                    <p className='mb-0 ' onClick={() => Detailsset(item)}>       {item.title}</p>
                                    {/* {item.authorId=="-1"? <p className='mt-0'  >By: {item.authorDetails.firstName} {item.authorDetails.lastName} </p>: */}

                                    {/* <p className='mt-0'  onClick={() => AuthoreDetails(item)}>By: {item.authorDetails.firstName} {item.authorDetails.lastName} </p>} */}

                                  </div><div class="book_icon">
                                    {/* <span><i class="fa fa-heart" aria-hidden="true"></i></span> */}
                                    {item.eBook == "0" ? <span><i class="fa fa-book" aria-hidden="true"></i> <i class="fa fa-ban newDisable" aria-hidden="true"></i></span> : <span><i class="fa fa-book" aria-hidden="true"></i> </span>}
                                    {/* <span><i class="fa fa-book" aria-hidden="true"></i> <i class="fa fa-ban newDisable" aria-hidden="true"></i></span> */}

                                    {
                                      item.audioBook == "0" ? <span><i class="fa fa-microphone-slash" aria-hidden="true"></i></span>

                                        :
                                        <span><i class="fa fa-microphone" aria-hidden="true"></i></span>}

                                    <span onClick={() => handleCardClick(item)}><i class="fa fa-eye" aria-hidden="true"></i></span>
                                    <span onClick={() => AddCart(item)}><i class="fa fa-shopping-cart" aria-hidden="true"></i></span>
                                    <span onClick={openModal}><i class="fa fa-share-alt" aria-hidden="true"></i></span>

                                  </div>

                                </div>
                              </div>

                            </div>
                          )
                        })
                      ) : (
                        <div className="no-data-message">
                          No data found
                        </div>
                      )
                    }
                    {/* <!-- Post -- */}

                    {/* <!-- Post --
               <div class="col-lg-6 col-md-6">
                  <div class="single-blog">
                     <a href="#">
                        <div class="blog-cover">
                           <img src="images/blog/top-recommended-programming-languages-for-mobile-app-development-in-2021.png" alt="Top Recommended Programming Languages For Mobile App Development In 2021">
                        </div>
                     </a>
                     <div class="blog-details">
                        <div class="blog-info">
                           <span class="categories">
                           technical                           </span>
                           <h3>
                              <a href="#">
                              Top Recommended Programming Languages For Mobile A...                              </a>
                           </h3>
                           <a href="#">
                              <p>Take a pause and think about how many times you use different apps in ...</p>
                           </a>

                           <div class="row">
                               <div class="col-6 text-left">
                                  <div class="like_dis_count">
                                     <i class="fa fa-thumbs-up"></i> <span>372</span>
                                     <i class="fa fa-thumbs-down"></i> <span>122</span>
                                  </div>
                               </div>
                               <div class="col-6 text-right">
                                  <div class="like_dis_count">
                                     <p><i class="fa fa-eye" aria-hidden="true"></i> <span>3422</span></p>
                                  </div>
                               </div>
                         </div>

                        </div>
                     </div>
                  </div>
               </div>---->
               <!-- Post --> */}


                  </div>
                </div>

              </div>









            </div>
          </div>
        </section> : <div className="loader-overlay">
          {/* You can style this loader overlay as needed */}
          <div className="loader-container">
            <img src={require("../assets/images/Loader.gif")} alt="Loading" className="loader" />
          </div>
        </div>
      }




    </div>
  )
}
