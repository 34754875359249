import React, { useState, useEffect } from 'react';
import config from '../config/Config';

// import WishlistItem from './WishlistItem';

const Wishlist = ({ addToCart }) => {
  const [wishlist, setWishlist] = useState([]);
  const [userId, setUserId] = useState("")

  useEffect(() => {
    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);
    if (parsedUsername) {
      setUserId(JSON.stringify(parsedUsername.userId));
    } else {
      console.log('No username found in local storage');
    }
  }, []);
  useEffect(() => {
    // Fetch wishlist data from the API
    if(userId){
      fetch(config.api_base_url + `/api/v1/book/wishlist/list/${userId}`)
      .then(response => response.json())
      .then(data => setWishlist(data.data))
      .catch(error => console.error('Error fetching wishlist:', error));
    }
    
  }, [userId]);

  return (
    <div>
      <section class="leader_bg">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="title_top_writer">
                <h1><span style={{ color: "red" }}>W</span>ishlist</h1>
                {/* <h3>Explore more about your favorite writers and their other amazing articles & blogs on the site. Follow them for new Updates & Blogs by them.</h3> */}

              </div>
            </div>
            <div class="col-lg-6">
              <div class="image_top_writer">
                <img src={require("../assets/images/leader-board-image.png")} alt="" />
              </div>
            </div>
          </div>
        </div>

      </section>
      <section className='my-5'>
        <div className='container'>
          <div className='row'>

            {
              wishlist !== null ?
                (!wishlist || !Array.isArray(wishlist) ?
                  (
                    <span>No item found</span>) :
                  (
                    wishlist.map(item => (
                      <WishlistItem key={item.id} item={item.productDetails} onAddToCart={addToCart} />
                    ))
                  )
                ) : (<span> No item found</span>)
            }
          </div>
        </div>
      </section>
    </div>
  );
};


const WishlistItem = ({ item, onAddToCart }) => {
  return (
    <div>
      <div className='col-md-11 '>
        <div className='card bg-white border  p-3 mb-3'>
          <div className='row'>
            <div className='col-sm-2 '>
              <div className='Side1 d-flex justify-content-center'>
                <img src={item.mainImage != null && item.mainImage != "" ? config.api_base_url + `/api/v1/images?fileName=${item.mainImage}` : `https://cdn-icons-png.flaticon.com/512/4791/4791601.png`} />
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='ti'>
                <h6>{item.title}</h6>
                {/* <p>Date :  {item.createdAt}</p> */}
              </div>
            </div>
            <div className='col-sm-2'>
              <p>INR-/  {item.price}</p>
            </div>
            <div className='col-sm-2'>
              {/* <button onClick={() => onAddToCart(item)}>Add to Cart</button> */}
            </div>
          </div>
        </div>
      </div>

      {/* <button onClick={() => onAddToCart(item)}>Add to Cart</button> */}
    </div>
  );
};

export default Wishlist;