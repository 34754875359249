import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import config from '../config/Config';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  // Divider,
  FormHelperText,
  // Grid,
  TextField,
  // Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Grid
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import Google from 'assets/images/social-google.svg';

// ==============================|| FIREBASE REGISTER ||============================== //

const Signup = ({ ...rest }) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const countryCodes = ["+1", "+91", "+44", "+81", "+86", "+49", "+33"]; // Add more country codes as needed


  const [age, setAge] = React.useState('author');

  const handleClickSelect = (event) => {
    setAge(event.target.value);

  };
  // console.log(age)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const [selectedCountryCode, setSelectedCountryCode] = React.useState('');

  const handleClickSelectCOuntry = (event) => {
    // setAge(event.target.value);
    setSelectedCountryCode(event.target.value);
    console.log(event.target.value)
  };
  const handleLogin = async (values, { setSubmitting }) => {
    // Simulate an asynchronous login operation (e.g., API call)
    console.log('Login Successful:', values);
    setTimeout(() => {

      console.log('Signup Successful:', values);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "username": values.Username,
        "email": values.email,
        "password": values.password,
        "mobile": values.mobile,
        "mobile_cc": selectedCountryCode,
        "sub_user_type": age,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(config.api_base_url + "/api/v1/auth/signup", requestOptions)
        .then((response) => {
          if (response.status != 200) {
            alert("Please Fill Valid Details")


          }

          return response.json();
        })
        .then(async (responseData) => {
          console.log("RESULTS HEREforData: for booking id", responseData)
          alert(responseData.message)
          navigate(`/Signin`);





          // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









        })
        .catch(error => console.log('error', error));
      setSubmitting(false);
    }, 1000);

  }




  return (
    <>



      <Formik
        initialValues={{
          email: '',
          password: '',
          Username: '',
          mobile: '',
          confirmpassword: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required'),
          confirmpassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        })}

        onSubmit={handleLogin}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit} {...rest} >
            <TextField
              error={Boolean(touched.Username && errors.Username)}
              fullWidth
              helperText={touched.Username && errors.Username}
              label="Full Name"
              margin="normal"
              name="Username"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.Username}
              variant="outlined"
            />
            <Grid container spacing={2}>
           <Grid item xs={3}>
    <FormControl fullWidth     margin="normal">
      <InputLabel id="country-code-label">Country</InputLabel>
      <Select
          labelId="country-code-label"
          id="country-code"
          value={selectedCountryCode}
          onChange={handleClickSelectCOuntry}
          label="Code"
        >
         {countryCodes.map((code) => (
            <MenuItem key={code} value={code}>{code}</MenuItem>
          ))}
          {/* Add more options as needed */}
        </Select>
    </FormControl>
  </Grid>
  <Grid item xs={9}>
    <TextField
      // error={Boolean(touched.mobile && errors.mobile)}
      // fullWidth
      // helperText={touched.mobile && errors.mobile}
      error={Boolean(touched.mobile && errors.mobile)}
              fullWidth
              helperText={touched.mobile && errors.mobile}
      label="Mobile"
      margin="normal"
      name="mobile"
      onBlur={handleBlur}
      onChange={handleChange}
      type="text"
      value={values.mobile}
      variant="outlined"
    />
  </Grid>
  </Grid>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address / Username"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <FormControl fullWidth
              sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
            >
              <InputLabel id="demo-simple-select-label">Seller Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                margin="normal"
                label="Age"
                onChange={handleClickSelect}
                className='w-100'
              >
                <MenuItem value="Nan">Buyer</MenuItem>
                <MenuItem value="author">Author</MenuItem>
                <MenuItem value="publisher">Publisher</MenuItem>
                <MenuItem value="shopkeeper">Bookseller</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {touched.password && errors.password && (
                <FormHelperText error id="standard-weight-helper-text">
                  {' '}
                  {errors.password}{' '}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              error={Boolean(touched.confirmpassword && errors.confirmpassword)}
              sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={values.confirmpassword}
                name="confirmpassword"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Confirm Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {touched.confirmpassword && errors.confirmpassword && (
                <FormHelperText error id="standard-weight-helper-text">
                  {' '}
                  {errors.confirmpassword}{' '}
                </FormHelperText>
              )}
            </FormControl>

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box my={0}>
              <FormControlLabel
                control={
                  <Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} name="checked" color="primary" />
                }
                label={
                  <>
                    I have read the &nbsp;
                    <Link to="#" className='text-primary'>Terms and Conditions </Link>
                  </>
                }
              />
            </Box>
            <Box mt={2}>
              <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                Register
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Signup;
