import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import config from '../config/Config';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// import jwtDecode from 'jwt-decode';
import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  CssBaseline,
  FormControlLabel,
  Checkbox,
  // CircularProgress,
} from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgressbar } from 'react-circular-progressbar';



const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const Authentic = () => {
const [emailid,setEmailid]=useState("")
const [pass,setpass]=useState("")
const [token,setToken]=useState("")
const [loading, setLoading] = useState(false);

const navigate = useNavigate();


  const handleLogin = async(values, { setSubmitting }) => {
    setLoading(true);
    console.log('Login Successful:', values);
   await setEmailid(values.email)
   await setpass(values.password)

      setSubmitting(false);
      setTimeout(() => {
        console.log('Login Successful ssuus:', values);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "password": values.password,
          "email": values.email
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(config.api_base_url+"/api/v1/auth/login", requestOptions)
        .then((response) => {
          if(response.status!= 200){
              // alert("Please Fill Valid Details")
              setLoading(false);
              toast.error("Please Fill Valid Details");

          }

          return response.json();
        })

          .then(async (responseData) => {
              console.log("RESULTS HEREforData: for booking id", responseData.data)
              // alert("Login Successfully")
              toast.error("Please Fill Valid Information");
              // toast("Please FIll Valid Details!")
              setToken(responseData.data.jwt)
           let decodedToken=await jwtDecode(responseData.data.jwt);
           console.log(decodedToken,"decodedToken")
           localStorage.setItem('jwtToken',responseData.data.jwt);
           localStorage.setItem('MyToken', JSON.stringify(decodedToken));
           if(decodedToken.user_type=="seller"){
            navigate(`/account`);
            window.location.reload();

           }else{
            window.location.reload();


           navigate(`/`);

           }



            })
          .catch(error => console.log('error', error));
      }, 3000);

  // / Simulating a delay for demonstration purposes
  };


  console.log(pass,"pass");
  console.log(emailid,"emailid");



const YourComponent = () => {
  // Example JWT token


  // Decode token
  try {
      const decodedToken = jwtDecode(token);
      console.log(decodedToken,"tokenDecode");
      // You can now use the decoded token as needed
  } catch (error) {
      console.error('Invalid token:', error);
  }

  // Rest of your component...
};




  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
       <Typography component="h4" variant="h4">
       Welcome Back!

       </Typography>
        <Typography component="h2" variant="h5">

Please login to your account
        </Typography>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={handleLogin}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '1em' }}>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
               <FormControlLabel
               className='mt-2'
            control={
              <Checkbox  name="gilad" />
            }
            label="Remember Me"
          />
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                style={{ marginTop: '1em' }}
              >
                {isSubmitting ? 'Logging In...' : 'Log In'}

              </Button> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                style={{ marginTop: '1em' }}
              >
     {loading ?<div> <span className="spinner1"></span> Please Wait...</div> : ' Login '}
              </Button>

            </form>
          )}
        </Formik>
        <ToastContainer />
      </Box>



    </Container>
  );
};

export default Authentic;









