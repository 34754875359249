// src/components/ContactUs.js
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';


import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';
import config from '../config/Config';
import { Helmet } from 'react-helmet';

const style = {
    //   position: 'absolute' as 'absolute',
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    //   bgcolor: 'background.paper',
    //   border: '2px solid #000',
    //   boxShadow: 24,
    p: 4,
};

const ContactUs = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handlePhoneChange = (value) => {
    setPod({
        ...addPod,
        contact: value,
    });
};
const handleSubmitpod = (e) => {
    e.preventDefault();
    Addpodcast()

    // You can perform actions with the form data here
    console.log('Form Data hddhd:',e);
  };

  const navigate = useNavigate();
  const Addpodcast=()=>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "name":addPod.names,
      "email": addPod.emails,
    //   "contact_cc": "+91",
      "contact": addPod.contact,
      "description": addPod.description,
      "query_type": "contact_us"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };



fetch(config.api_base_url + "/api/v1/customer_request/create", requestOptions)
.then((response) => {
if (response.code != 200) {
    //   alert("Please Fill Valid Details")


}

return response.json();
})

.then(async (responseData) => {
if(responseData.code==201){
    alert(responseData.message)
    // navigate("/Podcast")
    setPod({
        name: "",
        email: "",
        contact:"",
        description:"",

    });


}else{
    alert("Please Fill Valid Details")
}
console.log("RESULTS all All  details", responseData)

})
.catch(error => console.log('error', error));

  }
const [addPod, setPod] = useState({
    names: "",
    emails: "",
    contact: "",
    description: "",
});

const handlePodcast = (e) => {
    const { name, value } = e.target;
    setPod({
        ...addPod,
        [name]: value,
    });
};

useEffect(()=>{
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
}

)
  return (
    <section className='newcontact_Section'>


    <div className='container contact_us mt-5'>
    <div className='row'>
    <div className='col-sm-6'>
    <div className="contact-us-container">
      {/* <h2>Contact Us</h2> */}
      {/* <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Message:</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form> */}


                                                                <section className='secVg newForm'>

                                                                <form className="form1" onSubmit={handleSubmitpod}>
            <h2 className='text-start'>Contact Us
           </h2>
            <p type="Name:">
                <input
                    placeholder="Write your name here.."
                    name="names"
                    value={addPod.names}
                    onChange={handlePodcast}
                />
            </p>
            <p type="Phone:">
                <PhoneInput
                    international
                    defaultCountry="IN"
                    placeholder="Write your phone here"
                    value={addPod.contact}
                    onChange={handlePhoneChange}
                />
            </p>
            <p type="Email:">
                <input
                    placeholder="Write your email id..."
                    name="emails"
                    value={addPod.emails}
                    onChange={handlePodcast}
                />
            </p>
            <p type="Message:">
                <input
                    placeholder="What would you like to tell us.."
                    name="description"
                    value={addPod.description}
                    onChange={handlePodcast}
                />
            </p>
            <div className='d-flex justify-content-end mb-3 mt-2'>
                <button className='float-right'>Send Message</button>
            </div>
            <p className='mb-0 fw-bold'>For immediate assistance you can also email at <a href="mailto:support@booksbea.com" style={{color:"#0f7fc2"}}>support@booksbea.com</a> or call/whatsapp at  <a  href="tel:+14087551607" style={{color:"#0f7fc2"}}><span><i class="fa fa-phone" aria-hidden="true"></i></span> +1-408-755-1607</a></p>
            <p className='fw-bold'> You will find whatsapp icon at right side  bottom of this page as well.</p>
            {/* <p className='mb-0'>  <span><i class="fa fa-envelope" aria-hidden="true"></i></span><a href="mailto:support@booksbea.com" style={{color:"#0f7fc2"}}>support@booksbea.com</a> <p className='mb-0'> <a  href="tel:+14087551607" style={{color:"#0f7fc2"}}><span><i class="fa fa-phone" aria-hidden="true"></i></span> +1-408-755-1607</a></p>
            .</p> */}
        </form>

                                                                </section>


    </div>
    </div>
    <div className='col-sm-6'>
    <img src={require("../assets/images/5138237.jpg")} className='img-fluid' />
        </div>
    </div>
    </div>
    </section>
  );
};

export default ContactUs;
