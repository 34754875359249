import React, { useState } from 'react';

function SelectComponent() {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [isFetching, setIsFetching] = useState(false); // Track the fetching status

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (!selectedOption || value !== selectedOption) {
      setSelectedOption('');
    }

    if (value.length >= 2) {
      setIsFetching(true); // Start fetching data
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };

        const response = await fetch(`https://test.booksbea.com/api/v1/list/publisher/search?value=${value}`, requestOptions);
        const result = await response.json();
        console.log('API Result:', result.data);

        setFilteredOptions(result.data);
      } catch (error) {
        console.error('API Error:', error);
      } finally {
        setIsFetching(false); // Finish fetching data
      }
    } else {
      setFilteredOptions([]);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option.publicationName);
    setInputValue(option.publicationName);
    setFilteredOptions([]);
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Start typing..."
      />
      {isFetching && <div>Loading...</div>}
      {!isFetching && filteredOptions.length > 0 && (
        <ul>
          {filteredOptions.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option)}>
              {option.publicationName}
            </li>
          ))}
        </ul>
      )}
      {!isFetching && filteredOptions.length === 0 && inputValue.length >= 2 && (
        <div>List not found</div>
      )}
    </div>
  );
}

export default SelectComponent;
