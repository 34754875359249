import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const VideoReviewsCarousel = () => {
  const testimonials = [
    {
      id: 1,
      name: 'Booksbea',
      website: 'www.Booksbea.com',
      stars: 5,
      review:
        "Welcome to Booksbea – Your Gateway to Books and Beyond! Discover the world of literature with Booksbea, where words come to life. Whether you're an avid reader, an aspiring author, or someone looking to dive into the vast realm of books, we've got something special for you.",
      author: 'Virender Singh',
      videoUrl: 'https://www.youtube.com/embed/VeOA-9zdFGY',
    },

    // Add more testimonials as needed
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
  };

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
      // <FontAwesomeIcon
      //     icon={faChevronRight}
      //     className={`${className} custom-arrow`} // Add a custom class for additional styling
      //     style={{ ...style, display: "block" }}
      //     onClick={onClick}
      // />
      <div className='custom-arrow_3'>
      <i class="fa fa-chevron-right " aria-hidden="true"   style={{ ...style, display: "block" }}
      onClick={onClick}></i>
      </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
      <div className='custom-arrow_2'>
      <i class="fa fa-chevron-left " aria-hidden="true"   style={{ ...style, display: "block" }}
      onClick={onClick}></i>
      </div>
  );
}


  return (
    <section className="video_reviews">
      <div className="container">
        <div className="video_review_title text-center pb-lg-5 pb-3">
          <h2>What Our Client Think About Us</h2>
        </div>
        <Slider {...sliderSettings}>
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="carousel-item">
              <div className="video_inner">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="client_bio">
                      <h2>{testimonial.name}</h2>
                      <h4>{testimonial.website}</h4>
                      <div className="golden_stars">
                        {[...Array(testimonial.stars)].map((_, index) => (
                          <span key={index}>
                            <i className="fa fa-star" aria-hidden="true"></i>
                          </span>
                        ))}
                      </div>
                      <p>{testimonial.review}</p>
                      <h3>
                        – {testimonial.author} <span>(Founder)</span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe
                        title={`YouTube video ${testimonial.id}`}
                        width="560"
                        height="315"
                        src={testimonial.videoUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default VideoReviewsCarousel;
