import React, { useEffect, useRef, useState } from 'react'
import "../assets/css/style.css"
import { Link, useNavigate } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSearch, faHome, faUserCircle, faBook, faAdd, faShippingFast, faBookOpen, faUser } from '@fortawesome/free-solid-svg-icons';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import config from '../config/Config';

export default function Header() {
  const tabs = [{
    route: "/",
    icon: faHome,
    label: "Home"
  }, {
    route: "/Signup",
    icon: faBookOpen,
    label: "Read "
  }, , {
    route: "/Signup",
    icon: faAdd,
    label: "Add"
  }, {
    route: "/Signup",
    icon: faBook,
    label: "NoteBook"
  }, {
    route: "/Signup",
    icon: faShippingFast,
    label: "Track"
  }]



  const [itenset, setData] = useState([])
  const [profileImage ,setprofileImage] = useState("")
  useEffect(() => {
    Detailsbook()

  }, []

  )
  const Detailsbook = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(config.api_base_url + "/api/v1/category/getList", requestOptions)
      .then((response) => {
        if (response.status != 200) {
          alert("Please Fill Valid Details")


        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("header cattttt list==>>>data", responseData)
        setData(responseData.data)






        // nav("/");




        // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









      })
      .catch(error => console.log('error', error));
  }

  const navigate = useNavigate();

  const dataItem = (item) => {
    // console.log(item,"ItemGet")


    // navigate(`/book/${item.categoryName}`, { state: item});
    console.log('Navigating with item:', item);
    navigate(`/categories/${item.categoryName}`, { state: item });




  }
  const dropdownRef = useRef(null);


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDrop(false);
    }
  };
  const [drop, setDrop] = useState(false)
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("")

  const [isOrderDropdownOpen, setOrderDropdownOpen] = useState(false);

  const toggleOrderDropdown = () => {
    setOrderDropdownOpen(!isOrderDropdownOpen);
  };


  useEffect(() => {
    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);

    if (parsedUsername) {
      console.log(parsedUsername);
      // setemail(parsedUsername.email)
      setUserId(parsedUsername.userId)
      setUserType(parsedUsername.user_type)
      setprofileImage(parsedUsername.profileImage)
    } else {
      console.log('No username found in local storage');
    }
  }, []);



  const dropdownItem = () => {

    setDrop(!drop)
    setNavbarOpen(false);

  }
  const dropdownItem1 = () => {

    setDrop(!drop)
    // setNavbarOpen(false);

  }
  const Logout = async () => {
    dropdownItem()
    await localStorage.clear();

    navigate(`/`);

    window.location.reload();


  }

  const [isNavbarOpen, setNavbarOpen] = useState(false);

  const closeNavbar = () => {
    setNavbarOpen(false);
  };


  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light shadow d-block d-lg-block sticky-top" role="navigation">
          <div className="container-fluid header-sec">
            <Link className="navbar-brand book-logo" to="/">
              <img src="https://i.ibb.co/Y8Pdwgw/booksbealogo.png" className="mx-auto d-block img-fluid" alt="" />
              {/* <!--<h4 className="mb-0" style="color:black;  font-family: 'Cinzel Decorative', cursive ; "><span-->
            <!--        style="color:#62CDFF;  font-family: 'Cinzel Decorative', cursive;">B</span>ook Planet</h4>--> */}
            </Link>
            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button> */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setNavbarOpen(!isNavbarOpen)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* <div className="collapse navbar-collapse header-box" id="navbarSupportedContent"> */}
            <div className={`collapse navbar-collapse header-box ${isNavbarOpen ? 'show' : ''}`} id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                {/* <!-- <li className="nav-item">
                    <a className="nav-link" aria-current="page" href="https://booksbea.com">Home</a>
                </li> --> */}
                {/*
                <!--<li className="nav-item" >-->
                <!--    <a className="nav-link" href="https://booksbea.com">Home</a>-->
                <!--</li>--> */}
                <li className="nav-item">
                  <a className="nav-link" href="/">Home</a>
                </li>
                <li className="nav-item">
                  <Link to={'/authors'} className="nav-link" onClick={closeNavbar} >Authors</Link>
                </li>
                <li className="nav-item">
                  <Link to={"/publishers"} className="nav-link" onClick={closeNavbar} >Publishers</Link>
                </li>
                <li className="nav-item">
                  <Link to={"/book-stores"} className="nav-link" onClick={closeNavbar} >BookStores</Link>
                </li>
                <li className="nav-item">
                  <Link to={"/podcasts"} className="nav-link" onClick={closeNavbar} >Podcasts</Link>
                </li>
              
               
                <li className="nav-item  d-flex justify-content-center align-items-center newCat"  >
                  <div className="dropdown nav-link">
                    <div className=" dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      <a href="" className="m-0">Shop</a>
                    </div>
                    <ul className="dropdown-menu bg-white">
                      <li ><Link to={"/books"} className="nav-link" onClick={closeNavbar}>Books</Link></li>
                      <li ><Link to={"/audio-books"} className="nav-link" onClick={closeNavbar}>Audio-Books</Link></li>
                      <li><Link to={"/ebooks"} className="nav-link" onClick={closeNavbar}>E-books</Link></li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item  d-flex justify-content-center align-items-center newCat"  >
                  <div className="dropdown nav-link">
                    <div className=" dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      <a href="" className="m-0">Used books</a>
                    </div>
                    <ul className="dropdown-menu bg-white">
                      <li ><Link to={"/books"} className="nav-link" onClick={closeNavbar}>Sell book</Link></li>
                      <li ><Link to={"/audio-books"} className="nav-link" onClick={closeNavbar}>Buy book</Link></li>
                      {/* <li><Link to={"/ebooks"} className="nav-link" onClick={closeNavbar}>E-books</Link></li> */}
                    </ul>
                  </div>
                </li>
                <li className="nav-item  d-flex justify-content-center align-items-center newCat"  >
                  <div className="dropdown nav-link">
                    <div className=" dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      <a href="" className="m-0">Categories</a>
                    </div>
                    <ul className="dropdown-menu bg-white">
                      {
                        itenset.map((item, index) => {
                          return (
                            <li key={index} onClick={() => dataItem(item)}><a className="dropdown-item m-0" >{item.categoryName}</a></li>
                          )

                        }
                        )
                      }
                    </ul>
                  </div>
                </li>
                {/* <!-- <li className="nav-item">
                    <a className="nav-link" href="#">Audio Library</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">For Sellers</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Blog</a>
                </li> --> */}

                <li>

                  {/* <i className="fa fa-heart" aria-hidden="true"></i> */}

                </li>

              </ul>

              {/* <!-- Use the Blade directive to check if the user is authenticated -->
                         <!--Display login link if the user is not authenticated --> */}

              {/* <a href="" style={{background:"#0f7fc2",color:"white",fontFamily: "'Poppins', sans-serif"}} className="btn me-4">For Sellers</a> */}
              {userId != null && userId != "" ?
                <div className='mx-4 me-2'>
                  <Link to={"/cart"} className='iconCart'><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                  <Link to={"/Wishlist"} className='iconCart'><i className="fa fa-heart" aria-hidden="true"></i></Link>
                  <button onClick={() => dropdownItem1()} className="dropdown-toggle2  newItme">
                    <span className="item-icon ">                     
                    {
                      profileImage ? (
                        <img className='rounded-circle mx-3 shadow' src={config.api_base_url + `/api/v1/images?fileName=${profileImage}`} alt="" loading="lazy" width={'35px'} />
                      ) : (<i className="fa fa-user-circle " style={{ color: "#0F7FC2" }} aria-hidden="true"></i>)

                    }
                    </span>
                  </button>
                </div>
                :
                <>  <Link to={userId != null && userId != "" ? "/cart":"/Signin"} className='iconCart'><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                <Link to={userId != null && userId != "" ? "/Wishlist":"/Signin"} className='iconCart'><i className="fa fa-heart" aria-hidden="true"></i></Link>
                <Link to={"/Signin"} onClick={closeNavbar} style={{ color:"#0F7FC2"  }}

                  className=" ms-2">
<FontAwesomeIcon icon={faUserCircle} style={{fontSize:"26px"}}/>

                  </Link>
                  </>
              }
              {/* <Link className='iconCart'><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link> */}
              {/* <Link to='/AddProduct' className='iconCart'><i className="fa fa-plus-square" aria-hidden="true"></i></Link> */}


              {drop ?
                <ul ref={dropdownRef} className="dropdown-menu" id="newWay">
                  <li className="menu-item">
                    {/* <Link to={"/MyAccount"} className='m-0'> */}
                    <span className="item-icon">
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </span>

                    <Link to={"/MyAccount"} className="item-txt" onClick={dropdownItem}>
                      Manage Profile
                    </Link>
                    {/* </Link> */}
                  </li>
                  <li className="menu-item">
                    <span className="item-icon">
                      <i className="fa fa-first-order" aria-hidden="true"></i>
                    </span>
                    <Link to={"/myorder"} className="item-txt" onClick={dropdownItem} >
                      My Order
                    </Link>
                  </li>
                  <li className="menu-item">
                    <span className="item-icon">
                      <i className="fa fa-address-card" aria-hidden="true"></i>
                    </span>
                    <Link to={"/addresses"} className="item-txt" onClick={dropdownItem}>
                      My Addresses
                    </Link>
                  </li>
                  <li className="menu-item">
                    <span className="item-icon">
                      <i className="fa fa-gift" aria-hidden="true"></i>
                    </span>
                    <Link to={"/"} className="item-txt" onClick={dropdownItem}>
                      My Offers
                    </Link>
                  </li>
                  {/* <hr/> */}
                  {
                    userType == "seller" ? <>

                      <li className="menu-item">
                        <span className="item-icon">
                          <i className="fa fa-book" aria-hidden="true"></i>
                        </span>
                        <Link to={"/account"} onClick={dropdownItem} className="item-txt">
                          Manage Seller Account
                        </Link>
                      </li>



                    </> : null
                  }

                  <li className="menu-item fw-bold">
                    <span className="item-icon">
                      <i className="fa fa-sign-out" aria-hidden="true"></i>
                    </span>
                    <span onClick={() => Logout()} className="item-txt">
                      Logout
                    </span>
                  </li>
                </ul>
                : null}

            </div>
          </div>
        </nav>

        {/* <a  target='_blank' href="https://wa.me/+14087551607" className="back-to-top d-flex align-items-center justify-content-center active d-none d-lg-block"><i className="fa fa-whatsapp set-icon-whatsapp"></i></a> */}
        <nav className="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav" role="navigation">
          <Nav className="w-100">
            <div className=" d-flex flex-row justify-content-around ">
              {
                tabs.map((tab, index) => (
                  <NavItem key={`tab-${index}`}>
                    <Link to={tab.route} className="nav-link bottom-nav-link" activeClassName="active">
                      <div className=" d-flex flex-column justify-content-center align-items-center">
                        <FontAwesomeIcon size="lg" icon={tab.icon} className='iconSet' />
                        <div className="bottom-tab-label">{tab.label}</div>
                      </div>
                    </Link>
                  </NavItem>
                ))
              }
            </div>
          </Nav>
        </nav>



      </header>
    </>
  )
}
