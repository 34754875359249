import React from 'react';
import { Modal } from 'react-bootstrap';
import config from '../config/Config';

const QuickViewModal = ({ show, onHide, item }) => {
    return (
        <div >
        <Modal show={show} onHide={onHide} size="lg" centered className='quickVuew'>
            <Modal.Header closeButton>
                <Modal.Title>{"Book Details"}</Modal.Title>


            </Modal.Header>
            <Modal.Body>
                {/* Display item details here */}
                {item && item.mainImage &&
                <img style={{width:"100%",height:"auto"}} src={config.api_base_url + `/api/v1/images?fileName=${item.mainImage}`} alt={item.title} />
                }
                <h5>{item && item.title ? item.title : "No Title Available"}</h5>
                <p className='mb-0'>Price: Rs. {item && item.price}</p>
                <p className='mb-1'>Author: {item && item.authorDetails ? `${item.authorDetails.firstName} ${item.authorDetails.lastName}` : "Author details not available"}</p>
                            {/* Add more item details as needed */}
            </Modal.Body>
        </Modal>
        </div>
    );
};

export default QuickViewModal;
