
import React, { useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../assets/css/style.css"
import "../assets/css/bookcontent.css"
import { Link, useNavigate } from 'react-router-dom';
import config from '../config/Config';



export default function Category() {



  const [cards, setAllCards] = useState([
    {
      id: 1
    },
    {
      id: 2
    },
    {
      id: 3
    },
    {
      id: 4
    },

    {
      id: 5
    },




  ])


  const [cat, setAllCat] = useState([
    {
      id: 1
    },
    {
      id: 2
    },
    {
      id: 3
    },
    {
      id: 4
    },

    {
      id: 5
    },




  ])


  const [itenset, setData] = useState([])
  useEffect(() => {
    Detailsbook()

  }, []

  )
  const Detailsbook = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(config.api_base_url + "/api/v1/category/getList", requestOptions)
      .then((response) => {
        if (response.status != 200) {
          alert("Please Fill Valid Details")


        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("Category list==>>>data", responseData)
        setData(responseData.data)






        // nav("/");




        // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









      })
      .catch(error => console.log('error', error));
  }


  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", }}
        onClick={onClick}
      />
    );
  }



  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    focus: false,
    pauseOnHover: true,
    arrow: true,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      // <FontAwesomeIcon
      //     icon={faChevronRight}
      //     className={`${className} custom-arrow`} // Add a custom class for additional styling
      //     style={{ ...style, display: "block" }}
      //     onClick={onClick}
      // />
      <div className='custom-arrow3'>
        <i class="fa fa-chevron-right " aria-hidden="true" style={{ ...style, display: "block" }}
          onClick={onClick}></i>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className='custom-arrow2'>
        <i class="fa fa-chevron-left " aria-hidden="true" style={{ ...style, display: "block" }}
          onClick={onClick}></i>
      </div>
    );
  }
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    focus: false,
    pauseOnHover: true,
    arrow: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  var settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    focus: false,
    pauseOnHover: true,
    arrow: false,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  const navigate = useNavigate();
  const dataItem = (item) => {
    console.log(item, "ItemGet")

    navigate(`/categories/${item.categoryName}`, { state: item });



  }



  return (
    <div>




      <section class="mt-3 cat" style={{ background: "#f7f7fa" }}>
        <div class="container w-100">

          <Slider {...settings2}>
            {
              itenset.map((item, index) => {
                return (
                  <div class="main_swiper_container">

                    <div onClick={() => dataItem(item)} class="swiperChange">
                      <div><img src={config.api_base_url + `/api/v1/images?fileName=${item.categoryImage}`} alt="" />
                        <div class="text-center  Name_clas">{item.categoryName}</div>
                      </div>
                    </div>



                  </div>
                )

              }

              )
            }





          </Slider>
          {/* <button  onClick={()=>dataItem()} className='btn btn-primary'>Click me</button> */}

          {/* <div class="swiper-container">
        <div class="swiper-wrapper">















        </div>

    </div> */}

          {/* <div class="w-100 d-flex justify-content-between align-items-center " style="position: relative;
        top: -140px;">
                  <div class="swiper-button-prev"><i class="fa-solid fa-chevron-left"></i></div>
        <div class="swiper-button-next"><i class="fa-solid fa-chevron-right"></i></div>
             </div> */}


        </div>
      </section>
    </div>
  )
}

