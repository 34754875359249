import React from 'react';
import  { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import config from '../config/Config';


const AddToWishlist = ({ productId }) => {
  const [userId, setUserId] = useState("")


  useEffect(() => {

    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);

    if (parsedUsername) {
      console.log(parsedUsername);
      setUserId(JSON.stringify(parsedUsername.userId));
    } else {
      console.log('No username found in local storage');
    }
  }, []);

  const addToWishlist = async () => {
    try {
      if(productId){
        const response = await fetch(config.api_base_url + `/api/v1/book/wishlist/add/${userId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            productId: productId._id
          })
        });
        if (!response.ok) {
          toast.error("Failed to add product to wishlist" ,{ position: toast.POSITION.BOTTOM_RIGHT});
          throw new Error('Failed to add product to wishlist');
        }
        toast.success("Item added in wish list !",{ position: toast.POSITION.BOTTOM_RIGHT});

      }
     
      // Product added to wishlist successfully
      // console.log('Product added to wishlist',JSON.stringify(productId));
    } catch (error) {
      console.error('Error adding product to wishlist:', error);
    }
  };

  return (
    <span onClick={addToWishlist} className='cursor-pointer iconHEart'><i class="fa fa-heart-o" aria-hidden="true"></i></span>

  );
};

export default AddToWishlist;
