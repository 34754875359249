
import React, { useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../assets/css/style.css"
import "../assets/css/bookcontent.css"
import "../assets/css/responsive.css"
import config from '../config/Config';
import AddToWishlist from '../Component/AddToWishlist';





import Category from '../Component/Category';
import { Link, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import QuickViewModal from '../Component/QuickView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { Helmet } from 'react-helmet';
import Head from '../Component/Helmet';


const style = {
    //   position: 'absolute' as 'absolute',
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    //   bgcolor: 'background.paper',
    //   border: '2px solid #000',
    //   boxShadow: 24,
    p: 4,
};



export default function Home() {

    const [cards, setAllCards] = useState([

    ])
    const [value, setValue] = useState();
    const [AuthorAll, setAllAuthor] = useState([])
    const [PublisherAll, setPublisher] = useState([])
    const [ShopkeepersAll, setShopkeepers] = useState([])
    const [open, setOpen] = React.useState(false);
    const [userId, setUserId] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const url = "https://www.booksbea.com";

    const handleWhatsAppShare = () => {
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
        window.open(whatsappUrl, '_blank');
    };

    useEffect(() => {
        // Detailsbook()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });


    }, []

    )
    const handleFacebookShare = () => {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        window.open(facebookUrl, '_blank');
    };

    // const openModal = () => setIsModalOpen(true);
    const openModal = () => {
        console.log("click");
        setIsModalOpen(true)

    }
    const closeModal = () => setIsModalOpen(false);


    const [addPod, setPod] = useState({
        names: "",
        emails: "",
        contact: "",
        description: "",
    });

    const handlePodcast = (e) => {
        const { name, value } = e.target;
        setPod({
            ...addPod,
            [name]: value,
        });
    };
    useEffect(() => {

        const storedUsername = localStorage.getItem('MyToken');
        const parsedUsername = JSON.parse(storedUsername);


        if (parsedUsername) {
            console.log(parsedUsername);

            setUserId(parsedUsername.userId)


        } else {
            console.log('No username found in local storage');
        }
        // AllAddress()

    }, []);


    useEffect(() => {
        // This useEffect depends on userId and calls AllAddress when userId changes and is valid
        if (userId) {


        }
    }, [userId]);

    const AddCart = (item) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "productId": item._id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        fetch(config.api_base_url + `/api/v1/cart/add/${userId}`, requestOptions)
            .then((response) => {
                if (response.status != 201) {
                    alert("Please Fill Valid Details")



                }

                return response.json();
            })
            .then(async (responseData) => {
                alert(responseData.message)
                console.log("RESULTS HEREforData: for", responseData)










                // nav("/");




                // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









            })
            .catch(error => console.log('error', error));


    }



    const handlePhoneChange = (value) => {
        setPod({
            ...addPod,
            contact: value,
        });
    };
    const handleSubmitpod = (e) => {
        e.preventDefault();
        Addpodcast()

        // You can perform actions with the form data here
        console.log('Form Data hddhd:', e);
    };
    const Addpodcast = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "name": addPod.names,
            "email": addPod.emails,
            //   "contact_cc": "+91",
            "contact": addPod.contact,
            "description": addPod.description,
            "query_type": "podcast_request"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };



        fetch(config.api_base_url + "/api/v1/customer_request/create", requestOptions)
            .then((response) => {
                if (response.code != 200) {
                    //   alert("Please Fill Valid Details")


                }

                return response.json();
            })

            .then(async (responseData) => {
                if (responseData.code == 201) {
                    alert(responseData.message)
                    navigate("/Podcast")
                    setPod({
                        name: "",
                        email: "",
                        contact: "",
                        description: "",

                    });


                } else {
                    alert("Please Fill Valid Details")
                }
                console.log("RESULTS all All  details", responseData)

            })
            .catch(error => console.log('error', error));

    }


    const handleCardClick = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    useEffect(() => {
        // Detailsbook()
        AllDetails()

    }, []

    )

    const handleRightClick = (e) => {
        if (e.button === 2) {
            // Right-click event
            e.preventDefault();
            const newWindow = window.open('/Reviews', '_blank');
            if (newWindow) {
                // If the new window opened successfully, you can perform additional actions
                newWindow.opener = null; // Detach the new window from the current window
            }
        }
    };
    //   const Detailsbook=()=>{
    //     var requestOptions = {
    //       method: 'GET',
    //       redirect: 'follow'
    //     };

    //     fetch("http://198.177.123.10:4001/api/v1/books/find", requestOptions)
    //     .then((response) => {
    //       if(response.code != 200){
    //         //   alert("Please Fill Valid Details")


    //       }

    //       return response.json();
    //   })

    //       .then(async (responseData) => {
    //           console.log("RESULTS all booking details", responseData)
    //           setAllCards(responseData.data)



    //           // nav("/");




    //           // console.log("RESULTS HEREforDatatype:", responseData.data[0].typeId)









    //         })
    //       .catch(error => console.log('error', error));
    //   }
    const AllDetails = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(config.api_base_url + "/api/v1/home", requestOptions)
            .then((response) => {
                if (response.code != 200) {
                    //   alert("Please Fill Valid Details")


                }

                return response.json();
            })

            .then(async (responseData) => {
                console.log("RESULTS all All  details", responseData)
                setAllCards(responseData.data.books)
                setAllAuthor(responseData.data.authors)
                setPublisher(responseData.data.publishers)
                setShopkeepers(responseData.data.shopkeepers)
            })
            .catch(error => console.log('error', error));
    }

    const [cat, setAllCat] = useState([
        {
            id: 1
        },
        {
            id: 2
        },
        {
            id: 3
        },
        {
            id: 4
        },

        {
            id: 5
        },




    ])

    // const truncateText = (text, wordCount) => {
    //     const words = text.split(' ');
    //     const truncatedWords = words.slice(0, wordCount);
    //     return truncatedWords.join(' ');
    //   };
    const truncateText = (text, wordCount) => {
        const words = text.split(' ');

        if (words.length > wordCount) {
            const truncatedWords = words.slice(0, wordCount);
            return `${truncatedWords.join(' ')} ...`;
        } else {

            return text;
        }
    };


    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            // <FontAwesomeIcon
            //     icon={faChevronRight}
            //     className={`${className} custom-arrow`} // Add a custom class for additional styling
            //     style={{ ...style, display: "block" }}
            //     onClick={onClick}
            // />
            <div className='custom-arrow'>
                <i class="fa fa-chevron-right " aria-hidden="true" style={{ ...style, display: "block" }}
                    onClick={onClick}></i>
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div className='custom-arrow1'>
                <i class="fa fa-chevron-left " aria-hidden="true" style={{ ...style, display: "block" }}
                    onClick={onClick}></i>
            </div>
        );
    }


    function SamplePrevArrow1(props) {
        const { className, style, onClick } = props;
        return (
            <div className='custom-arrow2' onClick={onClick}>
                <i class="fa fa-chevron-left " aria-hidden="true" style={{ ...style, display: "block" }}
                ></i>
            </div>
        );
    }
    function SampleNextArrow1(props) {
        const { className, style, onClick } = props;
        return (
            // <FontAwesomeIcon
            //     icon={faChevronRight}
            //     className={`${className} custom-arrow`} // Add a custom class for additional styling
            //     style={{ ...style, display: "block" }}
            //     onClick={onClick}
            // />
            <div className='custom-arrow3' onClick={onClick}>
                <i class="fa fa-chevron-right " aria-hidden="true" style={{ ...style, display: "block" }}
                ></i>
            </div>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        focus: false,
        pauseOnHover: true,
        arrow: true,

        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        focus: false,
        pauseOnHover: true,
        arrow: false,

        prevArrow: <SamplePrevArrow1 />,
        nextArrow: <SampleNextArrow1 />,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    var settings7 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        focus: false,
        pauseOnHover: true,
        arrow: false,
        nextArrow: <SampleNextArrow1 />,
        prevArrow: <SamplePrevArrow1 />,


        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    var settings3 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        focus: false,
        pauseOnHover: true,
        arrow: false,


        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    var settings8 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        focus: false,
        pauseOnHover: true,
        arrow: false,
        nextArrow: <SampleNextArrow1 />,
        prevArrow: <SamplePrevArrow1 />,


        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    const navigate = useNavigate();
    const Detailsset = (item) => {
        console.log(item)
        if(!item.metaTitle || item.metaTitle === "") {
          navigate(`/books/details/${item._id}`, { state: item });
        } else {
          navigate(`/books/details/${item.metaTitle}`, { state: item });
        }
    }

    // const AuthoreDetails = async (item) => {
    //     const { id, firstName } = item;
    //     console.log(item, "ItemGet")
    //     const urlFriendlyAuthorName = encodeURIComponent(item.authorDetails.lastName).replace(/%20/g, '-');

    //     navigate(`/authors/${item.authorDetails.firstName}${urlFriendlyAuthorName}`, { state: item });

    //     // navigate(`/authors/${item.id}`, { state: item});



    // }
    const AuthoreDetails = async (item) => {
        const { userId, authorDetails } = item;
        console.log(item, "ItemGet")
        //    const urlFriendlyAuthorName = encodeURIComponent(item.authorDetails.lastName).replace(/%20/g, '-');
        const urlFriendlyAuthorName = `${encodeURIComponent(item.authorDetails.firstName)}-${encodeURIComponent(item.authorDetails.lastName)}`;

        //  navigate(`/authors/${item.authorDetails.firstName}${urlFriendlyAuthorName}`, { state: item });

        const lowercaseAuthorName = urlFriendlyAuthorName.toLowerCase();
        // const url = `/authors/${encodeURIComponent(authorDetails.firstName)}-${encodeURIComponent(authorDetails.lastName)}-${userId}`;
        console.log(urlFriendlyAuthorName, "urlFriendlyAuthorName")

        // Use the `navigate` function to redirect to the lowercase URL
        let dynamicUserName = `${lowercaseAuthorName}-${userId}`
        navigate(`/authors/${dynamicUserName}`);
        //  navigate(`/authors/${urlFriendlyAuthorName}`, { state: item });

        // navigate(`/authors/${item.id}`, { state: item});



    }
    const PublisherDetails = (item) => {
        console.log(item, "ItemGet")
        // const urlFriendlyAuthorName = encodeURIComponent(item.publisherDetails.lastName).replace(/%20/g, '-');
        // const lowercaseAuthorName = urlFriendlyAuthorName.toLowerCase();
        const urlFriendlyAuthorName = `${encodeURIComponent(item.publisherDetails.firstName)}-${encodeURIComponent(item.publisherDetails.lastName)}`;

        //  navigate(`/authors/${item.authorDetails.firstName}${urlFriendlyAuthorName}`, { state: item });

        const lowercaseAuthorName = urlFriendlyAuthorName.toLowerCase();

        let dynamicUserName = `${lowercaseAuthorName}-${item.userId}`
        navigate(`/publishers/${dynamicUserName}`);
        // navigate(`/publisher/${lowercaseAuthorName}/${item.userId}`);



    }

    const SellereDetails = async (item) => {
        const { userId, shopDetails } = item;
        console.log(item, "ItemGet")
           const urlFriendlyAuthorName = encodeURIComponent(item.shopDetails.shopName).replace(/%20/g, '-');
        // const urlFriendlyAuthorName = `${encodeURIComponent(item.shopDetails.shopName)}`;

        //  navigate(`/authors/${item.authorDetails.firstName}${urlFriendlyAuthorName}`, { state: item });

        const lowercaseAuthorName = urlFriendlyAuthorName.toLowerCase();
        // const url = `/authors/${encodeURIComponent(authorDetails.firstName)}-${encodeURIComponent(authorDetails.lastName)}-${userId}`;
        console.log(urlFriendlyAuthorName, "urlFriendlyAuthorName")

        // Use the `navigate` function to redirect to the lowercase URL
        let dynamicUserName = `${lowercaseAuthorName}-${userId}`
        navigate(`/book-stores/${dynamicUserName}`);
        //  navigate(`/authors/${urlFriendlyAuthorName}`, { state: item });

        // navigate(`/authors/${item.id}`, { state: item});



    }

    return (
        <div>
            <div className='first-sec'>
                {/* <Helmet>
        <title>My Page Title</title>
        <meta name="description" content="Description of my page" />

      </Helmet> */}



                <Slider {...settings}>
                    <div>
                        <section class="sider-sec ">

                            <div class="container-fluid">
                                <div class="row">

                                    <div class="col-sm-12-col-md-12 col-xl-4 ">
                                        <div class="slider-1">
                                            <h3>
                                                Sell Your  Books

                                            </h3>
                                            <div class=" sell-heading mt-4">
                                                <h6 class="mt-3 fw-bold">
                                                    Reach Your Targeted Audience
                                                </h6>
                                            </div>
                                            <div class="mt-5 sell-paragraph">
                                                <p>
                                                    Elevate your book-selling journey with our platform,
                                                    where Book-sellers, Publishers and Authors converge to showcase and sell their books to a global
                                                    audience. Join the literary revolution and turn pages into profits!
                                                </p>
                                            </div>
                                            <div class="mt-5 btn-set">
                                                <Link to='/Signin'>Sell Books</Link>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-sm-12-col-md-12 col-xl-8 ">

                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>
                    <div class="carousel-item">
                        <section class="sider-sec3  ">

                            <div class="container-fluid">
                                <div class="row">

                                    <div class="col-sm-12-col-md-12 col-xl-4 ">
                                        <div class="slider-1">
                                            <h3>
                                                Promote Books

                                            </h3>
                                            <div class=" sell-heading mt-4">
                                                <h6 class="mt-3 fw-bold">
                                                    Tailored Online Promotion


                                                </h6>
                                            </div>
                                            <div class="mt-5 sell-paragraph">
                                                <p>
                                                    Elevate your book's visibility with our dynamic promotion strategies,,
                                                    ensuring that your story shines in the digital spotlight.Unleash the potential
                                                    of your words with our dedicated platform, where promotion meets passion.

                                                </p>

                                            </div>

                                            <div class="m t-5 btn-set">
                                                <Link to='/Signin'>Sell Books</Link>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="col-sm-12-col-md-12 col-xl-8 ">

                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>
                    <div class="carousel-item">
                        <section class="sider-sec1 ">

                            <div class="container-fluid">
                                <div class="row">

                                    <div class="col-sm-12-col-md-12 col-xl-4 ">
                                        <div class="slider-1">
                                            <h3>

                                                Reader's Jackpot

                                            </h3>
                                            <div class=" sell-heading mt-4 newWidth">
                                                <h6 class="mt-3 fw-bold">
                                                    Pre Launch Sale for First 1000 users Only!


                                                </h6>
                                            </div>
                                            <div class="mt-5 sell-paragraph">
                                                <p>
                                                    Become a Premium Reader before December 31, 2023, and unlock a treasure trove of words with three years of boundless
                                                    access to online books, ebooks, and audiobooks. But that's not all – enjoy the added perk of
                                                    a delightful 20% discount on all offline book orders. Seize the opportunity to immerse
                                                    yourself in a world of stories, wisdom, and savings – because your reading journey deserves nothing less!"
                                                </p>

                                            </div>

                                            <div class="mt-5 btn-set">
                                                <Link to='/Signin'>Sell Books</Link>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="col-sm-12-col-md-12 col-xl-8 ">

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slider>
            </div>


            {/* <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <section class="sider-sec ">

                        <div class="container-fluid">
                            <div class="row">

                                <div class="col-sm-12-col-md-12 col-xl-4 ">
                                    <div class="slider-1">
                                        <h3>
                                            <span>S</span>ell Your <br /> Books

                                        </h3>
                                        <div class=" sell-heading mt-4">
                                            <h6 class="mt-3 fw-bold">
                                                Reach Your Targeted Audience
                                            </h6>
                                        </div>
                                        <div class="mt-5 sell-paragraph">
                                            <p>
                                                Elevate your book-selling journey with our platform,
                                                where Book-sellers, Publishers and Authors converge to showcase and sell their books to a global
                                                audience. Join the literary revolution and turn pages into profits!
                                            </p>
                                    </div>
                                        <div  class="mt-5 btn-set">
                                        <a>Sell Books</a>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-12-col-md-12 col-xl-8 ">

                                </div>
                            </div>

                        </div>
                    </section>
                </div>
                <div class="carousel-item">
                    <section class="sider-sec3  ">

                        <div class="container-fluid">
                            <div class="row">

                                <div class="col-sm-12-col-md-12 col-xl-4 ">
                                    <div class="slider-1">
                                        <h3>
                                            <span>P</span>romote <br /> Books

                                        </h3>
                                        <div class=" sell-heading mt-4">
                                            <h6 class="mt-3 fw-bold">
                                                Tailored Online Promotion


                                            </h6>
                                        </div>
                                        <div class="mt-5 sell-paragraph">
                                            <p>
                                                Elevate your book's visibility with our dynamic promotion strategies,,
                                                ensuring that your story shines in the digital spotlight.Unleash the potential
                                                of your words with our dedicated platform, where promotion meets passion.

                                            </p>

                                        </div>

                                        <div class="mt-5 btn-set">
                                            <a>Sell Books</a>
                                        </div>

                                    </div>

                                </div>
                                <div class="col-sm-12-col-md-12 col-xl-8 ">

                                </div>
                            </div>

                        </div>
                    </section>
                </div>
                <div class="carousel-item">
                    <section class="sider-sec1 ">

                        <div class="container-fluid">
                            <div class="row">

                                <div class="col-sm-12-col-md-12 col-xl-4 ">
                                    <div class="slider-1">
                                        <h3>

                                            <span>R</span>eader's Jackpot

                                        </h3>
                                        <div class=" sell-heading mt-4">
                                            <h6 class="mt-3 fw-bold">
                                              Pre Launch Sale for First 1000 users Only!


                                            </h6>
                                        </div>
                                        <div class="mt-5 sell-paragraph">
                                            <p>
                                                Become a Premium Reader before December 31, 2023, and unlock a treasure trove of words with three years of boundless
                                                access to online books, ebooks, and audiobooks. But that's not all – enjoy the added perk of
                                                a delightful 20% discount on all offline book orders. Seize the opportunity to immerse
                                                yourself in a world of stories, wisdom, and savings – because your reading journey deserves nothing less!"
                                            </p>

                                        </div>

                                        <div class="mt-5 btn-set">
                                            <a>Buy Books</a>
                                        </div>

                                    </div>

                                </div>
                                <div class="col-sm-12-col-md-12 col-xl-8 ">

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <button class="carousel-control-prev setPREVIOSSLIDER" type="button"
                data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true "></span>
                <div class="set-btn-next">
                    <span class="visually-hidden">Previous</span>
                </div>
            </button>
            <button class="carousel-control-next setPREVIOSSLIDER" type="button"
                data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <div class="set-btn-next">
                    <span class="visually-hidden">Next</span>
                </div>
            </button>
        </div> */}



            {/*
  <Link className='btn btn-danger' to="/Reviews" onContextMenu={handleRightClick}>
        Go to About
      </Link> */}








            <section class="section-2" >
                <div class="container ">
                    <Category />

                    <div class="row mb-4 mt-4">
                        <div class="col-sm-6">


                        </div>
                        <div class="col-sm-6">

                        </div>

                    </div>

                    <div class="">
                        <div class="col-xl-9 col-sm-12">
                            <div class="slider-1">
                                {/* <h3 class=""> <span>F</span>ind Your Books</h3> */}
                            </div>
                        </div>
                        <div class="row ">
                            {/* <div class="col-sm-12 col-md-12 col-xl-1">

                        </div> */}
                            <div className='row set_FIlter mb-3'>
                            <div className='col-sm-4 d-flex align-items-center'>
                                <div className='position-relative'>
                                    <input type='text' className='serach bar ' placeholder='Search..' />
                                    <a><i class="fa fa-search iconsSe" aria-hidden="true"></i></a>
                                </div>

                            </div>
                            <div class="col-sm-12 col-md-12 col-xl-8 mt-2" style={{ paddingRight: "17px" }}>

                                <div class="d-flex justify-content-end set-flex pt-2">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item pe-2" role="presentation">
                                            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-home" type="button" role="tab"
                                                aria-controls="pills-home" aria-selected="true">Most Popular</button>
                                        </li>
                                        <li class="nav-item pe-2" role="presentation">
                                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-profile" type="button" role="tab"
                                                aria-controls="pills-profile" aria-selected="false">New Arrival</button>
                                        </li>
                                        <li class="nav-item pe-2" role="presentation">
                                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-profile" type="button" role="tab"
                                                aria-controls="pills-profile" aria-selected="false">Best Rated</button>
                                        </li>
                                        <li class="nav-item pe-2" role="presentation">
                                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-profile" type="button" role="tab"
                                                aria-controls="pills-profile" aria-selected="false">Recommended</button>
                                        </li>



                                    </ul>
                                </div>
                            </div>
                            </div>
                            <div style={{ gap: "54px" }} class="mt-5 row pro d-flex justify-content-center position-relative">


                                {
                                    cards.map((item, index) => {
                                        return (
                                            <>
                                                <div class="new_arrival_sec">
                                                    <div className='p-2'>
                                                        <div class="book1">
                                                            <div class="book_img">
                                                                <img onClick={() => Detailsset(item)} src={config.api_base_url + `/api/v1/images?fileName=${item.mainImage}`} alt="" />
                                                                {/* <img src={require("../assets/images/book444.webp")} /> */}
                                                                {/* <span className='iconHEart'><i class="fa fa-heart-o" aria-hidden="true"></i></span> */}
                                                                <AddToWishlist  productId={item} />

                                                            </div>
                                                            {/* <div><i class="fa fa-heart" aria-hidden="true"></i></div> */}


                                                            <div class="price_details">
                                                                {/* <div>
                                                <img src={item.icon}  className='iconClass'/>
                                            </div> */}
                                                                <div className='d-flex mb-2'>
                                                                    <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>                                            <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                                                    <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                                                    <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                                                                    <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>

                                                                </div>
                                                                <h4>Rs. {item.listingPrice}</h4>
                                                                <h2>Rs. {item.price}</h2>

                                                                <a className='text-white' onClick={() => Detailsset(item)} >

                                                                    Buy Now
                                                                </a>
                                                            </div>

                                                        </div>



                                                        <div class="book_details mt-3 ">
                                                            {/* <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
  Tooltip on top
</button> */}
                                                            <div class="book_name">
                                                                <Tippy
                                                                    content={`${item.title}`}
                                                                    placement="top"
                                                                    arrow={true}
                                                                    animation="scale"
                                                                    duration={200}
                                                                    style={{
                                                                        background: '#0F7FC2',
                                                                        color: '#ffffff',
                                                                        fontFamily: 'Montserrat, sans-serif',
                                                                        // Add more styles as needed
                                                                    }}
                                                                >
                                                                    <p className='mb-0' onClick={() => Detailsset(item)}>
                                                                        {truncateText(item.title, 4)}
                                                                    </p>
                                                                </Tippy>

                                                                {/* <p className='mb-0 '  onClick={() => Detailsset(item)} data-toggle="tooltip" data-placement="top" title="Tooltip on top">       {truncateText(item.title, 4)}</p> */}
                                                                {item.authorId == "-1" ? <p className='mt-0'  >By: {item.authorDetails.firstName} {item.authorDetails.lastName} </p> :

                                                                    <p className='mt-0' onClick={() => AuthoreDetails(item)}>By: {item.authorDetails.firstName} {item.authorDetails.lastName} </p>}

                                                            </div><div class="book_icon">
                                                                {/* <span><i class="fa fa-heart" aria-hidden="true"></i></span> */}
                                                                {item.eBook == "0" ? <span><i class="fa fa-book" aria-hidden="true"></i> <i class="fa fa-ban newDisable" aria-hidden="true"></i></span> : <span><i class="fa fa-book" aria-hidden="true"></i> </span>}
                                                                {/* <span><i class="fa fa-book" aria-hidden="true"></i> <i class="fa fa-ban newDisable" aria-hidden="true"></i></span> */}

                                                                {
                                                                    item.audioBook == "0" ? <span><i class="fa fa-microphone-slash" aria-hidden="true"></i></span>

                                                                        :
                                                                        <span><i class="fa fa-microphone" aria-hidden="true"></i></span>}

                                                                <span onClick={() => handleCardClick(item)}><i class="fa fa-eye" aria-hidden="true"></i></span>
                                                                <span onClick={() => AddCart(item)}><i class="fa fa-shopping-cart" aria-hidden="true"></i></span>
                                                                <span onClick={openModal}><i class="fa fa-share-alt" aria-hidden="true"></i></span>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </>
                                        )
                                    }

                                    )
                                }

                                <QuickViewModal
                                    show={showModal}
                                    onHide={() => setShowModal(false)}
                                    item={selectedItem}
                                />




                                {isModalOpen && (
                                    <div className='newwws'>

                                        <div className="popup">
                                            <header>
                                                <span>Share </span>
                                                <div className="close" onClick={closeModal}><i className="fa fa-close"></i></div>
                                            </header>
                                            <div className="content1">
                                                <p>Share this link via</p>
                                                <ul className="icons ps-0 justify-content-start">
                                                    <a onClick={handleFacebookShare}><i className="fa fa-facebook"></i></a>
                                                    <a className='ms-3' onClick={handleWhatsAppShare}><i className="fa fa-whatsapp"></i></a>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}



                                <div className='d-flex justify-content-center ViewButton'>
                                    <Link to={"/books"} className='fw-bold text-dark'>View More</Link>
                                </div>


                                {/* <button onClick={openModal}>yycnn</button> */}






                            </div>


                        </div>


                    </div>
                </div>


            </section>



            <section class="section-22" style={{ background: "white" }}>

                <div class="container">


                    <div class="row">
                        <div class="col-sm-12">
                            <div class="newBox">
                                <div class="firstCard">
                                    <div class="icons_call"> <i class="fa fa-phone" aria-hidden="true"></i></div>
                                    <div>
                                        <h6> Active Customer Support</h6>
                                        <p class="mb-0 text-white">   Call:+1-408-755-1607 </p>
                                    </div>

                                </div>
                                <div class="firstCard">
                                    <div class="icons_call"><i class="fa fa-plane" aria-hidden="true" style={{ rotate: "307deg" }}></i></div>
                                    <div>
                                        <h6>Track Your Orders Online</h6>
                                        <p class="mb-0 text-white">Click Here To Track Orders</p>
                                    </div>
                                </div>
                                <div class="firstCard">
                                    <div class="icons_call"><i class="fa fa-inr" aria-hidden="true"></i></div>
                                    <div>
                                        <h6>Books On Rent</h6>
                                        <p class="mb-0 text-white">Books On Rent are available Online </p>
                                    </div>

                                </div>



                            </div>

                        </div>


                        <div class="col-sm-12 col-md-12 col-xl-4">
                            <Link to={"/books/shop"}>
                                <div class="card-bg">
                                    <div class="shop-content">
                                        <div class="newww_card_tag">
                                            {/* <!--<h6>SHOP CATEGORY</h6>--> */}
                                            <h3>BOOKS</h3>
                                            <p class="fw-bold">SHOP NOW <span class="me-1"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div class="col-sm-12 col-md-12 col-xl-4">
                            <Link onClick={() => handleOpen()}>
                                <div class="card-bg1">
                                    <div class="shop-content">
                                        <div class="newww_card_tag">
                                            {/* <!--<h6>SHOP CATEGORY</h6>--> */}
                                            <h3>Book Your Podcast</h3>
                                            <p class="fw-bold" >SHOP NOW<span class="me-3"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></p>
                                        </div>
                                    </div>

                                </div>
                            </Link>


                        </div>
                        <div class="col-sm-12 col-md-12 col-xl-4">
                            <Link to={"/ebook"}>
                                <div class="card-bg2">
                                    <div class="shop-content">
                                        <div class="newww_card_tag">
                                            {/* <!--<h6>SHOP CATEGORY</h6>--> */}
                                            <h3>E-BOOKS</h3>
                                            <p class="fw-bold">SHOP NOW <span class="me-3"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></p>
                                        </div>
                                    </div>
                                </div>
                            </Link>


                        </div>
                    </div>

                </div>

            </section>

            <section class="section-2" style={{ background: "white" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-center">
                            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
                                                <div class="slider-txt">
                                                    <h2 style={{ fontFamily: "Cinzel Decorative, cursive", color: "black" }}>
                                                        Podcast-Increase Your Fanbase
                                                    </h2>
                                                    <p>At Booksbea, we believe in championing authors and their literary journeys. <br /> Picture this: a captivating spotlight on you
                                                        and your work, reaching readers hungry for inspiration. We offer a unique opportunity for authors to share their stories,
                                                        ideas, and the passion behind their creations through complimentary interviews. Whether you're launching a new book or
                                                        simply want to connect with your audience on a deeper level, our platform is your stage. Join
                                                        us in the celebration of words, where your narrative takes center stage, and let the world
                                                        discover the magic within your stories – because your voice deserves to be heard." <br /><br />
                                                        "Calling all visionaries and trailblazers! Elevate your business narrative and inspire a global audience by joining our podcast category. We're dedicated to showcasing the journeys of passionate business owners and entrepreneurs, providing a platform for you to share your unique stories, triumphs, and insights. Let your voice resonate in the digital realm, connecting with like-minded individuals and enthusiasts eager to learn from your experiences. Together, let's celebrate the spirit of entrepreneurship and turn your story into an empowering symphony for the business community to tune into on our website."
                                                    </p>
                                                    <div class="mb-3 btn-set">
                                                        {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Open modal for @mdo</button> */}

                                                        <Link onClick={() => handleOpen()}>Request for Podcast</Link>

                                                        <Modal
                                                            keepMounted
                                                            open={open}
                                                            onClose={handleClose}
                                                            aria-labelledby="keep-mounted-modal-title"
                                                            aria-describedby="keep-mounted-modal-description"
                                                        >
                                                            <Box sx={style}>
                                                                <section className='secVg newForm'>

                                                                    <form className="form" onSubmit={handleSubmitpod}>
                                                                        <h2>Book Your Podcast</h2>
                                                                        <p type="Name:">
                                                                            <input
                                                                                placeholder="Write your name here.."
                                                                                name="names"
                                                                                value={addPod.names}
                                                                                onChange={handlePodcast}
                                                                            />
                                                                        </p>
                                                                        <p type="Phone:">
                                                                            <PhoneInput
                                                                                international
                                                                                defaultCountry="IN"
                                                                                placeholder="Write your phone here"
                                                                                value={addPod.contact}
                                                                                onChange={handlePhoneChange}
                                                                            />
                                                                        </p>
                                                                        <p type="Email:">
                                                                            <input
                                                                                placeholder="Write your email id..."
                                                                                name="emails"
                                                                                value={addPod.emails}
                                                                                onChange={handlePodcast}
                                                                            />
                                                                        </p>
                                                                        <p type="Message:">
                                                                            <input
                                                                                placeholder="What would you like to tell us.."
                                                                                name="description"
                                                                                value={addPod.description}
                                                                                onChange={handlePodcast}
                                                                            />
                                                                        </p>
                                                                        <div className='d-flex justify-content-end mb-3 mt-2'>
                                                                            <button className='float-right'>Send Message</button>
                                                                        </div>
                                                                    </form>

                                                                </section>

                                                            </Box>
                                                        </Modal>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div class="col-xl-6 col-sm-12 col-md-12 d-flex justify-content-end ">
                            <div class="position-relative set-read">
                                <div class="position-absolute bg-white Reed">
                                    <a style={{ cursor: "pointer" }}>Podcast- Share Your Journey</a>
                                </div>
                                <img src={require("../assets/images/prodcast.jpg")} style={{ height: "580px", width: "100%" }} />

                            </div>


                        </div>
                    </div>

                </div>


            </section>

            <section class="sec-1">
                <div class="container w-100">
                    <div class="d-flex justify-content-between">

                        <h2 style={{ fontFamily: "Cinzel Decorative, cursive", color: "black" }}>
                            Top Authors
                        </h2>
                        <div class="d-flex justify-content-end">

                            <Link to={'/authors'} className="Newss" onclick="fireDynamicPropSeeAllGTM(event,'Fresh properties see all','freshProp');seeAppPropertiesOpenUrl('freshProp');">See all <span> <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
                            </Link>
                        </div>

                    </div>
                    <Slider {...settings2}>
                        {
                            AuthorAll.map((item, index) => {
                                return (
                                    <>
                                        <div className='slide1' >
                                            {/* <Link target="_blank" to="/SingleAuthore" className='text-dark'> */}
                                            <div class="review-container cursor-pointer" onClick={() => AuthoreDetails(item)}>
                                                <div class="img-container">
                                                    <img class="review-image" src={item.authorDetails.profileImage != null && item.authorDetails.profileImage != "" ? config.api_base_url + `/api/v1/images?fileName=${item.authorDetails.profileImage}` : `https://cdn-icons-png.flaticon.com/512/4791/4791601.png`} alt="" />
                                                </div>

                                                <h2 class="review-name" id="name">{item.authorDetails.firstName} {item.authorDetails.lastName}</h2>
                                                {/* <h3>{console.log(item.authorDetails)}</h3> */}
                                                <h4 class="review-job" id="job"></h4>
                                                <button className='buttonKnow' onClick={() => AuthoreDetails(item)}> <a>Know Me</a> </button>
                                                {/* <div class="btn-container">
                                                    <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                </div> */}
                                                {/* <p class="review-text" id="text">{item.authorDetails.aboutUser}</p> */}


                                            </div>
                                            {/* </Link> */}
                                        </div></>


                                )
                            }

                            )
                        }


                    </Slider>

                    {/* <div class="swiper-container">
        <div class="swiper-wrapper">















        </div>

    </div> */}

                    {/* <div class="w-100 d-flex justify-content-between align-items-center " style="position: relative;
        top: -140px;">
                  <div class="swiper-button-prev"><i class="fa-solid fa-chevron-left"></i></div>
        <div class="swiper-button-next"><i class="fa-solid fa-chevron-right"></i></div>
             </div> */}


                </div>
            </section>
            <section class="section-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-xl-6">

                        </div>
                        <div class="col-sm-12 col-md-12 col-xl-6">
                            <h6 class="set-a-head fw-bold text-center BookFOnt">
                                NEW BOOK LAUNCH


                            </h6>
                            <div class="set-section-title mb-3 d-flex justify-content-center">
                                <h3 class="text-center"><span>T</span>he Name of Wind</h3>

                            </div>

                            <h6 class="set-a-head fw-bold text-center">
                                23/FEB/2024


                            </h6>
                            <div class="set-btn-read ">
                                <a class="">
                                    Read a Sample
                                </a>
                            </div>



                        </div>
                    </div>

                </div>

            </section>
            <section className='ShopSection'>

                <div className='container'>
                    <div class="d-flex justify-content-between">
                        <h2 style={{ fontFamily: "Cinzel Decorative, cursive", color: "black" }}>
                            Book Stores
                        </h2>
                        <div class="d-flex justify-content-end">
                            <Link to="/book-stores" className="Newss" onclick="fireDynamicPropSeeAllGTM(event,'Fresh properties see all','freshProp');seeAppPropertiesOpenUrl('freshProp');">See all <span> <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
                            </Link>
                        </div>
                    </div>
                    <div className='row'>
                        <Slider {...settings8}>
                            {
                                ShopkeepersAll.map((item, index) => {
                                    return (
                                        <>
                                            <div className='slider144'>
                                                <div class="product_items">
                                                    <article class="single_product">
                                                        <figure className='p-1'>
                                                            <div class="product_thumb">
                                                                <a class="primary_img" href="" onClick={() => SellereDetails(item)}>
                                                                    <img class="review-image" src={item.shopkeeperDetails.profileImage != null && item.shopkeeperDetails.profileImage != "" ? config.api_base_url + `/api/v1/images?fileName=${item.shopkeeperDetails.profileImage}` : `https://cdn-icons-png.flaticon.com/512/4791/4791601.png`} alt="" />
                                                                </a>

                                                            </div>
                                                            <figcaption class="product_content">
                                                                <h4 class="product_name" onClick={() => SellereDetails(item)}><a href="">{item.shopDetails.shopName} </a>
                                                                </h4>
                                                            </figcaption>
                                                        </figure>
                                                    </article>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </section>



            <section class="working-process sp-two">
                <div class="container">
                    {/* <!--Sec Title--> */}
                    <div class="sec-title centered">
                        <h1><span style={{ color: "black" }}>H</span>ow to Sell Book</h1>
                    </div>
                    <div class="working-process-step">
                        <div class="row">
                            {/* <!--Process Block--> */}
                            <div class="process-block col-md-4">
                                <div class="inner-box">
                                    <div class="count-box">
                                        <div class="count">01</div>
                                    </div>
                                    <h3>Signup Your Seller Account</h3>
                                    <div class="text">Register today and become a part of our vibrant community as a book seller, publisher, or author.</div>
                                </div>
                            </div>
                            {/* <!--Process Block--> */}
                            <div class="process-block col-md-4">
                                <div class="inner-box float-bob-y">
                                    <div class="count-box">
                                        <div class="count">02</div>
                                    </div>
                                    <h3>Uploads Your Book Details</h3>
                                    <div class="text">Easily upload your book details, set your pricing to showcase your masterpiece in our virtual shop.</div>
                                </div>
                            </div>
                            {/* <!--Process Block--> */}
                            <div class="process-block col-md-4">
                                <div class="inner-box">
                                    <div class="count-box">
                                        <div class="count">03</div>
                                    </div>
                                    <h3>Just Start Sharing Your Shoplink</h3>
                                    <div class="text">Your Booksbea virtual shop Link showcase all our books in one centralized location.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 btn-set d-flex justify-content-center">

                        <Link to={"/Signup"} >Join As Seller</Link>

                    </div>
                </div>
            </section>
            {/* <section class="sec-4">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-xl-6">
                        <div class="d-flex justify-content-center">
                            <img src={require("../assets/images/bg-book1.png")}
                            style={{boxShadow:"0 3px 10px rgb(0 0 0 / 0.2)",border:"2px solid white",height:"600px"}}
                      />
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-xl-6">
                        <div class=" sec-6">
                            <h3 style={{fontFamily:"Cinzel Decorative, cursive",color:"#0f7fc2"}}><span style={{color:"red"}}>H</span>ow To Sell Book Online ?</h3>

                                    <ol class="ol-cards">
    <li
    style={{accentColor:"#EE5830"}}
    >
        <div class="icon"><i class="fa fa-user" aria-hidden="true"></i></div>
        <div class="title">Signup Your Seller Account</div>
        <div class="descr">Register today and become a part of our vibrant community as a book seller, publisher, or author.</div>
    </li>
    <li  style={{accentColor:"#FAAF47"}}>
        <div class="icon"><i class="fa fa-book" aria-hidden="true"></i></div>
        <div class="title">Uploads Your Book Details</div>
        <div class="descr">Easily upload your book details, set your pricing to showcase your masterpiece in our virtual shop.</div>
    </li>
    <li  style={{accentColor:"#4CBEB8"}}>
        <div class="icon"><i class="fa fa-share-alt"></i></div>
        <div class="title">Just Start Sharing Your Shoplink</div>
        <div class="descr">Your Booksbea virtual shop Link showcase all our books in one centralized location.</div>
    </li>
</ol>

                        </div>
                        <div class="mt-5">

                        </div>
                    </div>
                </div>
            </div>
        </section> */}
            <section class="sec-1">
                <div class="container w-100">
                    <div class="d-flex justify-content-between">

                        <h2 style={{ fontFamily: "Cinzel Decorative, cursive", color: "#000" }}>
                            <span style={{ color: "#000" }}>T</span>op Publisher
                        </h2>
                        <div class="d-flex justify-content-end">

                            <Link to="/publishers" className="Newss" onclick="fireDynamicPropSeeAllGTM(event,'Fresh properties see all','freshProp');seeAppPropertiesOpenUrl('freshProp');">See all <span> <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
                            </Link>
                        </div>

                    </div>


                    <div class="swiper-container">
                        <div class="swiper-wrapper swiper-wrapper2" >

                            <div class="row main_swiper_container publisher_container">
                                <Slider {...settings7}>
                                    {
                                        PublisherAll.map((item, index) => {
                                            return (
                                                <>

                                                    <div className=' slider12'>

                                                        <div class="swiper-slide cursor-pointor" onClick={() => PublisherDetails(item)}>


                                                            <div class="img">
                                                                <img src={item.publisherDetails.profileImage != null && item.publisherDetails.profileImage != "" ? config.api_base_url + `/api/v1/images?fileName=${item.publisherDetails.profileImage}` : `https://thumbs.dreamstime.com/b/corrupted-file-document-icon-linear-simple-element-illustration-bad-concept-outline-symbol-design-vector-logo-can-be-used-web-134295411.jpg`} alt="" />

                                                            </div>
                                                            <div className='setPublisher'>
                                                                <div class="  fw-semibold">{item.publisherDetails.publicationName}</div>
                                                                {/* <div class="mt-1;"><span>{item.publisherDetails.aboutUserss}</span>

                                                                </div> */}
                                                            </div>


                                                        </div>

                                                    </div>

                                                </>
                                            )
                                        }

                                        )
                                    }


                                </Slider>

                            </div>


                        </div>

                    </div>




                </div>
            </section>




            {/* <section class="section-mobile">
            <div class="">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 d-flex align-items-center justify-content-center ">
                            <div class="">
                                <div class="paragraph-mobile
">
                                    <p>The Booksbea App- COMING SOON</p>
                                    <h4>Make your online Books shop <br/> easier with our<span> mobile app</span></h4>
                                </div>

                                <div class="setIcon d-flex justify-content-between mt-1" style={{width:"inherit",height:"inherit"}}>
                                  <div class="d-flex">
                                       <img class="me-2" src={require("../assets/images/app-store-btn.png")} width="50%" />
                                    <img src={require("../assets/images/play-store-btn.png")} width="50%" />
                                  </div>

                                </div>
                            </div>

                        </div>
                        <div class="col-sm-6 d-flex justify-content-center">
                          <img  src="https://booksbea.com/public/assets/images/mack.webp" width="50%" />

                        </div>

                    </div>

                </div>


            </div>



        </section> */}


            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">New message</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="mb-3">
                                    <label for="recipient-name" class="col-form-label">Name</label>
                                    <input type="text" class="form-control" id="name" />
                                </div>
                                <div class="mb-3">
                                    <label for="recipient-name" class="col-form-label">Email</label>
                                    <input type="email" class="form-control" id="Email" />
                                </div>
                                <div class="mb-3">
                                    <label for="recipient-name" class="col-form-label">Phone</label>
                                    <input type="number" class="form-control" id="Phone" />
                                </div>
                                <div class="mb-3">
                                    <label for="message-text" class="col-form-label">Message:</label>
                                    <textarea class="form-control" id="message-text"></textarea>
                                </div>
                            </form>
                        </div>

                        {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                        <button type="button" class="btn btn-primary" style={{ background: "#0F7FC2 !important" }}>Send message</button>

                    </div>
                </div>
            </div>


            <Head
                title="Sell books online l Read books online l Get Books at your doorstep"
                description="Booksbea brings you the best platform to sell  books online.You can sell all types of books available for user for online and offline reading both.Very good platform for authors,book stores and publisher to sell books online"
                image="https://i.imgur.com/fCHtXg3.png"
                url="https://www.booksbea.com"
            />

        </div>
    )
}

