import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Category from './Component/Category';
import Login from './Screen/Login';
import Detailspage from './Screen/Detailspage';
import { BrowserRouter } from 'react-router-dom';
import Authorepage from './Screen/Authorepage';
import SingleAuthore from './Screen/SingleAuthore';
import Prodcast from './Screen/Prodcast';
import Checkout from './Screen/Checkout';
import ImageGallery from './Screen/check';
import CountryDropdown from './Screen/check';
import ReviewPage from './Screen/ReviewPage';
import SinglePage from './Screen/SingleReviewPage';
import Subscription from './Screen/Subscription';
import AllBooks from './Screen/AllBooks';
import AllSeller from './Screen/AllSeller';
import Navigation from './Component/NavigationBottom';
import Sign from './Screen/Signin';
import Register from './Screen/Signup';
import AddProduct from './SellerDashboard/AddBook';
import KeepMountedModal from './Component/Podcastmodal';
import DropdownMenu from './Component/ProfileUpdate';
import MyAccount from './SellerDashboard/MyAccount';
import PaymentComponent from './Component/Payment';
import Openpayment from './Component/Openpayment';
import RajorPayTest from './Component/RajorPayTest';
import Addresses from './Screen/Addresses';
import Order from './Component/Order';
import ManageOrder from './SellerDashboard/ManageOrder';
import ManageBook from './SellerDashboard/ManageBook';
import AddressList from './Component/TestAddress';
// import Test from './Component/TestEbook';
import SelectComponent from './Component/TestSpiner';
// import Test from './Component/Test';
// import TextTing from './Component/Test';
import ShareButton from './Component/hhh';
import CheckoutComponent from './Component/Test12';
import CheckoutComponent1 from './Component/Test13';
import SubscriptionTest from './Component/Test13';
import YourComponent from './Component/Test13';
import ShoppingCart from './Component/Test13';
import ContactUs from './Screen/Contactus';
import Account from './Screen/Account';
import VideoReviewsCarousel from './Component/NewVideo';
import BlogSection from './Screen/blogs';
import PdfReader from './Screen/check';
// import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<BrowserRouter>
  {/* <DropdownMenu /> */}
  <App />
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
