import React, { useState } from 'react';
import '../assets/css/style.css';
import RenderRazorpay from '../Component/PaymentGatweay';
import { useEffect } from 'react';
import config from '../config/Config';


const Subscription = () => {
  const [amounts, setAmout] = useState('');
  const [orderId, setOrderId] = useState('');
  const [currency, setCurrency] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('INR');
  // const [subscriptionType, setSubscriptionType] = useState('monthly');
  const [isYearlySubscription, setIsYearlySubscription] = useState(false);
  const [SubCard, setSub] = useState([]);
  const [userId, setUserId] = useState("")

  useEffect(() => {
    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);
    if (parsedUsername) {
      setUserId(JSON.stringify(parsedUsername.userId));
    } else {
      console.log('No username found in local storage');
    }
  }, []);

  useEffect(() => {
    Plans()
  }, [])
  useEffect(()=>{
    // Detailsbook()
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });


  },[]

  )



  const Plans = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(config.api_base_url + "/api/v1/plan/list", requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          alert('Please Fill Valid Details');
        }
        return response.json();
      })
      .then(async (responseData) => {
        // console.log('Profile Details==>>>data', responseData.data);
        setSub(responseData.data)

      })
      .catch(error => console.log('error', error));
  }


  const [exchangeRate, setExchangeRate] = useState(1); // Default to 1

useEffect(() => {
  fetchExchangeRate();
}, []);

const fetchExchangeRate = async () => {
  try {
    const response = await fetch('https://api.exchangerate-api.com/v4/latest/INR');
    const data = await response.json();
    setExchangeRate(data.rates.USD);
  } catch (error) {
    console.error('Error fetching exchange rate:', error);
  }
};


  const HandleItem = async (i) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    var raw = JSON.stringify({
      "id": JSON.stringify(i.id),
      "userId": userId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(config.api_base_url + '/api/v1/subscription', requestOptions)
      .then((response) => {
        if (response.status !== 201) {
          alert('Please Fill Valid Details');
        }
        return response.json();
      })
      .then(async (responseData) => {
        console.log('Profile Details==>>>data', responseData);
        window.open(responseData.data.short_url, "_blank");
      })
      .catch((error) => console.log('error', error));
  };
  return (
    <section className="mt-5 mb-3 setSetSub" >
      <div className="container-fluid">
        <div className="row">
          <div class="wrapperMain">
            <main>
              <div id="main-container">
                <section class="header">
                  <div   >
                    <h2 className='setPl'>Plans</h2>
                    <select className='form-control' value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)}>
                      <option value="INR">INR</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>
                  <p>
                    <label className="switch">
                      {/* <input
                        type="checkbox"
                        checked={isYearlySubscription}
                        onChange={() => setIsYearlySubscription(!isYearlySubscription)}
                      /> */}
                      {/* <span className="slider"></span> */}
                    </label>
                    {/* <span>{isYearlySubscription ? 'Yearly' : 'Monthly'}</span> */}
                    {/* <strong className="decorated">{isYearlySubscription ? 'Save 20%' : ''}</strong> */}
                  </p>
                </section>
                <div class="container-fluid ">
                  <div class="row">
                    {SubCard.map((i, index) => {
                        // Convert the price based on selected currency
  const convertedPrice = selectedCurrency === 'USD'
    ? (i.amount * exchangeRate).toFixed(2)
    : i.amount;
                      return (
                        <div class="col-lg-3 col-md-12 col-sm-12 mb-4">
                          <div class="card h-100 shadow-lg">
                            <div class="card-body">
                              <div class="text-center p-3">
                                <h5 class="card-title">{i.planName}</h5>
                                {/* <small>Individual</small> */}

                                <br />

                                <span class="h2">{selectedCurrency === 'USD' ? '$' : '₹'}{convertedPrice}</span>/month
                                <br /> <br /><br />
                              </div>
                              <p class="card-text"></p>
                              <ul class="list-group list-group-flush">
                                {
                                  JSON.parse(i.services).map((service, index) => (
                                    <li class="list-group-item" key={index}>

                                    {service.enable ?
                                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="green" class="bi bi-check" viewBox="0 0 16 16">
                                     <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg>
                                      : <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 384 512"><path fill="#f20226" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                                      </svg>}
                                        {service.title} {service.charges ? service.charges + '%' : ''}
                                    </li>
                                  ))
                                }
                              </ul>
                              <div class="card-body text-center">
                                <button onClick={() => HandleItem(i)} class="button btn-outline-primary" style={{ 'border-radius': '30px' }}> Subscribe </button>
                              </div>

                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="promotion-container NewClaSUb">
      <h2>Exciting New Launch Promotion - Limited Time Only!</h2>

      <p className='fw-bold'>
       Elevate your business with these fantastic deals:
      </p>

      <div className="membership-options mt-4 mb-5">
        <div className="membership-option">
          <h3>Two-Month Membership:</h3>
          <p>Purchase a 2-month membership and receive an additional 2-month subscription absolutely FREE!</p>
        </div>

        <div className="membership-option">
          <h3>Three-Month Membership:</h3>
          <p>Opt for a 3-month membership and enjoy an extra 3-month subscription at no additional cost!</p>
        </div>

        <div className="membership-option">
          <h3>Six-Month Membership:</h3>
          <p>Commit to a 6-month membership and receive an extra 6-month subscription as our gift to you!</p>
        </div>
      </div>

      <p>
      • This is your chance to maximize your subscription and make the most of our premium services. Act now to unlock the full potential of your membership!      </p>
      <p>
      • Don't miss out on this incredible offer – valid only  until<span className='fw-bold'> February 5th, 2024 .</span>
      </p>
      <p>
      • Join us today and experience a new level of excellence in your online book selling journey.
      </p>


      <p className='fw-bold'>
        To avail this offer email at <a href="mailto:support@booksbea.com" style={{color:"#0f7fc2"}}>support@booksbea.com</a> or WhatsApp at <a href="tel:+14087551607" style={{color:"#0f7fc2"}}>+1-408-755-1607</a>.
      </p>
    </div>

                {/*
                {amounts && currency && orderId && (
                  <>

                    <RenderRazorpay
                      amount={amounts}
                      currency={currency}
                      orderId={orderId}
                      keyId={'rzp_test_OLlFpw1nD4ka73'}
                      keySecret={'Ry46QeGmeA01KV3dcA5hQbSe'}
                    />
                  </>
                )} */}
              </div>
            </main>
          </div>
        </div>
      </div>


    </section>
  );
};

export default Subscription;
