// Head.js

import React from 'react';
import { Helmet } from 'react-helmet';

const Head = ({ title, description, image, url }) => {
  return (
    <Helmet>

    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />


      <link rel="canonical" href="https://www.booksbea.com" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Booksbea" />
      {/* <meta property="og:image" content="" /> */}
      <meta name="google-site-verification" content="372xuYTYARs27axg1pNORzoCFDXQwCJC-SicJZAOTnA" />
      {/* <meta property="og:image" itemprop="image" content="https://i.ibb.co/Y8Pdwgw/booksbealogo.png" /> */}
      <meta name="twitter:site" content="@booksbeadotcom" />
      <meta name="twitter:title" content="Sell books online l Read books online l Get Books at your doorstep" />
      <meta name="twitter:description" content="Booksbea brings you the best platform to sell  books online.You can sell all types of books available for user for online and offline reading both.Very good platform for authors,book stores and publisher to sell books online" />
    </Helmet>
  );
};

export default Head;
