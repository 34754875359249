import React, { useEffect } from 'react'
import "../assets/css/checkout.css"
import { useState } from 'react';
import AddNew from '../Component/AddAdress';
import config from '../config/Config';
import { useLocation } from 'react-router-dom';
import { KeyboardCommandKey } from '@mui/icons-material';
import RenderRazorpay from '../Component/PaymentGatweay';







export default function Checkout() {
  const { state } = useLocation();
  const [AllCartlist, setAllCart] = useState([])
  // const cartItems = [
  //   // This would be dynamically populated based on user selections
  //   { sellerId: '12', productId: state._id, quantity: 2 },

  // ];
  const [showModal, setShowModal] = useState(false);

  const handleCardClick = () => {
      // setSelectedItem(item);
      setShowModal(true);
  };

  const [popupVisible, setPopupVisible] = useState(false);
  const onPaymentCancelled = () => {
    // Logic to close the popup

      setAmounts(null);
      setCurrency(null);
      setOrderId(null);
      setUserId(null);

    // For example, if you're using a state variable to control the visibility of the popup:
    setPopupVisible(false); // Assuming you have a state [popupVisible, setPopupVisible]
  };




  const [userId,setUserId]=useState("");
  const [userType,setUserType]=useState("")
  const [paymentMethod, setPaymentMethod] = useState("Online");
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [visibleAddresses, setVisibleAddresses] = useState(3); // Initial number of visible addresses
  const [totalAmount, setTotalAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [finalAmount,setFinal]=useState("")
   const [dAT,setData]=useState("")
   const [AllBooData,setAllBooData]=useState("")
     const [amounts, setAmounts] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [addresses, setAddresses] = useState([

  ]);
  const [addresses1, setAddresses1] = useState("");




  const handleSelectAddress = (index) => {
    setSelectedAddressIndex(index);
    setAddresses1(addresses[index])

    console.log("Selected address:", addresses[index]);
  };
  console.log(addresses1.id,"addresses1 ");


  const handleEditAddress1 = (index) => {
    // Handle edit logic here
    console.log("Edit address at index:", index);
    // You can open a modal or navigate to another page with the address details for editing
  };

  const handleViewMore = () => {
    // Increase the number of visible addresses by a certain amount (e.g., 3)
    setVisibleAddresses((prevVisibleAddresses) => prevVisibleAddresses + 3);
  };

  const handleEditAddress = (index) => {
    // Trigger the edit functionality
    handleEditAddress1(index);
  };

  useEffect(()=>{
    // Detailsbook()
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });


  },[]

  )

  useEffect(() => {

    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);

    if (parsedUsername) {
      console.log(parsedUsername);
      setData(parsedUsername)
      // setemail(parsedUsername.email)
      setUserId(parsedUsername.userId)
      setUserType(parsedUsername.user_type)

    } else {
      console.log('No username found in local storage');
    }
    // AllAddress()

  }, []);


  useEffect(() => {
    // This useEffect depends on userId and calls AllAddress when userId changes and is valid
    if (userId) {
      AllAddress();
      AllCart();
    }
  }, [userId]);

  // const [cartItems, setCartItems] = useState([]);



  const getBookingItems = () => {
    return AllCartlist.map(item => ({
      sellerId: item.userId,
      productId: item.productDetails._id,
      quantity: item.quantity
    }));
  };

  // Log the result to the console
  console.log("Booking Items:", getBookingItems());

  const removeItem=()=>{
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    };

    fetch("https://test.booksbea.com/api/v1/cart?id=3&userId=12", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }



  const BookingCheckout=()=>{
    const bookingItems = getBookingItems();
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  bookingItems: bookingItems,
  userId: userId,
  addressId: addresses1.id,
  paymentMethod: paymentMethod,
  couponCode: "SAVE20"
});

console.log(raw,"raw")

// return false
var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};


fetch("https://test.booksbea.com/api/v1/booking/create", requestOptions)
.then((response) => {
  if(response.status != 200){
      // alert("Please Fill Valid Details")

  }

  return response.json();
})
  .then(async (responseData) => {
      console.log("RESULTS HEREforData: for user details id", responseData.data)
      if(responseData.data.paymentMethod=="Online"){
        setAmounts(responseData.data.orderPayment.amount_due);
        setCurrency(responseData.data.orderPayment.currency);
        setOrderId(responseData.data.orderPayment.id);
        setPopupVisible(true);
      }
     else if(responseData.data.paymentMethod=="COD"){
alert("Order Placed Successfully")
     }

    })
  .catch(error => console.log('error', error));

  }



  const AllAddress = () => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    fetch(config.api_base_url + `/api/v1/user/address/list/${userId}`, requestOptions)
        .then((response) => {
            if (response.code != 200) {
                //   alert("Please Fill Valid Details")


            }

            return response.json();
        })

        .then(async (responseData) => {
            console.log("RESULTS all All  Address===>>>>>>", responseData)
            setAddresses(responseData.data)

        })
        .catch(error => console.log('error', error));
}
const AllCart = () => {
  var requestOptions = {
      method: 'GET',
      redirect: 'follow'
  };

  fetch(config.api_base_url + `/api/v1/cart/list/${userId}`, requestOptions)
      .then((response) => {
          if (response.code != 200) {
              //   alert("Please Fill Valid Details")


          }

          return response.json();
      })

      .then(async (responseData) => {
          console.log("RESULTS all All  Cart===>>>>>>", responseData)

          // setAllCart(responseData.data)
          // const total = responseData.data.reduce((acc, item) => acc + item.productDetails.price, 0);
          // setTotalAmount(total);
          // const tax = total * 0.18;
          // setTaxAmount(tax);
          // console.log(total,"total==>>>");
          // const final = total + tax + 5;
          // setFinal(final)
          //   setAllCart(responseData.data)
          // const total = responseData.data.reduce((acc, item) => acc + (item.productDetails.price * item.quantity), 0);
          // setTotalAmount(total);
          // const tax = total * 0.18;
          // setTaxAmount(tax);
          // console.log(total,"total==>>>");
          setAllCart(responseData.data);
const total = responseData.data.reduce((acc, item) => acc + (item.productDetails.price * item.quantity), 0);
const roundedTotal = Math.floor(total); // Remove decimal values
setTotalAmount(roundedTotal);

const tax = roundedTotal * 0.18;
const roundedTax = Math.floor(tax); // Remove decimal values
setTaxAmount(roundedTax);

          const final =Math.floor( total + tax + 5);
          setFinal(final);



      })
      .catch(error => console.log('error', error));
}

const DataHandling=()=>{
  // console.log(selectedAddressIndex,"BookingCheckout")
  if(addresses1){
    BookingCheckout()

  }else{
    console.log(KeyboardCommandKey,"error")
  }

}

const [itemQuantities, setItemQuantities] = useState({});

// ...

// const handleIncrement = (productId) => {
//   setItemQuantities(prevQuantities => ({
//     ...prevQuantities,
//     [productId]: (prevQuantities[productId] || 0) + 1
//   }));
// };

// const handleDecrement = (productId) => {
//   setItemQuantities(prevQuantities => ({
//     ...prevQuantities,
//     [productId]: Math.max((prevQuantities[productId] || 0) - 1, 0)
//   }));
// };

const handleIncrement = (itemId) => {
  setAllCart((prevItems) =>
    prevItems.map((item) =>
      item.id === itemId.id ? { ...item, quantity: item.quantity + 1 } : item
    )
  );

  // Move the API call inside the callback function
  setAllCart((prevItems) => {
    var requestOptions = {
      method: 'PUT',
      redirect: 'follow'
    };

    fetch(`https://test.booksbea.com/api/v1/cart?id=${itemId.id}&quantity=${itemId.quantity + 1}&userId=${itemId.userId}`, requestOptions)
      .then((response) => {
        if (response.code !== 200) {
          // alert("Please Fill Valid Details")
        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("RESULTS all Quantity update Address===>>>>>>", responseData);
        AllCart();
      })
      .catch(error => console.log('error', error));

    return prevItems;
  });
};

const handleDecrement = (itemId) => {
  setAllCart((prevItems) =>
    prevItems.map((item) =>
      item.id === itemId.id && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    )
  );

  // Move the API call inside the callback function
  setAllCart((prevItems) => {
    var requestOptions = {
      method: 'PUT',
      redirect: 'follow'
    };

    fetch(`https://test.booksbea.com/api/v1/cart?id=${itemId.id}&quantity=${itemId.quantity - 1}&userId=${itemId.userId}`, requestOptions)
      .then((response) => {
        if (response.code !== 200) {
          // alert("Please Fill Valid Details")
        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("RESULTS all Quantity update Address===>>>>>>", responseData);
        AllCart();
      })
      .catch(error => console.log('error', error));

    return prevItems;
  });
};


// const handleIncrement = (itemId) => {
//   setAllCart((prevItems) =>
//     prevItems.map((item) =>
//       item.id === itemId.id ? { ...item, quantity: item.quantity + 1 } : item
//     )
//   );
//   var requestOptions = {
//     method: 'PUT',
//     redirect: 'follow'
//   };

//   fetch(`https://test.booksbea.com/api/v1/cart?id=${itemId.id }&quantity=${itemId.quantity}&userId=${itemId.userId}`, requestOptions)
//   .then((response) => {
//     if (response.code != 200) {
//         //   alert("Please Fill Valid Details")


//     }

//     return response.json();
// })

// .then(async (responseData) => {
//     console.log("RESULTS all Quantity update  Address===>>>>>>", responseData)
//     AllCart()


// })
// .catch(error => console.log('error', error));


// };

// const handleDecrement = (itemId) => {
//   setAllCart((prevItems) =>
//     prevItems.map((item) =>
//       item.id === itemId.id && item.quantity > 1
//         ? { ...item, quantity: item.quantity - 1 }
//         : item
//     )
//   );
//   var requestOptions = {
//     method: 'PUT',
//     redirect: 'follow'
//   };

//   fetch(`https://test.booksbea.com/api/v1/cart?id=${itemId.id }&quantity=${itemId.quantity}&userId=${itemId.userId}`, requestOptions)
//   .then((response) => {
//     if (response.code != 200) {
//         //   alert("Please Fill Valid Details")


//     }

//     return response.json();
// })

// .then(async (responseData) => {
//     console.log("RESULTS all Quantity update  Address===>>>>>>", responseData)
//     AllCart()


// })
// .catch(error => console.log('error', error));
// };

const removeItems=(item)=>{
  console.log(item,"removeItem")
  var requestOptions = {
    method: 'DELETE',
    redirect: 'follow'
  };

  fetch(`https://test.booksbea.com/api/v1/cart?id=${item.id}&userId=${item.userId}`, requestOptions)
  .then((response) => {
    if (response.code != 200) {
        //   alert("Please Fill Valid Details")


    }

    return response.json();
})

.then(async (responseData) => {
    console.log("RESULTS all All  Address===>>>>>>", responseData)
    AllCart()


})
.catch(error => console.log('error', error));


}
  return (
    <div>





    <section class="check-outsection">
      <div class="container">
        <div class="row">
          <h3>Checkout</h3>
          <div class="col-sm-6 mb-5">
            <div class="">
              <div class="category-set">
                <a>
                  Home
                </a>
                &nbsp;/&nbsp;
                <a>Book Category</a>
                &nbsp;/&nbsp;
                <a>Checkout</a>
                <a></a>
              </div>

            </div>



          </div>

          <div class="col-sm-7 border-end">
            <div class="set-border-contact">

              <div class="row">
                <div class="col-sm-2">
                  <div class="d-flex align-items-center"> <a class="set-icon-checkout mt-3">

                      <i class="fa fa-user-circle" aria-hidden="true"></i>
                    </a>
                  </div>


                </div>
                <div class="col-sm-7 d-flex align-items-center">
                  <div class="mt-2 ">
                    <h6>CONTACT INFO</h6>
                    <p id="setnumberp fw-bold">{dAT.username} {dAT.mobile}</p>
                  </div>



                </div>
                <div class="col-sm-3 d-flex justify-content-center align-items-center">
                  {/* <a class="set-btn-checkout" onclick="basicdt()">Change</a> */}


                </div>

                <div>

                </div>


              </div>

              <div id="changeemail">
                <div class="border-top mt-3">
                  <div class="set-heading-checkout pt-4">
                    <h5>Contact infomation</h5>
                  </div>


                  <form class="pt-4 setfontcheckout">


                    <div class="form-group mb-4">
                      <label for="exampleInputEmail1">Email address</label>
                      <input type="email" class="form-control p-3" aria-describedby="emailHelp"
                        placeholder="Enter email" id="getEmail" value="hello" />
                      <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
                        else.</small>
                    </div>
                    <div class="form-group mb-1">
                      <label for="exampleInputPassword1">Phone Number</label>
                      <input type="number" class="form-control p-3" placeholder="Enter phone number" id="getphone" />
                    </div>
                    <div class="form-check mb-5">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      <label class="form-check-label" for="exampleCheck1">Email me news and offer</label>
                    </div>
                    <div>
                      <a type="submit" class="btn btn-primary  p-3 mobilea"
                        // style="  font-family: 'Poppins', sans-serif;background-color: black;border: none;"
                        style ={{fontFamily:"'Poppins', sans-serif",background:"black",border:"none"}}
                        onclick="getVal()">Save and Next to Shipping </a>
                      <a onclick="cancleSet()" class="set-cancle">Cancle</a>
                    </div>
                  </form>
                </div>
              </div>




            </div>

            <div class="set-border-contact mt-5">
              <div class="row">
                <div class="col-sm-2">
                  <div class="d-flex align-items-center"> <a class="set-icon-checkout mt-3">

                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </a>
                  </div>


                </div>
                <div class="col-sm-7 d-flex align-items-center">
                  <div class="mt-2 ">
                    <h6>SHIPPING ADDRESS</h6>
                    {/* <p>St. Paul's Road, Norris, SD 57560,</p> */}



                  </div>


                </div>

                <div class="col-sm-3 d-flex justify-content-center align-items-center">
                  <a onClick={() => handleCardClick()} class="set-btn-checkout">Add New</a>


                </div>

                <div>

                </div>


              </div>
              <div className='row mt-3'>
              <div className='addrressList'>
      {/* <h3>Select Delivery Address</h3> */}
      {addresses.slice(0, visibleAddresses).map((address, index) => (
        <>
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px',justifyContent:"space-between" }}>
        <div className='setfontcheckout'>
          <label style={{ flexGrow: 1, }} className='d-flex align-items-center'>
            <input
            className='inputRadio'
              type="radio"
              name="address"
              checked={selectedAddressIndex === index}
              onChange={() => handleSelectAddress(index)}
            />
            <span className='fw-bold ps-2'> {address.name} <span className='fw-normal'> {address.mobile}</span></span>


          </label>
          <p className='ps-4'>  {address.address},   {address.city},   {address.state} , {address.country} , {address.zipCode}</p>
          {/* <p>{address.city}</p> */}

          </div>
          {/* <div className='cancle '> */}

          <a style={{color:"#0F7FC2",fontWeight:"bold"}} onClick={() => handleEditAddress(index)}>Edit</a>
          {/* </div> */}
        </div>
        <hr />

        </>


      ))}
      {addresses.length > visibleAddresses && (
        <a style={{color:"#0F7FC2",fontWeight:"bold",fontFamily:"Poppins',sans-serif"}} onClick={handleViewMore} className="view-more-button">
          View More ({addresses.length - visibleAddresses} remaining)
        </a>
      )}

    </div>

              </div>
              <AddNew
              userId={userId}
                show={showModal}
                onHide={() => setShowModal(false)}
                // item={selectedItem}
            />

<p>{console.log(currency,orderId,amounts,"amounts===>")}</p>
{popupVisible && amounts && currency && orderId && (

        <RenderRazorpay
          amount={amounts}
          currency={currency}
          orderId={orderId}
          keyId={'rzp_live_97uxfmkOaaV9bi'}
          keySecret={'4xUNVtOTSe0VFAekcjfASOPw'}
          onPaymentCancelled={onPaymentCancelled}
        />
      )}







            </div>

            <div class="set-border-contact mt-5">
              <div class="row">
                <div class="col-sm-2">
                  <div class="d-flex align-items-center"> <a class="set-icon-checkout mt-3">

                      <i class="fa fa-cc-visa" aria-hidden="true"></i>
                    </a>
                  </div>


                </div>
                <div class="col-sm-7 d-flex align-items-center">
                  <div class="mt-2 ">
                    <h6>SELECT PAYMENT METHOD</h6>
                    {/* <p>Google / Apple Walletxxx-xxx-xx55</p> */}
                  </div>


                </div>
                <div class="col-sm-3 d-flex justify-content-center align-items-center">
                  {/* <a class="set-btn-checkout">Change</a> */}


                </div>

                <div class="border-top mt-3 set-payment-section">




                  <div class="form-check mt-5 mb-5">
  <input class="form-check-input" type="radio" name="paymentMethod" id="onlinePayment" value="Online" onChange={e => setPaymentMethod(e.target.value)} />
  <label class="form-check-label" for="onlinePayment">
    <span><i class="fa fa-credit-card-alt" aria-hidden="true"></i></span> Online
  </label>
</div>
<div class="form-check mt-5 mb-5">
  <input class="form-check-input" type="radio" name="paymentMethod" id="codPayment" value="COD" onChange={e => setPaymentMethod(e.target.value)} />
  <label class="form-check-label" for="codPayment">
    <span><i class="fa fa-credit-card-alt" aria-hidden="true"></i></span> Cash On Delivery
  </label>
</div>


                </div>
                <div>

                </div>


              </div>




            </div>



          </div>

          <div class="col-sm-5">
            <div class="set-item-order">
              <h6>Order Summary</h6>
              {
                AllCartlist.map((item,index)=>{
                  return(
                  <div class="mt-5">
                <div class="set-card-divide">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="mt-3">
                        <a class="imgsetpngbook "><img src={config.api_base_url + `/api/v1/images?fileName=${item.productDetails.mainImage}`} /></a>
                      </div>
                    </div>
                    <div class="col-sm-6">

                      <div class="set-image-margine">
                        <h5 style={{fontFamily:"'Poppins', sans-serif",fontWeight:"bold"}}>{item.productDetails.title} </h5>
                      </div>
                      <div class="set-sub-cat d-flex justify-content-around w-75">
                        <div>
                          <p>Romance</p>


                        </div>
                        <span class="border" style={{height:"28px"}}></span>
                        <div>
                          <p>Poetry</p>
                        </div>


                      </div>

                      {/* <div class="mt-4 w-75 set-icon-width">
                        <div class="d-flex justify-content-around">
                          <div class=" d-flex justify-content-center">
                            <a id="decr">
                              <i class="fa fa-minus" aria-hidden="true"></i>


                            </a>
                          </div>
                          <div class="">
                            <p id="setcounter" class="fw-bold pt-2"> 1</p>
                          </div>
                          <div class=" d-flex justify-content-center">

                            <a id="incr">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </a>
                          </div>

                        </div>

                      </div> */}

                      {/*  */}
                      <div class="d-flex justify-content-around">
      <div class="d-flex justify-content-center">
        <a id="decr" onClick={() => handleDecrement(item)}>
          <i class="fa fa-minus" aria-hidden="true"></i>
        </a>
      </div>
      <div class="">
        <p id="setcounter" class="fw-bold pt-2">{item.quantity}</p>
      </div>
      <div class="d-flex justify-content-center">
        <a id="incr" onClick={() => handleIncrement(item)}>
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a>
      </div>
    </div>


                      {/*  */}

                    </div>
                    <div class="col-sm-2 price-remove">
                      <div>
                        <span class="set-money"><i class="fa fa-inr" aria-hidden="true"></i>{item.productDetails.price}</span>
                        <div class="cancle" onClick={()=>removeItems(item)}> <a>
                            Remove
                          </a>
                        </div>


                      </div>

                    </div>
                  </div>

                </div>

              </div>
                  )
                }

                )


              }




              <div class="mt-5 border-top">
                <form class="setfontcheckout mt-5">
                  <div class="row">
                    <div class="col-sm-9">
                      <div class="form-group mb-1">
                        <label for="exampleInputPassword1" class="mb-1">Discount Code</label>
                        <input style={{fontSize:"18px !important"}} type="text" class="form-control p-2" placeholder=""
                          id="getphone" />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group ">
                        {/* <!-- <label for="exampleInputPassword1" class="mb-1">Cvv</label> --> */}
                        <button  style={{fontSize:"18px !important"}} type="text" class="btn btn-dark set-apply">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </form>



              </div>
              <ul class="mt-5 set-item-ul">
                <li>
                  <div class="row ">
                    <div class="col-sm-6">
                      <p>Subtotal</p>
                    </div>
                    <div class="col-sm-6">
                      <p class="fw-bold set-text-rs">
                        <span class=""><i class="fa fa-inr" aria-hidden="true"></i>{totalAmount}</span>
                      </p>

                    </div>

                  </div>
                </li>
                <li>
                  <div class="row ">
                    <div class="col-sm-6">
                      <p>Shipping estimate</p>
                    </div>
                    <div class="col-sm-6">
                      <p class="fw-bold set-text-rs">
                        <span class=""><i class="fa fa-inr" aria-hidden="true"></i>5.00</span>
                      </p>

                    </div>

                  </div>
                </li>
                <li>
                  <div class="row ">
                    <div class="col-sm-6">
                      <p>Tax estimate</p>
                    </div>
                    <div class="col-sm-6">
                      <p class="fw-bold set-text-rs">
                        <span class=""><i class="fa fa-inr" aria-hidden="true"></i>{taxAmount}</span>
                        {/* <div>Tax (18%): {taxAmount}</div> */}
                      </p>

                    </div>

                  </div>
                </li>
                <li>
                  <div class="row ">
                    <div class="col-sm-6">
                      <p class="fw-bold fs-5">Order Total</p>
                    </div>
                    <div class="col-sm-6">
                      <p class="fw-bold set-text-rs">
                        <span class=""><i class="fa fa-inr" aria-hidden="true"></i>{finalAmount}</span>
                      </p>

                    </div>

                  </div>
                </li>
                <div class="mt-2">
                  <a onClick={()=>DataHandling()} class="btn btn-dark w-100 p-3 " style={{fontFamily:"font-family: 'Poppins', sans-serif"}} >Confirm Order</a>
                </div>
              </ul>




            </div>

          </div>
        </div>


      </div>


    </section>








    </div>
  )
}
