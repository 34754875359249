import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config/Config';
import QuickViewModal from '../Component/QuickView';
import AddToWishlist from '../Component/AddToWishlist';


import 'tippy.js/dist/tippy.css';
import Tippy from '@tippyjs/react';

export default function AllBooks() {
  const [cards, setAllCards] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage] = useState(6); // Adjust the number of cards per page as needed
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    // Detailsbook()
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });


  }, []

  )

  useEffect(() => {
    Detailsbook();
  }, [currentPage]); // Fetch data when currentPage changes

  const truncateText = (text, wordCount) => {
    const words = text.split(' ');

    if (words.length > wordCount) {
      const truncatedWords = words.slice(0, wordCount);
      return `${truncatedWords.join(' ')} ...`;
    } else {
      return text;
    }
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const Detailsbook = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "pageNumber": currentPage,
      "pageSize": 35
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${config.api_base_url}/api/v1/books/find`, requestOptions)
      .then((response) => {
        if (response.code !== 200) {
          // Handle error
          console.error("Error fetching data");
        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("RESULTS all booking details", responseData);
        setAllCards(responseData.data);
        setPagination(responseData.pagination);
      })
      .catch((error) => console.log('error', error));
  };
  const AuthoreDetails = async (item) => {
    const { id, firstName } = item;
    console.log(item, "ItemGet")
    const urlFriendlyAuthorName = encodeURIComponent(item.authorDetails.lastName).replace(/%20/g, '-');

    navigate(`/authors/${item.authorDetails.firstName}${urlFriendlyAuthorName}`, { state: item });

    // navigate(`/authors/${item.id}`, { state: item});



  }

  const navigate = useNavigate();
  const Detailsset = (item) => {
    console.log(item, "ItemGet")

    if(!item.metaTitle || item.metaTitle === "") {
      navigate(`/books/details/${item._id}`, { state: item });
    } else {
      navigate(`/books/details/${item.metaTitle}`, { state: item });
    }




  }



  // Pagination logic
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };




  return (
    <>
      {cards !== null ? (
        <div>
          <section className="section-2">
            <div className="container-fluid">
              <div className='row set_FIlter mt-5 sticky-top'>
                <div className='col-sm-4 d-flex align-items-center'>
                  <div className='position-relative'>
                    <input type='text' className='serach bar ' placeholder='Search..' />
                    <a><i class="fa fa-search iconsSe" aria-hidden="true"></i></a>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-xl-8 mt-2" style={{ paddingRight: "38px" }}>
                  <div class="d-flex justify-content-end set-flex pt-2">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      <li class="nav-item pe-2" role="presentation">
                        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-home" type="button" role="tab"
                          aria-controls="pills-home" aria-selected="true">Most Popular</button>
                      </li>
                      <li class="nav-item pe-2" role="presentation">
                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-profile" type="button" role="tab"
                          aria-controls="pills-profile" aria-selected="false">New Arrival</button>
                      </li>
                      <li class="nav-item pe-2" role="presentation">
                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-profile" type="button" role="tab"
                          aria-controls="pills-profile" aria-selected="false">Best Rated</button>
                      </li>
                      <li class="nav-item pe-2" role="presentation">
                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-profile" type="button" role="tab"
                          aria-controls="pills-profile" aria-selected="false">Recommended</button>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-5 row pro d-flex justify-content-center">
                {cards.map((item, index) => (
                  <div key={index} className="new_arrival_sec">
                    <div className="p-2">
                      <div class="book1">
                        <div class="book_img" >
                          <img className='cursor-pointer' onClick={() => Detailsset(item)} src={config.api_base_url + `/api/v1/images?fileName=${item.mainImage}`} alt="" />
                          {/* <img src={require("../assets/images/book444.webp")} /> */}
                          <AddToWishlist  productId={item} />
                        </div>
                        {/* <div><i class="fa fa-heart" aria-hidden="true"></i></div> */}
                        <div class="price_details">
                          {/* <div>
                                                <img src={item.icon}  className='iconClass'/>
                                            </div> */}
                          <div className='d-flex mb-2'>
                            <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>                                            <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                            <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                            <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>
                            <i class="fa fa-star" style={{ color: "gold" }} aria-hidden="true"></i>

                          </div>
                          <h4>Rs. {item.listingPrice}</h4>
                          <h2>Rs. {item.price}</h2>

                          <a className='text-white' onClick={() => Detailsset(item)} >

                            Buy Now
                          </a>
                        </div>
                      </div>
                      <div class="book_details mt-3">
                        <div class="book_name">
                          <Tippy content={`${item.title}`} placement="top">
                            <p className='mb-0' onClick={() => Detailsset(item)}>
                              {truncateText(item.title, 4)}
                            </p>
                          </Tippy>
                          {item.authorId == "-1" ? <p className='mt-0'  >By: {item.authorDetails.firstName} {item.authorDetails.lastName} </p> :

                            <p className='mt-0 cursor-pointer' onClick={() => AuthoreDetails(item)}>By: {item.authorDetails.firstName} {item.authorDetails.lastName} </p>}


                        </div><div class="book_icon">
                          {/* <span><i class="fa fa-heart" aria-hidden="true"></i></span> */}
                      <span><i class="fa fa-book" aria-hidden="true"></i> </span>
                          {/* {
                            item.audioBook == "0" ? <span><i class="fa fa-microphone-slash" aria-hidden="true"></i></span>

                              : */}
                              <span><i class="fa fa-microphone" aria-hidden="true"></i></span>

                          <span className='cursor-pointer'  onClick={() => handleCardClick(item)}><i class="fa fa-eye" aria-hidden="true"></i></span>
                          <span><i class="fa fa-shopping-cart" aria-hidden="true"></i></span>
                          <span><i class="fa fa-share-alt" aria-hidden="true"></i></span>

                        </div>

                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center align-items-center ">
                {/* Pagination */}
                <div className='newAdd d-flex justify-content-center'>
                  {pagination && (
                    <nav aria-label="Page navigation example" className='newPageNaviage'>
                      <ul className="pagination mb-0">
                        {Array.from({ length: pagination.totalPages }).map((_, index) => (
                          <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                            <a className="page-link newPageLink" onClick={() => paginate(index + 1)}>
                              {index + 1}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  )}
                </div>

              </div>
            </div>
          </section>

          <QuickViewModal show={showModal} onHide={() => setShowModal(false)} item={selectedItem} />
        </div>
      ) : (
        <div className="loader-overlay">
          {/* You can style this loader overlay as needed */}
          <div className="loader-container">
            <img src={require("../assets/images/Loader.gif")} alt="Loading" className="loader" />
          </div>
        </div>
      )}

    </>
  );
}
