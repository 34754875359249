import React, { useEffect, useState } from 'react';
import ProfilePhotoUpload from '../Component/ProfileUpdate';
import CertificateUpload from './AddCertificates'
import { json } from 'react-router-dom';
import config from '../config/Config';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const MyAccount = () => {


  const [selectedFile, setSelectedFile] = useState(null);
  const [emails, setemail] = useState("")
  const [userId, setUserId] = useState("")
  const [mobile, setmobile] = useState("")
  const [dataall, setAll] = useState("")
  const [userType, setuserType] = useState("");
  const [sub_user_type, set_sub_user_type] = useState("");

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleImageChange = (e) => {
    setSelectedFile(e.target.files[0]);

  };
  console.log(selectedFile)

  const handleSubmit = async (e) => {
    e.preventDefault();

    Bookdetails()
  }
  




  useEffect( () => {

    // This will run when the component mounts (similar to componentDidMount in class components)


    const storedUsername = localStorage.getItem('MyToken');
    const parsedUsername = JSON.parse(storedUsername);

    if (parsedUsername) {
      console.log(parsedUsername);
      setemail(parsedUsername.email);
      setUserId(JSON.stringify(parsedUsername.userId));
      setuserType(parsedUsername.user_type)
      set_sub_user_type(parsedUsername.sub_user_type)
      setmobile(parsedUsername.mobile)
    } else {
      console.log('No username found in local storage');
    }
    //   console.log(`http://198.177.123.10:4001/api/v1/shop/details/${userId}`,"url");





  }, []);

  //   useEffect(() => {
  //     const storedUsername = localStorage.getItem('MyToken');
  //     const parsedUsername = JSON.parse(storedUsername);

  //     if (parsedUsername) {
  //         console.log(parsedUsername);
  //         setEmail(parsedUsername.email);
  //         setUserId(parsedUsername.userId);
  //         setMobile(parsedUsername.mobile);
  //     } else {
  //         console.log('No username found in local storage');
  //     }
  // }, []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        window.location.reload();
      }
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (userId && sub_user_type ) {
      // console.log(config.api_base_url + `/api/v1/shop/details/${userId}`, "url");

      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };


      //   return false


      fetch(config.api_base_url + `/api/v1/shop/details/${userId}`, requestOptions)

        .then((response) => {
          if (response.status != 200) {
            // alert("Please Fill Valid Details")
            toast.error(response.message ,{ position: toast.POSITION.BOTTOM_RIGHT});
          }

          return response.json();
        })
        .then(async (responseData) => {
          setSellerinfo(responseData.data)
          setPersonalDetails(responseData.UserData)
          if (responseData.UserData.aboutUser) {
            const parsedContent = JSON.parse(responseData.UserData.aboutUser)
            setEditorState(EditorState.createWithContent(convertFromRaw(parsedContent)));
          }

        })
        .catch(error => console.log('error', error));


      // Fetch data or perform other actions with the URL
    }
  }, [userId]); // This effect runs when userId changes





  console.log(userId, 'userId')

  const [Personal, setPersonalDetails] = useState({
    firstName: '',
    lastName: '',
    publicationName: '',
    email: '',
    videoIntroduction: '',

  });

  const [Sellerinfo, setSellerinfo] = useState({
    shopName: dataall.shopName,
    companyAddress: '',
    zipCode: '',
    foundationDate: '',
    licenceImage: '',




  });
  const handlesellerInput = (e) => {
    const { name, value } = e.target;
    setSellerinfo({
      ...Sellerinfo,
      [name]: value,
    });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPersonalDetails({
      ...Personal,
      [name]: value,
    });
  };



  const PersonalDetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    // const content =  convertToRaw(editorState.getCurrentContent())

    var raw = JSON.stringify({
      "userId": userId,
      "firstName": Personal.firstName,
      "lastName": Personal.lastName,
      "publicationName": Personal.publicationName,
      "videoIntroduction": Personal.videoIntroduction,
      "aboutUser": content

    });

    console.log(raw)
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.api_base_url + "/api/v1/user/profile", requestOptions)
      .then((response) => {
        if (response.status != 200) {
          // alert("Please Fill Valid Details")
          toast.error(response.message ,{ position: toast.POSITION.BOTTOM_RIGHT});


        }

        return response.json();
      })
      .then(async (responseData) => {
        // console.log("Profile Details==>>>data", responseData)
        // alert(responseData.message)
           toast.success(responseData.message ,{ position: toast.POSITION.BOTTOM_RIGHT});
        // setData(responseData.

      })
      .catch(error => console.log('error', error));

  }

  const Bookdetails = () => {
    var formdata = new FormData();
    formdata.append("licenceImage", selectedFile);
    formdata.append("userId", userId);
    formdata.append("shopName", Sellerinfo.shopName);
    formdata.append("companyAddress", Sellerinfo.companyAddress);
    formdata.append("zipCode", Sellerinfo.zipCode);
    formdata.append("foundationDate", Sellerinfo.foundationDate);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(config.api_base_url + "/api/v1/shop/details", requestOptions)
      .then((response) => {
        if (response.status != 200) {
          // alert("Please Fill Valid Details")


        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("Profile Details==>>>data", responseData)
        alert("Update Submit")


      })
      .catch(error => console.log('error', error));
  }


  const DetailsShow = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch(config.api_base_url + `/api/v1/shop/details/${userId}`, requestOptions)
      .then((response) => {
        if (response.status != 200) {
          alert("Please Fill Valid Details")
        }
        return response.json();
      })
      .then(async (responseData) => {
        console.log("Profile Details==>>>data", responseData)
      })
      .catch(error => console.log('error', error));
  }
  console.log(userType, "userType cjhecking===>>>>>>>")

  return (
    <div>
      <section className="sign-set mb-5 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 d-flex justify-content-center ">
              <div className="set-login">
                <div className="py-4">
                  <section>
                    <header>
                      <h3 className="">Personal Information</h3>
                      <p className="text-center text-danger fw-bold">
                        Update your account's profile information and email address.
                      </p>
                    </header>
                    <ProfilePhotoUpload data={Personal.profileImage} />
                    {/* {Personal.profileImage} */}
                    <form onS className="mt-4 mb-4" onSubmit={() => PersonalDetails()}  >
                      <input type="hidden" name="_token" value="ifpBFAxsqsqF8V948OhvRvD4W9mNsoTbh5KLUEoF" />        <input type="hidden" name="_method" value="patch" />
                      <div className="row">
                        <div className="col-md-3">
                          <label className="col-sm-6 col-form-label" for="firstname">
                            First Name
                          </label>
                          <input
                            className="form-control p-3 form-control setcustomeinput"
                            id="email" name="firstName" type="text" required="required"
                            autoFocus="autofocus"
                            placeholder='Enter First Name'
                            value={Personal.firstName}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3 form-group">
                            <label className="col-sm-6 col-form-label" for="lastname">
                              Last Name
                            </label>
                            <input
                              className="form-control p-3 form-control setcustomeinput"
                              id="email" name="lastName" type="text"
                              required="required"
                              autoFocus="autofocus"
                              placeholder='Enter Last Name'
                              value={Personal.lastName}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3 form-group">
                            <label className="col-sm-2 col-form-label" for="email">
                              Email
                            </label>
                            <input className="form-control p-3 form-control setcustomeinput"
                              id="email" name="email" type="email"
                              value={emails}

                              required="required" autoComplete="email" autoFocus="autofocus"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3 form-group">
                            <label className="col-sm-2 col-form-label" for="name">
                              Mobile
                            </label>
                            <input
                              placeholder='Enter Mobile No'
                              className="form-control p-3 form-control setcustomeinput" id="mobile" name="mobile" type="text"

                              required="required" autoComplete="mobile"
                              value={mobile}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 mb-3 form-group'>
                          <label className="col-sm-12 col-form-label" for="name">
                            Video introduction ( Add youtube Video Id )
                          </label>
                          <input
                            className="form-control p-3 form-control setcustomeinput"
                            id="videoIntroduction" name="videoIntroduction" type="text"
                            // required="required"
                            autoFocus="autofocus"
                            placeholder='Enter Youtube Video Id'
                            value={Personal.videoIntroduction}
                            onChange={handleInputChange}
                          />
                          {/* <textarea 
                                autoFocus="autofocus"
                                onChange={handleInputChange} className='form-control setcustomeinput' id="videoIntroduction" name="videoIntroduction">{Personal.videoIntroduction}</textarea> */}
                        </div>
                      </div>
                      {
                        sub_user_type != null && sub_user_type == 'publisher' ? (
                          <div className='row'>
                        <div className='col-md-12 mb-3 form-group'>
                          <label className="col-sm-12 col-form-label" for="name">
                            Publication Name
                          </label>
                          <input
                            className="form-control p-3 form-control setcustomeinput"
                            id="publicationName" name="publicationName" type="text"
                            // required="required"
                            autoFocus="autofocus"
                            placeholder='Enter your publication name ..'
                            value={Personal.publicationName}
                            onChange={handleInputChange}
                          />
                          {/* <textarea 
                                autoFocus="autofocus"
                                onChange={handleInputChange} className='form-control setcustomeinput' id="videoIntroduction" name="videoIntroduction">{Personal.videoIntroduction}</textarea> */}
                        </div>
                      </div>
                        ) : null
                       
                      }
                      
                      
                      <div className='row'>
                        <div className='col-md-12 form-group'>
                          <label className="col-sm-6 col-form-label" for="lastname">
                            Write About Your Self
                          </label>
                          <div className="setcustomeinput">
                            <Editor
                              editorStyle={{
                                minHeight: '200px', // Set the minimum height
                                lineHeight: '1.5', // Set the line height
                                backgroundColor: '#FFFFFF', // Set the background color to white
                                border: '1px solid #CED4DA', // Optional: Add a border
                                borderRadius: '4px', // Optional: Add border radius
                                padding: '10px', // Optional: Add padding
                              }}
                              placeholder="Write your short bio for your fans .....  " // Add placeholder text
                              editorState={editorState}
                              toolbarClassName="toolbar-class"
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              onEditorStateChange={handleEditorStateChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className='row'>
                          <div className='col-md-8 form-group'>
                              <label className="col-sm-8 col-form-label" for="name">
                                Achievements Certificates 
                              </label>
                              <input type="file" className="form-control p-3 form-control setcustomeinput" onChange={handleImageChange} />
                          </div>
                         
                      </div> */}
                    </form>
                    <div className='d-flex justify-content-center'>
                      <button onClick={() => PersonalDetails()} className="btn btn-primary w-25 p-2" >
                        Save
                      </button>
                    </div>

                    <form className="mt-4 mb-4">
                      <input type="hidden" name="_token" value="ifpBFAxsqsqF8V948OhvRvD4W9mNsoTbh5KLUEoF" />
                      <input type="hidden" name="_method" value="patch" />
                      {/* ... Rest of the form fields ... */}
                    </form>
                  </section>
                  <hr />
                  <CertificateUpload />
                  <hr className='mt-5'></hr>

                  {userType && (
                    <section >
                      <header>
                        <h3 className="">Business Details {console.log(userType, "userType")}</h3>
                        <p className="text-center text-danger fw-bold">Mandatory For Book Stores and Publishers Only Not for Authors. </p>
                      </header>
                      <form onSubmit={handleSubmit} className="mt-4 mb-4" encType="multipart/form-data">
                        {/* <input type="hidden" name="_token"  />        <input type="hidden" name="_method" value="patch" /> */}
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3 form-group">
                              <label className="col-form-label" for="name">
                                Business Name *
                              </label>
                              <input className="form-control p-3 form-control setcustomeinput"
                                id="b_name" name="shopName" type="text"
                                required="required"
                                placeholder='Enter Business Name'
                                value={Sellerinfo.shopName}
                                onChange={handlesellerInput}

                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 form-group">
                              <label className="col-form-label" for="date">
                                Founded On *
                              </label>
                              <input className="form-control p-3 form-control setcustomeinput" id="foundedOn" name="foundationDate" type="date"
                                required="required"
                                value={Sellerinfo.foundationDate}
                                onChange={handlesellerInput}
                                autoComplete="foundedOn"
                                autoFocus="autofocus" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 form-group">
                              <label className="col-form-label" for="pCode">
                                Postal Code *
                              </label>
                              <input className="form-control p-3 form-control setcustomeinput" id="pCode" name="zipCode"
                                type="text"
                                value={Sellerinfo.zipCode}
                                placeholder='Enter Postal Code'

                                onChange={handlesellerInput}
                                required="required" autoComplete="pCode"
                                autoFocus="autofocus" />
                            </div>
                          </div>
                          {/* <div className="col-md-6">
<div className="mb-3 form-group">
<label className="col-form-label" for="date">
Types of books *
</label>
<select name="booksType" className="form-select setcustomeinput p-3" required="">
  <option value="0">Books Type</option>
  <option value="fresh">
      Fresh Books
  </option>
  <option value="used">
      Used Books
  </option>
  <option value="both">
      Both
  </option>
</select>
              </div>
</div> */}

                          <div className="col-md-6">
                            <div className="mb-3 form-group">
                              <label className="col-form-label" for="lCerti">
                                Store Licence / Certificate
                              </label>
                              <input type="file" className="form-control p-3 form-control setcustomeinput" onChange={handleImageChange} />

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3 form-group">
                              <label className="col-form-label" for="sAddress">
                                Store Address *
                              </label>
                              <input
                                className="form-control p-3 form-control setcustomeinput"

                                id="sAddress" name="companyAddress"
                                type="text"
                                placeholder='Enter Full Address'

                                value={Sellerinfo.companyAddress}
                                onChange={handlesellerInput}
                                required="required" autoComplete="companyAddress" autoFocus="autofocus" />
                            </div>
                          </div>



                          {/* <p className="text-center mt-4 mb-4 text-danger fw-bold">
Shop details.
</p> */}



                          {/* <div className="col-md-3">
<div className="mb-3 form-group">
<label className="col-form-label" for="inventory">
Inventory
</label>
<input className="form-control p-3 form-control setcustomeinput" id="inventory" name="inventory" type="number" autoComplete="inventory" autoFocus="autofocus" />
              </div>
</div>
<div className="col-md-3">
<div className="mb-3 form-group">
<label className="col-form-label" for="lCerti">
Total Staff
</label>
<input className="form-control p-3 form-control setcustomeinput" id="tStaff" name="tStaff" type="number" autoComplete="tStaff" autoFocus="autofocus" />
              </div>
</div>
<div className="col-md-3">
<div className="mb-3 form-group">
<label className="col-form-label" for="turnover">
Total Turnover(in lakhs)
</label>
<input className="form-control p-3 form-control setcustomeinput" id="turnover" name="turnover" type="number" autoComplete="turnover" autoFocus="autofocus" />
              </div>
</div> */}
                          {/* <div className="col-md-6">
<fieldset className="row mb-3">
<legend className="col-form-label col-sm-4 pt-0">Shipping:</legend>
<div className="col-sm-12">
  <div className="form-check form-check-inline">
      <input className="form-check-input" type="radio" name="shipping" value="self" checked="" />
      <label className="form-check-label">Self Shipping</label>
  </div>
  <div className="form-check form-check-inline">
      <input className="form-check-input" type="radio" name="shipping" value="portal" />
      <label className="form-check-label">Portal Shipping</label>
  </div>
</div>
</fieldset>
</div>
<div className="col-md-6">
<fieldset className="row mb-3">
<legend className="col-form-label col-sm-6 pt-0">Want to donate books for poor ? </legend>
<div className="col-sm-12">
  <div className="form-check form-check-inline">
      <input className="form-check-input" type="radio" name="donate" value="yes" checked="" />
      <label className="form-check-label">Yes</label>
  </div>
  <div className="form-check form-check-inline">
      <input className="form-check-input" type="radio" name="donate" value="no" />
      <label className="form-check-label">No</label>
  </div>
</div>
</fieldset>
</div> */}


                          <div className="row d-flex justify-content-center">
                            <div className="col-md-3 mb-4 mt-4">
                              <button type="submit" className="btn btn-primary w-100 p-2" >
                                Save
                              </button>

                            </div>
                          </div>
                        </div>
                      </form>
                    </section>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyAccount;
