import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../Component/Header'
import config from '../config/Config';
import NewFollow from '../Component/Follow';



export default function AllSeller() {
    const [ShopKeepers, setShopKeepers] = useState(null)


    const allShopKeepers = (() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(config.api_base_url + "/api/v1/list/shopkeepers", requestOptions)
            .then((response) => {
                if (response.code != 200) {
                    //   alert("Please Fill Valid Details")
                }
                return response.json();
            })
            .then(async (responseData) => {
                console.log("RESULTS all All  details", responseData.data.shopkeepers)
                await setShopKeepers(responseData.data.shopkeepers)
            })
            .catch(error => console.log('error', error));
    })
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        // Detailsbook()
        allShopKeepers()

    }, [])
    const navigate = useNavigate();
    const SellereDetails = async (item) => {
        const { userId, shopDetails } = item;
        console.log(item, "ItemGet")
        const urlFriendlyAuthorName = encodeURIComponent(item.shopDetails.shopName).replace(/%20/g, '-');
        // const urlFriendlyAuthorName = `${encodeURIComponent(item.shopDetails.shopName)}`;

        //  navigate(`/authors/${item.authorDetails.firstName}${urlFriendlyAuthorName}`, { state: item });

        const lowercaseAuthorName = urlFriendlyAuthorName.toLowerCase();
        // const url = `/authors/${encodeURIComponent(authorDetails.firstName)}-${encodeURIComponent(authorDetails.lastName)}-${userId}`;
        console.log(urlFriendlyAuthorName, "urlFriendlyAuthorName")

        // Use the `navigate` function to redirect to the lowercase URL
        let dynamicUserName = `${lowercaseAuthorName}-${userId}`
        navigate(`/book-stores/${dynamicUserName}`);
        //  navigate(`/authors/${urlFriendlyAuthorName}`, { state: item });

        // navigate(`/authors/${item.id}`, { state: item});



    }

    return (
        <>
            <section class="leader_bg">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="title_top_writer">

                                <h1><span style={{ color: "red" }}>B</span>ook Stores  Leaderboard</h1>

                                <h3>Explore more about your favorite shop and their other amazing articles & blogs on the site. Follow them for new Updates & Blogs by them.</h3>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="image_top_writer">
                                <img src={require("../assets/images/leader-board-image.png")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="top_five">
                <div class="container">
                    <div class="row">

                        <div class="col-12">
                            <div class="top_five_title text-center">
                                <h2><span style={{ color: "red" }}>O</span>ur Top Five book Stores</h2>
                            </div>
                        </div>
                        {ShopKeepers !== null ?
                            ShopKeepers.map((i, index) => {
                                return (
                                    <>
                                        {
                                            index <= 4 ? <div class="col-md-4 col-lg-2 col-sm-12 m-auto">
                                                <div class="five_leaders">
                                                    {/* <img src={require("../assets/images/smile-it-solutions-author-profile.jpg")} alt="" /> */}
                                                    <img className='cursor-pointer' onClick={() => SellereDetails(i)} id="image" src={i.shopkeeperDetails.profileImage != null && i.shopkeeperDetails.profileImage != "" ? config.api_base_url + `/api/v1/images?fileName=${i.shopkeeperDetails.profileImage}` : `https://cdn-icons-png.flaticon.com/512/4791/4791601.png`} alt="" />





                                                    <img class="top_badge" src={require("../assets/images/olive-leaf-logo-png.png")} alt="" />

                                                    <p><span>#{index + 1}</span></p>

                                                    <h3 onClick={() => SellereDetails(i)} className='cursor-pointer'>{i.shopDetails.shopName}</h3>
                                                </div>
                                            </div> : null
                                        }

                                    </>


                                )

                            }

                            )
                            : <div className="loader-overlay">
                                {/* You can style this loader overlay as needed */}
                                <div className="loader-container">
                                    <img src={require("../assets/images/Loader.gif")} alt="Loading" className="loader" />
                                </div>
                            </div>
                        }

                    </div>
                </div>

            </section>
            <section className='leaderboard'>
                <div className='container'>
                    <div className='row my-5'>
                        {
                            ShopKeepers !== null ?
                                ShopKeepers.map((i, index) => {
                                    return (
                                        <div class="col-lg-3 col-md-4 col-sm-6" >
                                            <div class="leader-details" >
                                                {/* <Link   to='/SinglePublisher' target="_blank" > */}

                                                <div class="position_no">
                                                    <span>#{index + 1}</span>
                                                </div>
                                                <img className='cursor-pointer' onClick={() => SellereDetails(i)} id="image" src={i.shopkeeperDetails.profileImage != null && i.shopkeeperDetails.profileImage != "" ? config.api_base_url + `/api/v1/images?fileName=${i.shopkeeperDetails.profileImage}` : `https://cdn-icons-png.flaticon.com/512/4791/4791601.png`} alt="" />

                                                {/* <img id="image" src={i.shopkeeperDetails.profileImage != null && i.shopkeeperDetails.profileImage != "" ? config.api_base_url + `/api/v1/images?fileName=${i.shopkeeperDetails.profileImage}` : `https://thumbs.dreamstime.com/b/corrupted-file-document-icon-linear-simple-element-illustration-bad-concept-outline-symbol-design-vector-logo-can-be-used-web-134295411.jpg`} alt="" /> */}

                                                <h3 className='cursor-pointer' onClick={() => SellereDetails(i)}>{i.shopDetails.shopName}</h3>
                                                {/* <p>{console.log(i.shopkeeperDetails.publicationName)}</p> */}

                                                <div class="row pt-3 align-items-center">
                                                    <div class="col-4">
                                                        <div class="total_articles articlee_view text-center">
                                                            <img src={require("../assets/images/1.png")} alt="" />
                                                            <h3>Followers</h3>
                                                            <span id="follow_count1">{i.followerCount}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="total_articles articlee_view text-center">
                                                            <img src={require("../assets/images/2.png")} alt="" />
                                                            <h3> Books</h3>
                                                            <span>{i.bookCount ? i.bookCount : 0}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="total_views articlee_view text-center">
                                                            <img src={require("../assets/images/3.png")} alt="" />
                                                            <h3>Visitors</h3>
                                                            <span>{i.profileVisitCount}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* </Link> */}

                                                <hr />

                                                <NewFollow followingId={i.shopkeeperDetails.userId} />


                                                {/* <button><a href="login?page=top-content-writers">Follow</a></button> */}
                                                <button onClick={() => SellereDetails(i)}>  Know Me </button>
                                            </div>
                                        </div>
                                    )
                                })
                                : <div className="loader-overlay">
                                    {/* You can style this loader overlay as needed */}
                                    <div className="loader-container">
                                        <img src={require("../assets/images/Loader.gif")} alt="Loading" className="loader" />
                                    </div>
                                </div>

                        }
                        {/* { ShopKeepers } */}
                        {/* ShopKeepers.map((shopKeeper ,index) => {
                            <div className='col-sm-3'>
                                <div class="product_items">
                                    <article class="single_product">
                                        <figure>
                                            <div class="product_thumb">
                                                <a class="primary_img" href="">
                                                    <img class="lazy" data-original="http://ecartvendor.wrteam.co.in/upload/seller/1649400019.0214.png" alt="seller" src="http://ecartvendor.wrteam.co.in/upload/seller/1649400019.0214.png" /></a>
                                            </div>
                                            <figcaption class="product_content">
                                                <h4 class="product_name"><a href="">eCart  Grocery Seller Pvt. Ltd.</a>
                                                </h4>
                                            </figcaption>
                                        </figure>
                                    </article>
                                </div>
                            </div>
                        }) */}
                        {/* } */}

                    </div>
                </div>
            </section>
        </>
    )
}
