import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import config from '../config/Config';

const NewFollow = ({ followingId,AuthorAlldetails,followerCount }) => {
    const [userId, setUserId] = useState("");
    const [MyToken, setMyToken] = useState(null);
    const [loading, setLoading] = useState(false); // New state for loading indicator
    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('jwtToken');
        setMyToken(storedUsername);
    }, []);

    const Logout = async () => {
        await localStorage.clear();
        navigate(`/Signin`);
        window.location.reload();
    }

    const handleButtonClick = async () => {
        setLoading(true); // Set loading state to true when button is clicked

        try {
            if (!MyToken) {
                Logout();
            } else {
                const response = await fetch(config.api_base_url + `/api/v1/followers/new_follower/${followingId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + MyToken,
                    },
                });

                switch (response.status) {
                    case 201:
                        const responseData = await response.json();
                        AuthorAlldetails()
                        toast.success(responseData.message, { position: toast.POSITION.BOTTOM_RIGHT });
                        break;
                    case 200:
                        toast.success("Already Following !", { position: toast.POSITION.BOTTOM_RIGHT });
                        break;
                    case 400:
                        toast.error("Bad request ", { position: toast.POSITION.BOTTOM_RIGHT });
                        break;
                    case 403:
                        toast.error("Access Forbidden !  ", { position: toast.POSITION.BOTTOM_RIGHT });
                        Logout();
                        break;
                    case 500:
                        toast.error("Internal Server Error:", { position: toast.POSITION.BOTTOM_RIGHT });
                        console.error('Internal Server Error:', await response.text());
                        break;
                    default:
                        toast.error("Bad request ", { position: toast.POSITION.BOTTOM_RIGHT });
                        console.error('Unhandled status code:', response.status);
                }
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error("Internal Server Error:", { position: toast.POSITION.BOTTOM_RIGHT });
        } finally {
            setLoading(false); // Set loading state back to false after request is finished
        }
        // window.location.reload();    


    };

    return (
        <>
        {followerCount>=0? <button className='bg-dark' disabled={"disabled"}>
            Following
            
        </button>:
        <button   onClick={handleButtonClick} disabled={loading}>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Follow'}
        </button>
        }
        </>
    );
};

export default NewFollow;
