


import React from 'react';
import { BrowserRouter as Router, Route, Switch, BrowserRouter, Routes } from 'react-router-dom';
import Home from './Screen/Home';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Detailspage from './Screen/Detailspage';
import Authorepage from './Screen/Authorepage';
import SingleAuthore from './Screen/SingleAuthore';
import PublisherPage from './Screen/PublisherPage';
import SinglePublisher from './Screen/SinglePublisher';
import Checkout from './Screen/Checkout';
import Prodcast from './Screen/Prodcast';
import Login from './Screen/Login';
import Categoriespage from './Screen/Categoriespage';
import ReviewPage from './Screen/ReviewPage';
import SinglePage from './Screen/SingleReviewPage';
import AllBooks from './Screen/AllBooks';
import Subscription from './Screen/Subscription';
import AllSeller from './Screen/AllSeller';
import Singleseller from './Screen/Singleseller.jsx'
import AddProduct from './SellerDashboard/AddBook';
import Register from './Screen/Signup';
import Sign from './Screen/Signin';
import MyAccount from './SellerDashboard/MyAccount';
import { ToastContainer } from 'react-toastify';
import Addresses from './Screen/Addresses';
import Order from './Component/Order';
import ManageOrder from './SellerDashboard/ManageOrder';
import ManageBook from './SellerDashboard/ManageBook';
import EditBook from './SellerDashboard/EditBook';

import Ebook from './Screen/Ebook';
import Audio from './Screen/AudioBook';
import ContactUs from './Screen/Contactus';
import Account from './Screen/Account';
import BlogSection from './Screen/blogs';
import Wishlist from './Screen/Wishlist.jsx'
import Cart from './Screen/Cart.jsx'
import AddBook from './Component/AddBook.jsx'




function App() {
  return (

    <>

      <Header />
      <Routes>



        {/* <Route path="/" element={<Layout />}> */}
        <Route index exact path="/" element={<Home />}></Route>
        {/* <Route index path="/" element={<Home />} /> */}
        {/* <Route path="Detailspage" element={<Detailspage />} /> */}
        <Route exact path="books/details/:_id" element={<Detailspage />}></Route>
        <Route exact path="books/details/" element={<Detailspage />}></Route>

        <Route exact path="/authors" element={<Authorepage />}></Route>
        {/* <Route exact  path="/authors/:username-:userId" element={<SingleAuthore />}></Route> */}
        <Route exact path="/authors/:dynamicUserName" element={<SingleAuthore />}></Route>

        {/* <Route path="/blog/:postTitle" element={<SingleAuthore />} /> */}

        <Route exact path="/publishers" element={<PublisherPage />}></Route>
        <Route exact path="/publishers/:dynamicUserName" element={<SinglePublisher />}></Route>

        <Route exact path="Checkout" element={<Checkout />}></Route>
        <Route exact path="podcasts" element={<Prodcast />}></Route>
        <Route exact path="Login" element={<Login />}></Route>

        <Route exact path="/categories/:username" element={<Categoriespage />}></Route>
        <Route exact path="books-review" element={<ReviewPage />}></Route>
        <Route exact path="SinglePage" element={<SinglePage />}></Route>

        <Route exact path="books" element={<AllBooks />}></Route>

        <Route exact path="Subscription" element={<Subscription />}></Route>
        <Route exact path="book-stores" element={<AllSeller />}></Route>
        <Route exact path="book-stores/:dynamicUserName" element={<Singleseller />}></Route>

        <Route exact path="add-product" element={<AddProduct />}></Route>
        <Route exact path="Signup" element={<Register />}></Route>
        <Route exact path="Signin" element={<Sign />}></Route>
        <Route exact path="MyAccount" element={<MyAccount />}></Route>
        <Route exact path="addresses" element={<Addresses />}></Route>

        <Route exact path="myorder" element={<Order />}></Route>
        {/* <Route exact  path="ManageBook" element={<ManageBook />}></Route> */}

        <Route exact path="ManageOrder" element={<ManageOrder />}></Route>
        <Route exact path="manage-book" element={<ManageBook />}></Route>
        <Route exact path="manage-book/edit/:_id" element={<EditBook />}></Route>
        <Route exact path="manage-book/edit" element={<EditBook />}></Route>


        <Route exact path="ebooks" element={<Ebook />}></Route>
        <Route exact path="audio-books" element={<Audio />}></Route>
        <Route exact path="contact-us" element={<ContactUs />}></Route>
        <Route exact path="account" element={<Account />}></Route>
        <Route exact path="blogs" element={<BlogSection />}></Route>
        <Route exact path="Wishlist" element={<Wishlist />}></Route>
        <Route exact path="cart" element={<Cart />}></Route>
        <Route exact path='add-book' element={<AddBook />}></Route>


      </Routes>
      <Footer />
      <ToastContainer />


    </>

  );
}

export default App;


