import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import config from '../config/Config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddNew = ({ show, onHide, item, userId }) => {

  const [selectedAddressType, setSelectedAddressType] = useState('Home');

  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    address: '',
    apartment: '.',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    companyName: '',
    landMark: '',
    additionalInformation: ''
  });

  const handleAddressTypeChange = (type) => {
    setSelectedAddressType(type);
    console.log(type)
  };
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const BookingCheckout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "addressType": selectedAddressType,
      ...formData
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };


    fetch(config.api_base_url + `/api/v1/user/address/addNew/${userId}`, requestOptions)
      .then((response) => {
        if (response.status != 200) {
          // alert("Please Fill Valid Details")

        }

        return response.json();
      })
      .then(async (responseData) => {
        console.log("RESULTS HEREforData: for add New Address id", responseData)
        toast(responseData.message)
        window.location.reload();
      })
      .catch(error => console.log('error', error));

  }
  return (
    <div >
      <Modal show={show} onHide={onHide} size="lg" centered className='addAddre w-100'>
        <Modal.Header closeButton>
          <Modal.Title>{"Add New Address"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class=" mt-3 pt-3 pb-5">
            <form onSubmit={() => BookingCheckout()} class="pt-4 setfontcheckout">
              <div class="row">
                <div class="col-sm-6">


                  <div class="form-group mb-4">

                    <label for="exampleInputEmail1"> Name</label>
                    <input
                      type="text"
                      class="form-control p-2"
                      placeholder="Enter First Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>

                </div>
                <div class="col-sm-6">
                  <div class="form-group mb-1">
                    <label for="exampleInputPassword1">Mobile</label>
                    <input
                      type="text"
                      class="form-control p-2"
                      placeholder="Enter Mobile Number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div class="col-sm-6">



                  <div class="form-group mb-4">
                    <label for="exampleInputEmail1">Address</label>
                    <input
                      type="text"
                      class="form-control p-2"
                      placeholder="Enter Full Address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                    {/* <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> --> */}
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group mb-1">
                    <label for="exampleInputPassword1">Apt, Suite *</label>
                    <input type="number" class="form-control p-2" placeholder="Enter Details" id="getphone" />
                  </div>
                </div>
                <div class="col-sm-6">



                  <div class="form-group mb-4">
                    <label for="exampleInputEmail1"> City</label>
                    <input
                      type="text"
                      class="form-control p-2"
                      placeholder="Enter City Name"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                    {/* <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> --> */}
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group mb-1">
                    <label for="exampleInputPassword1">State</label>
                    <input
                      type="text"
                      class="form-control p-2"
                      placeholder="Enter State Name"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div class="col-sm-6">



                  <div class="form-group mb-4">
                    <label for="exampleInputEmail1"> Country</label>
                    <input
                      type="text"
                      class="form-control p-2"
                      placeholder="Enter Country Name"
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                    />
                    {/* <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> --> */}
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group mb-1">
                    <label for="exampleInputPassword1">Postal Code</label>
                    <input
                      type="text"
                      class="form-control p-2"
                      placeholder="Enter Postal Code"
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group mb-4">
                    <label for="exampleInputEmail1"> Company Name (optional) </label>
                    <input
                      type="text"
                      class="form-control p-2"
                      placeholder="Enter Company Name"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleInputChange}
                    />
                    {/* <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> --> */}
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group mb-1">
                    <label for="exampleInputPassword1">GST No.</label>
                    <input type="text" class="form-control p-2" placeholder="Enter GST Name" id="" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <label for="exampleInputEmail1"> Address Type</label>
                  <div class="form-group mb-4 mt-1 address-margine">
                    {['Home', 'Office', 'Work', 'Other'].map((type) => (
                      <div key={type} class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id={`flexRadioDefault${type}`}
                          checked={selectedAddressType === type}
                          onChange={() => handleAddressTypeChange(type)}
                        />
                        <label class="form-check-label ps-2" for={`flexRadioDefault${type}`}>
                          {type} {type === 'Home' && <span></span>}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
              <div>
                <a onClick={() => BookingCheckout()} type="submit" class="btn btn-primary  p-3 mobilea"

                  style={{ fontFamily: "'Poppins', sans-serif", background: "black", border: "none" }}
                  onclick="getVal()">Save </a>
                <a onclick="cancleSet()" class="set-cancle">Cancle</a>
              </div>
            </form>



          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddNew;
