import React, { useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../assets/css/checkout.css";
import config from '../config/Config';
import VideoReviewsCarousel from '../Component/NewVideo';

export default function Prodcast() {
    const [modal, setModal] = useState(false);
    const [videoLoading, setVideoLoading] = useState(true);

    const openModal = () => {
      setModal(!modal);
    };
    useEffect(()=>{
      // Detailsbook()
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });


    },[]

    )

    const spinner = () => {
      setVideoLoading(!videoLoading);
    };


    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block",  }}
            onClick={onClick}
          />
        );
      }

      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block",  }}
            onClick={onClick}
          />
        );
      }
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:false,
        autoplaySpeed:3000,
        focus:false,
        pauseOnHover:true,
        arrow:true,

        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
      };


      const Addpodcast=()=>{
        var formdata = new FormData();
formdata.append("userId", "0");
formdata.append("name", "Mayank");
formdata.append("email", "mayank@gmail.com");
formdata.append("designation", "Devloper");
formdata.append("title", "Some Test");
formdata.append("description", "wretyguiojpk");
formdata.append("meta_title", "sfdghjkl");
formdata.append("meta_description", "sfdxgchjbklm");
// formdata.append("meta_image", fileInput.files[0], "[PROXY]");
// formdata.append("images", fileInput.files[0], "[PROXY]");
// formdata.append("images", fileInput.files[0], "[PROXY]");
// formdata.append("images", fileInput.files[0], "[PROXY]");
// formdata.append("images", fileInput.files[0], "[PROXY]");
// formdata.append("videofile", fileInput.files[0], "[PROXY]");

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(config.api_base_url + "/api/v1/home", requestOptions)
.then((response) => {
    if (response.code != 200) {
        //   alert("Please Fill Valid Details")


    }

    return response.json();
})

.then(async (responseData) => {
    console.log("RESULTS all All  details", responseData)

})
.catch(error => console.log('error', error));

      }


  return (
    <>
    <div className='first-sec'>

    <VideoReviewsCarousel />
    </div>
    <section className='mt-3 mb-3'>
    <div class="container mt-4">
        <div class="row">
            <div class="col-xl-9 col-sm-12">
                <div class="slider-1">
                    <h3 class=""> Podcast</h3>
                </div>
            </div>
        </div>

        {/* <!----> */}
        <div class="row">

            <div class="col-md-4 p-2">
                <div class="bg-white shadow-sm p-1 border newCard" width="100%">
                    <a onClick={openModal} >
                        <p class="img-p">
                        <img loading="lazy" src={require("../assets/images/podcast.png")} alt="Smile It Solution" width="100%" class="img-responsive" />
                        </p>
                        <h4 class="size-24 title">Booksbea</h4>
                        </a>
                </div>
            </div>
            {/* <div class="col-md-4 p-2">
                <div class="bg-white shadow-sm p-1 border newCard" width="100%">
                    <a onClick={openModal} >
                        <p class="img-p">
                        <img loading="lazy" src={require("../assets/images/podcast.png")} alt="Smile It Solution" width="100%" class="img-responsive" />
                        </p>
                        <h4 class="size-24 title">Smile It Solution</h4>
                        </a>
                </div>
            </div>
            <div class="col-md-4 p-2">
                <div class="bg-white shadow-sm p-1 border newCard" width="100%">
                    <a onClick={openModal} >
                        <p class="img-p">
                        <img loading="lazy" src={require("../assets/images/podcast.png")} alt="Smile It Solution" width="100%" class="img-responsive" />
                        </p>
                        <h4 class="size-24 title">Smile It Solution</h4>
                        </a>
                </div>
            </div> */}

        </div>

        {/* <h2 className='text_Coming'>Coming Soon</h2> */}

        {modal ? (
          <section className="modal__bg_new">
            <div className="modal__align">
              <div className="modal__content" modal={modal}>
                <a
                  className="modal__close"
                  arial-label="Close modal"
                  onClick={openModal}
                ><i class="fa fa-close text-dark " style={{fontSize:"25px"}} ></i></a>
                <div className="modal__video-align">
                  {videoLoading ? (
                    <div className="modal__spinner">
                      {/* <BiLoaderAlt
                        className="modal__spinner-style"
                        fadeIn="none"
                      /> */}
                    </div>
                  ) : null}
                  <iframe
                        // title={`YouTube video 1}`}
                        width="560"
                        height="315"
                        src={'https://www.youtube.com/embed/VeOA-9zdFGY'}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                </div>
              </div>
            </div>
          </section>
        ) : null}

    </div>


</section>
</>
  )
}

// import React, { useState } from "react";
// import "../assets/css/checkout.css";


// export default function Prodcast() {
//   const [modal, setModal] = useState(false);
//   const [videoLoading, setVideoLoading] = useState(true);

//   const openModal = () => {
//     setModal(!modal);
//   };

//   const spinner = () => {
//     setVideoLoading(!videoLoading);
//   };

//   return (
//     <div className="App">
//       <button onClick={openModal} className="">
//         Click Me!
//         {modal ? (
//           <section className="modal__bg_new">
//             <div className="modal__align">
//               <div className="modal__content" modal={modal}>
//                 <a
//                   className="modal__close"
//                   arial-label="Close modal"
//                   onClick={setModal}
//                 ><i class="fa fa-close text-dark " style={{fontSize:"25px"}} ></i></a>
//                 <div className="modal__video-align">
//                   {videoLoading ? (
//                     <div className="modal__spinner">
//                       {/* <BiLoaderAlt
//                         className="modal__spinner-style"
//                         fadeIn="none"
//                       /> */}
//                     </div>
//                   ) : null}
//                   <iframe
//                     className="modal__video-style"
//                     onLoad={spinner}
//                     loading="lazy"
//                     width="800"
//                     height="500"
//                     src="https://www.youtube.com/embed/4UZrsTqkcW4"
//                     title="YouTube video player"
//                     frameBorder="0"
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                     allowfullscreen
//                   ></iframe>
//                 </div>
//               </div>
//             </div>
//           </section>
//         ) : null}
//       </button>
//     </div>
//   );
// }
